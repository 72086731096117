@import "vars.scss";
@import "mixin.scss";

.med-history__descr {
  font-size: 11px;
  color: red;
}

.med-history__recommend-text { font-size: 12px; }

.med-history__label { font-size: 14px; }

.med-history__view {
  font-weight: bold;
  color: $orangeColor;
  border-color: $orangeColor;
  margin-left: 25px;
}

//popover style

.med-popover {
  padding: 15px;
  color: #666666;

  table {
    td {
      vertical-align: top;
    }
  }
}

.med-popover .row { margin-bottom: 10px }

.med-popover b { color: $textGreen; }

.med-popover-wrap {
  .popover {
    width: 330px;
    max-width: 800px;
    border: 2px solid $textGreen;
    background-color: #e9f5e9;
  }

  .arrow {
    border-bottom-color: $textGreen !important;
  }

  .popover.bottom > .arrow:after {
    border-bottom-color: #e9f5e9;
  }
}

.datepicker-dropdown {
  padding-top: 27px;
  &::after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    text-align: center;
    content: 'Please enter your Birthdate';
    text-decoration: underline;
    font-weight: 700;
    font-size: 14px;
    height: 20px;
    display: block;
  }
}