@font-face {
  font-family: 'Brandon';
  src: url("/assets/fonts/brandon/Brandon_reg.otf") format("opentype"); }

html {
  box-sizing: border-box; }

*,
*::before,
*::after {
  box-sizing: inherit; }

body {
  background: #fff;
  font-size: 14px;
  font-family: Brandon, Verdana, Geneva, sans-serif;
  color: black; }

a {
  color: #eb6900; }
  a:hover {
    color: #eb6900; }

.container {
  padding-top: 136px; }
  @media (min-width: 1200px) {
    .container {
      width: 1080px; } }
  @media (max-width: 767px) {
    .container {
      padding-top: 55px; } }

@media (min-width: 1200px) {
  .main-content {
    width: 735px;
    padding: 0 0 0 15px; } }

@media (max-width: 767px) {
  .text-xs-center {
    text-align: center !important; }
  .center-xs-block {
    margin: 0 auto !important;
    display: block; } }

@media (min-width: 768px) and (max-width: 991px) {
  .text-sm-center {
    text-align: center !important; }
  .center-sm-block {
    margin: 0 auto !important;
    display: block; } }

.selected {
  color: #fff !important;
  background: #04be04 !important; }

.margin-0 {
  margin: 0; }

.none {
  display: none; }

span.required {
  color: red; }

.img-large-hidden {
  display: none;
  z-index: 1000; }

.dashed-underline {
  border-bottom: 1px dashed #949469;
  cursor: default; }

.link--green, .rectangular_orange a {
  color: #00BFA4; }
  .link--green:focus, .rectangular_orange a:focus {
    color: #00BFA4;
    text-decoration: none; }
  .link--green:hover, .rectangular_orange a:hover {
    color: #00BFA4;
    text-decoration: underline; }

.bold {
  font-weight: bold; }

.help-block {
  clear: both;
  display: table; }

.clearfix::after {
  display: table;
  clear: both;
  content: ""; }

#choosePossibleConnectionsCountry,
#chooseDoctorsCountry,
.chosen-container {
  max-width: 100% !important; }

@media (max-width: 767px) {
  #choosePossibleConnectionsCountry {
    display: block;
    width: 97%;
    height: 21px;
    margin-bottom: 8px;
    margin-left: 7px;
    padding-left: 1px; } }

iframe {
  max-width: 100% !important; }

@media (min-width: 992px) {
  .padding-l-md-0 {
    margin-left: 0; }
  .padding-r-md-0 {
    margin-right: 0; } }

.rectangular_orange {
  padding: 10px;
  color: #555555;
  background: #fff9e5;
  border: 1px solid orange; }

.birthDateExample {
  padding-top: 5px;
  font-size: 16px; }

label {
  position: relative; }

.error404__txt {
  font-size: 26px;
  line-height: 1.3em; }
  .error404__txt a {
    color: #336600; }
    .error404__txt a:focus {
      color: #336600;
      text-decoration: none; }
    .error404__txt a:hover {
      color: #336600;
      text-decoration: underline; }

label.required::before {
  content: "*";
  top: 1px;
  left: -11px;
  position: absolute;
  color: red; }

label.required--doc::before {
  left: -12px; }

.edit-title {
  padding: 0 15px 10px;
  color: #505050;
  font-weight: normal;
  font-size: 25px; }

#professional .alert-success,
#personal .alert-success,
#account .alert-success,
#email-notifications .alert-success,
#medical-history .alert-success {
  margin: 15px;
  padding: 15px;
  text-align: center;
  background-color: transparent;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  color: green;
  border: 1px solid #00af25;
  font-size: 16px; }

.empty-table {
  margin: 50px auto;
  font-family: Brandon, Verdana, Arial, sans-serif;
  font-size: 15px;
  letter-spacing: 1px;
  color: #222222; }

.empty-table--light {
  color: #626258; }

.change-profile-picture {
  font-style: italic;
  font-size: 13px; }

.chosen-container-single {
  width: 299px !important;
  padding: 0;
  margin: 0;
  top: -2px; }
  .chosen-container-single .chosen-single {
    height: 21px;
    padding: 0;
    margin: 0;
    border: 1px solid #b8c7d9;
    background-color: #ffffff;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    box-shadow: none !important; }
    .chosen-container-single .chosen-single span {
      margin: 0;
      background-color: #ffffff;
      font-size: 15px;
      color: #333333;
      font-family: Brandon, Arial, sans-serif;
      height: 21px;
      line-height: 20px;
      padding-left: 5px; }

.chosen-container-single .chosen-single div b {
  background-position: 4px -1px; }

.modal {
  background-color: rgba(170, 170, 170, 0.3); }

.privacy-title {
  margin-top: 7px;
  margin-left: 4px;
  margin-bottom: 18px;
  font-size: 14px;
  color: #1a1a1a;
  font-weight: bold; }

.privacy-descr {
  margin-top: -10px; }
  .privacy-descr p {
    line-height: 1.3em;
    margin-bottom: 15px; }
  .privacy-descr a {
    color: #336600; }

.crop-picture__img {
  max-width: 450px;
  max-height: 350px; }

.modal-backdrop.in {
  opacity: 0; }

.link_orange {
  color: #eb6900 !important;
  text-decoration: none !important; }
  .link_orange:hover, .link_orange:focus {
    color: #eb6900 !important;
    text-decoration: underline !important; }

.link_red {
  color: #ff0000 !important;
  text-decoration: none !important; }
  .link_red:hover, .link_red:focus {
    color: #ff0000 !important;
    text-decoration: underline !important; }

.abuse {
  font-family: Brandon, Ariana, Verdana, Helvetica, sans-serif;
  font-size: 18px;
  color: #ff0000;
  margin-bottom: 10px; }

.abuse--answer {
  margin-left: 1px;
  color: #ff0000;
  margin-bottom: 8px; }

.text-right {
  text-align: right; }

.modal-footer {
  border-top: 0; }

.no-border {
  border: none !important; }

.btn-fix-padding {
  padding: 6px !important; }

.ads-main-content {
  width: 730px;
  max-width: 100%; }

.ads-sidebar {
  width: 320px;
  max-width: 100%; }

.margin-bottom-20 {
  margin-bottom: 20px; }

.ads_block {
  border-bottom: 1px solid #808080;
  border-top: 1px solid #808080;
  margin: 15px 0;
  overflow: hidden; }

.cancer-input {
  width: 60% !important;
  display: inline-block; }

.margin-bottom-0 {
  margin-bottom: 0; }

.post-ads {
  font-size: 16px;
  color: #666666;
  padding: 5px 0;
  font-family: Brandon, Arial, "Helvetica Neue", Helvetica, sans-serif;
  line-height: 1.1em; }

.mce-ico.mce-i-fa {
  display: inline-block;
  font: normal normal normal 1em/1 FontAwesome;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-right: 0 !important;
  margin-right: -1px; }

.mce-ico {
  font-size: 13px !important; }

.connect-send {
  margin-bottom: 10px; }

.hospitals-map {
  width: 220px;
  height: 100px; }

.c-pointer {
  cursor: pointer; }

.login-page.has-error .help-block {
  margin-left: 75px; }

.declaine-message {
  font-weight: 400; }

.modal__header .modal-title {
  font-size: 16px; }

.decline {
  max-width: 100% !important;
  width: 400px;
  margin-left: 100px;
  margin-top: 170px !important; }
  @media (max-width: 767px) {
    .decline {
      margin-left: 0; } }

.margin-left-0 {
  margin-left: 0; }

ul.tagit li.tagit-new {
  width: 100%; }

.blue-line {
  border-color: #0073ea; }

#medicalSpecialization_chosen {
  width: 100% !important; }
  #medicalSpecialization_chosen .chosen-single {
    height: 30px;
    line-height: 30px;
    border-radius: 3px; }
    #medicalSpecialization_chosen .chosen-single span {
      height: 30px;
      padding: 5px 10px; }

#medicalSpecialization_chosen .chosen-single div b {
  background-position: 2px 4px !important; }

#medicalSpecialization_chosen.chosen-with-drop .chosen-single div b {
  background-position: -17px 6px !important; }

.hospitals-wrapper {
  border: 2px solid lightblue;
  padding: 10px 0;
  border-radius: 10px;
  max-width: 100%;
  margin: 0 auto; }

.hospital-node {
  color: #888888; }

#changeRegisterType {
  border: none;
  margin-bottom: 25px;
  color: black;
  font-size: 18px; }

#expertRegisterForm #medicalSpecialization_chosen .chosen-single {
  width: 260px;
  height: 21px !important;
  border: 1px solid #B8C7D9; }
  #expertRegisterForm #medicalSpecialization_chosen .chosen-single span {
    padding: 1px 4px !important; }
  #expertRegisterForm #medicalSpecialization_chosen .chosen-single div b {
    background-position: 4px 0 !important; }

#expertRegisterForm #medicalSpecialization_chosen.chosen-with-drop .chosen-single div b {
  background-position: -16px 0px !important; }

/*.#questionAnswers {
  margin-bottom: 200px;
}*/
.hello__text {
  font-size: 20px;
  margin-top: 6px;
  margin-bottom: 12px; }

.hello__btn {
  margin-bottom: 29px; }

.blured-medical-info {
  width: 100%;
  height: 300px;
  background-size: cover;
  background: url("/assets/images/medical-blur-ready.jpg") no-repeat; }

.posted-success {
  color: #009b85; }

a:focus {
  outline: none;
  color: #eb6900; }

@media (max-width: 767px) {
  .container {
    overflow-y: hidden; } }

.g-recaptcha {
  margin-top: 10px; }

.data-saving {
  padding-right: 30px !important;
  background: url(/assets/images/loading.gif) #dddddd 95% 50% no-repeat !important;
  background-size: 20px !important;
  color: #777 !important;
  text-shadow: none !important; }
  .data-saving:hover {
    padding-right: 30px !important;
    background: url(/assets/images/loading.gif) #dddddd 95% 50% no-repeat !important;
    background-size: 20px !important;
    color: #777 !important;
    text-shadow: none !important; }

.mobile-only-show {
  display: none; }
  @media (max-width: 767px) {
    .mobile-only-show {
      display: block; } }

.mobile-only-show-i {
  display: none !important; }
  @media (max-width: 767px) {
    .mobile-only-show-i {
      display: block !important; } }

@media (max-width: 767px) {
  .mobile-hide {
    display: none; } }

@media (max-width: 767px) {
  .mobile-hide-i {
    display: none !important; } }

@media (max-width: 767px) {
  .main-site-container {
    position: absolute;
    top: 0;
    max-width: 100%; } }

.viewed-me-badge {
  background-color: red;
  border-radius: 50%;
  margin: 0 0 0 3px;
  position: relative;
  top: -1px; }
  @media (max-width: 767px) {
    .viewed-me-badge {
      padding: 3px 5px;
      float: right;
      top: 7px; } }

.eye-icon {
  background-image: url("/assets/images/eye-icon.png");
  background-size: contain;
  width: 20px;
  height: 13px;
  margin-right: 3px;
  display: inline-block;
  position: relative;
  top: 2px;
  background-repeat: no-repeat; }

.main-header {
  top: 0;
  position: fixed;
  width: 1052px;
  z-index: 301;
  background-color: white; }
  @media (max-width: 767px) {
    .main-header {
      width: 92%; } }

@media (max-width: 767px) {
  .m-padding-left-40 {
    padding-left: 40px !important; } }

.signup-recaptcha {
  margin-bottom: 10px; }

.experts-signup-recaptcha {
  display: flex;
  justify-content: center; }

.main-site-content {
  padding-top: 25px; }
  @media (max-width: 767px) {
    .main-site-content {
      padding-top: 35px; } }

.padding-top-0 {
  padding-top: 0; }

.btn--orange {
  background: #eb6900;
  color: #fff !important;
  border-radius: 0; }

.btn-link--green {
  color: #00BFA4; }
  .btn-link--green:hover {
    color: #00BFA4; }

.btn-link--width {
  padding: 10px; }

.btn--green-gradient {
  background-color: #00BFA4;
  border: 1px solid #009b85 !important;
  color: white;
  text-shadow: -1px -1px 0px #404040; }
  .btn--green-gradient:focus, .btn--green-gradient:active {
    outline: none;
    color: white; }
  .btn--green-gradient:hover {
    background-color: #009b85;
    border-color: #009b85;
    color: white; }
  .btn--green-gradient.disabled {
    background: #b7b7b7;
    background: -moz-linear-gradient(top, #b7b7b7 0%, #828282 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, #b7b7b7), color-stop(100%, #828282));
    background: -webkit-linear-gradient(top, #b7b7b7 0%, #828282 100%);
    background: -o-linear-gradient(top, #b7b7b7 0%, #828282 100%);
    background: -ms-linear-gradient(top, #b7b7b7 0%, #828282 100%);
    background: linear-gradient(to bottom, #b7b7b7 0%, #828282 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#b7b7b7', endColorstr='#828282', GradientType=0);
    color: white; }

.btn--grey-gradient {
  text-shadow: -1px -1px 0px #404040;
  background: #b7b7b7;
  background: -moz-linear-gradient(top, #b7b7b7 0%, #828282 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, #b7b7b7), color-stop(100%, #828282));
  background: -webkit-linear-gradient(top, #b7b7b7 0%, #828282 100%);
  background: -o-linear-gradient(top, #b7b7b7 0%, #828282 100%);
  background: -ms-linear-gradient(top, #b7b7b7 0%, #828282 100%);
  background: linear-gradient(to bottom, #b7b7b7 0%, #828282 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#b7b7b7', endColorstr='#828282', GradientType=0);
  color: white; }

.btn--cancel {
  margin-left: 40px; }

.btn--mlogin {
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  border: 0;
  height: 36px;
  width: 94px;
  cursor: pointer;
  padding: 0;
  font-size: 17px; }

.btn--grey {
  padding: 4px 40px;
  color: #4D4D4D;
  border: 1px solid #B8C7D9;
  background-color: #dddddd; }
  .btn--grey:hover {
    color: #4D4D4D;
    text-decoration: none; }

.btn--wgr {
  padding: 6px 38px;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  background-color: #00BFA4;
  border-color: #00BFA4;
  font-size: 14px;
  color: white; }
  .btn--wgr:hover {
    background-color: #cc0000;
    border-color: #cc0000;
    color: white; }

.btn--post {
  width: 130px;
  font-size: 18px; }

.b-form__label {
  font-weight: 400;
  font-family: Brandon, Verdana, Arial, sans-serif;
  padding-top: 0 !important;
  text-align: left !important;
  color: #222222; }

.b-form__input {
  position: relative;
  border-radius: 3px;
  padding: 2px 4px;
  height: 27px !important;
  width: 260px;
  border: 1px solid #B8C7D9;
  font-family: Brandon, Arial, sans-serif;
  font-size: 15px;
  font-weight: 400;
  color: #4d4d4d;
  cursor: default; }

.b-form__input--post-m {
  width: 100%;
  height: 27px !important; }

.b-form__input--textarea {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  font-family: monospace;
  font-size: 15px;
  color: #333333; }

.b-form__input--select {
  padding-left: 1px; }

.b-form__input--expert {
  width: 260px; }

.b-form__input--square {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0; }

.b-form__input--expert-doc {
  display: inline-block;
  vertical-align: top;
  width: 238px;
  margin-left: -1px; }

.b-form__doc-prefix {
  display: inline-block;
  vertical-align: top;
  padding-top: 2px;
  font-family: Brandon, Verdana, Geneva, sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: black; }

.b-form__input--sing-up-modal {
  width: 200px;
  margin-left: 21px; }

.b-form__group {
  margin-bottom: 10px; }

.b-form--tags-in {
  max-width: 260px; }

.experts__form-group .tagit {
  position: relative;
  border-radius: 3px;
  padding: 2px 4px;
  width: 260px;
  border: 1px solid #B8C7D9;
  font-family: Brandon, Arial, sans-serif;
  font-size: 15px;
  font-weight: 400;
  color: #4d4d4d;
  cursor: default; }

.help-block {
  display: block;
  text-align: left;
  font-size: 12px;
  line-height: 1em;
  margin: 0; }
  .help-block strong {
    display: block;
    font-weight: 400;
    color: red;
    font-size: 12px;
    line-height: 1em; }

.has-error .form-control {
  border: 1px dotted red;
  box-shadow: none; }
  .has-error .form-control:focus {
    box-shadow: none !important;
    outline: -webkit-focus-ring-color auto 5px; }

.has-error .control-label {
  color: currentColor; }

#headerLogo {
  display: inline-block;
  vertical-align: bottom;
  margin-right: 2px;
  height: 50px;
  width: 50px;
  margin-bottom: 1px;
  text-decoration: none !important;
  background: #fff url("/assets/images/buhayofw-logo.png") no-repeat;
  -webkit-background-size: cover;
  background-size: cover; }
  @media (max-width: 767px) {
    #headerLogo {
      position: absolute;
      top: 7px;
      left: 55px;
      z-index: 1000;
      height: 35px;
      width: 100px;
      background: #00BFA4 url("/assets/images/buhayofw-logo-m.png") no-repeat;
      background-size: contain; } }

.header-top {
  margin-bottom: 4px; }
  @media (max-width: 767px) {
    .header-top {
      background-color: #00BFA4;
      padding-top: 0;
      margin-bottom: 0;
      height: 55px; } }

.mobile-header-profile-img {
  display: none; }
  @media (max-width: 767px) {
    .mobile-header-profile-img {
      display: block;
      position: absolute;
      right: 20px;
      top: 10px; } }
  @media (max-width: 767px) {
    .mobile-header-profile-img img {
      width: 35px;
      border-radius: 50%; } }

.main-header__search-line {
  display: inline-block;
  margin-top: 27px;
  width: calc(100% - 57px);
  margin-bottom: 1px; }
  @media (max-width: 767px) {
    .main-header__search-line {
      width: 103%;
      margin-left: -5px;
      margin-top: 60px; } }
  @media (max-width: 767px) {
    .main-header__search-line .main-search-header-form {
      position: absolute;
      width: 100%;
      z-index: 9999;
      top: -15px;
      margin-left: -10px;
      font-size: 16px; } }
  @media (max-width: 767px) {
    .main-header__search-line .main-search-header-form .search-submit {
      display: none; } }
  @media (max-width: 767px) {
    .main-header__search-line .main-search-header-form .search-close-mobile {
      position: absolute;
      z-index: 10;
      bottom: 20px;
      left: 15px;
      height: 20px;
      width: 20px;
      background: url("/assets/images/back-icon.png") no-repeat 50%;
      background-size: contain; } }
  @media (max-width: 767px) {
    .main-header__search-line .main-search-header-form .search-clear-mobile {
      position: absolute;
      z-index: 10;
      bottom: 20px;
      right: 15px;
      width: 20px;
      height: 20px;
      background: url("/assets/images/close-icon.png") no-repeat 50%;
      background-size: contain; } }
  .main-header__search-line .xdsoft_autocomplete {
    display: inline-block;
    position: relative;
    word-spacing: normal;
    text-transform: none;
    text-indent: 0;
    text-shadow: none;
    text-align: start;
    width: calc(100% - 172px) !important;
    float: left; }
    @media (max-width: 991px) {
      .main-header__search-line .xdsoft_autocomplete {
        width: calc(100% - 80px) !important; } }
    @media (max-width: 767px) {
      .main-header__search-line .xdsoft_autocomplete {
        width: 100% !important; } }
  .main-header__search-line .xdsoft_autocomplete_dropdown {
    overflow: hidden; }

input.search-input {
  display: inline-block;
  vertical-align: bottom;
  height: 47px;
  width: calc(100% - 4px);
  padding: 0 10px;
  border: 1px solid #3F76CA;
  box-shadow: none; }
  @media (min-width: 992px) {
    input.search-input {
      max-width: 367px; } }
  @media (max-width: 767px) {
    input.search-input {
      border: none;
      box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
      padding: 50px 50px 31px 55px;
      width: 100%; }
      input.search-input::placeholder {
        color: #00BFA4;
        font-size: 16px; } }
  input.search-input:focus {
    outline: none; }

input.search-input::-webkit-input-placeholder {
  color: #00BFA4;
  font-size: 16px; }

.search-submit {
  display: inline-block;
  margin-left: 10px;
  vertical-align: bottom;
  height: 47px;
  width: 67px;
  background: #eb6900 url("https://www.google.com/uds/css/v2/search_box_icon.png") no-repeat 50%;
  border: 0; }
  @media (max-width: 767px) {
    .search-submit {
      position: absolute;
      right: 10px;
      background: #00BFA4 url("https://www.google.com/uds/css/v2/search_box_icon.png") no-repeat 50%; } }

.search-submit-new {
  display: none;
  border: 0;
  height: 15px;
  width: 15px; }
  @media (max-width: 767px) {
    .search-submit-new {
      display: inline-block;
      position: absolute;
      right: 85px;
      top: 16px;
      height: 25px;
      width: 25px;
      background: #00BFA4 url("/assets/images/search-icon.png") no-repeat 50%;
      background-size: contain; } }

.header-profile-menu {
  float: right;
  text-align: right; }
  @media (max-width: 767px) {
    .header-profile-menu {
      position: absolute;
      right: 0;
      z-index: 100; } }

.notlogined {
  margin-top: 12px;
  margin-right: 10px;
  font-size: 0; }
  @media (max-width: 767px) {
    .notlogined {
      margin-bottom: 23px;
      margin-top: 0;
      display: none; } }

.notlogined li {
  display: inline-block; }

.notlogined__link {
  display: block;
  padding: 5px 15px !important;
  font-size: 16px;
  font-family: Brandon, Arial, Helvetica, sans-serif; }
  @media (max-width: 767px) {
    .notlogined__link {
      position: relative;
      display: block;
      top: 22px;
      left: 4px;
      font: 12px/34px Brandon, Arial, Helvetica, sans-serif;
      padding: 6px 3px !important;
      margin-left: 0;
      line-height: 1em; } }
  .notlogined__link:hover {
    text-decoration: underline !important; }
  .notlogined__link:focus {
    outline: none; }

.notlogined__link--sing-in {
  color: #005801;
  background-color: white; }
  .notlogined__link--sing-in:hover, .notlogined__link--sing-in:focus {
    color: #005801;
    background-color: white !important; }

.notlogined__link--sing-up {
  color: white;
  background-color: #eb6900; }
  .notlogined__link--sing-up:hover, .notlogined__link--sing-up:focus {
    color: white;
    background-color: #eb6900 !important; }

.logined {
  font-size: 0; }

.logined li {
  display: inline-block;
  min-height: 34px; }

.logined__link {
  font-family: Brandon, Arial, Helvetica, sans-serif;
  font-size: 16px;
  color: #00BFA4;
  background-color: #f3f3f3; }
  .logined__link:hover {
    color: #009b85 !important;
    background-color: #f3f3f3 !important; }
  .logined__link:focus {
    color: #009b85;
    background-color: #f3f3f3 !important; }

.logined__link--user {
  padding: 3px 0 1px 16px !important; }

.logined__link--posts {
  padding: 7px 15px 7px 18px !important;
  margin-right: 2px; }

.logined .dropdown-menu {
  top: 40px;
  min-width: 100px;
  padding: 0 10px;
  color: white;
  background-color: #00BFA4;
  border: 1px solid #78736f;
  border-radius: 0;
  z-index: 1000; }
  .logined .dropdown-menu li {
    width: 100%;
    padding-top: 7px;
    padding-bottom: 7px;
    border-bottom: 2px solid #009b85; }
    .logined .dropdown-menu li:first-child {
      position: relative; }
      .logined .dropdown-menu li:first-child::before {
        content: "";
        z-index: 20;
        position: absolute;
        top: -6px;
        left: 9px;
        display: block;
        width: 10px;
        height: 10px;
        transform: rotate(-45deg);
        background-color: #00BFA4;
        border-top: 1px solid #78736f;
        border-right: 1px solid #78736f; }
    .logined .dropdown-menu li a {
      width: 100%;
      display: block;
      padding: 5px 8px;
      font-size: 14px;
      line-height: 14px;
      color: white;
      font-family: Brandon, Arial, Helvetica, sans-serif; }
      .logined .dropdown-menu li a:hover, .logined .dropdown-menu li a:focus {
        text-decoration: none;
        background-color: #009b85; }
    .logined .dropdown-menu li:last-child {
      border-bottom: 0; }

.caret--rotate {
  transform: rotate(270deg); }

.caret {
  margin-left: 0; }

.logined__link:hover .caret {
  color: #5cbd21; }

@media (max-width: 767px) {
  #login-links.logined {
    display: none !important; } }

.logined-mob__wrap {
  display: none !important; }
  @media (max-width: 767px) {
    .logined-mob__wrap {
      display: block !important; } }

.logined-mob {
  background-color: #ccc;
  flex-direction: row !important;
  border: none !important; }
  @media (max-width: 767px) {
    .logined-mob {
      background-color: #eae9e9; } }

.logined-mob > li {
  width: 50% !important;
  display: inline-block; }

.logined-mob > li > a {
  font-family: Brandon, Arial, "Helvetica Neue", Helvetica, sans-serif !important;
  font-size: 14px !important;
  background-color: #ccc; }
  .logined-mob > li > a:focus, .logined-mob > li > a:hover {
    background-color: #ccc !important; }
    @media (max-width: 767px) {
      .logined-mob > li > a:focus, .logined-mob > li > a:hover {
        background-color: #eae9e9 !important; } }
  @media (max-width: 767px) {
    .logined-mob > li > a {
      background-color: #eae9e9; } }

.logined-mob .dropdown .dropdown-menu {
  display: none !important;
  background-color: #00BFA4;
  border: 1px solid #78736f !important;
  margin-top: -1px;
  position: absolute;
  left: 80px;
  top: 35px;
  z-index: 5450;
  padding: 10px;
  min-width: 100px;
  width: 122px !important;
  text-align: left;
  border-radius: 0; }
  @media (max-width: 767px) {
    .logined-mob .dropdown .dropdown-menu {
      background-color: #00BFA4; } }

.logined-mob .dropdown.open .dropdown-menu {
  display: block !important; }

.logined-mob .dropdown-menu > li:first-child {
  position: relative; }
  .logined-mob .dropdown-menu > li:first-child::before {
    content: "";
    z-index: 20;
    position: absolute;
    top: -16px;
    left: 2px;
    display: block;
    width: 10px;
    height: 10px;
    transform: rotate(-45deg);
    background-color: #00BFA4;
    border-top: 1px solid #78736f;
    border-right: 1px solid #78736f; }
    @media (max-width: 767px) {
      .logined-mob .dropdown-menu > li:first-child::before {
        background-color: #00BFA4; } }

.logined-mob .dropdown-menu > li:last-child a {
  border-bottom: none !important; }

.logined-mob .dropdown-menu > li > a {
  display: block;
  padding: 10px 3px !important;
  font-size: 14px !important;
  line-height: 14px !important;
  color: #FFFFFF !important;
  background: none !important;
  font-family: Brandon, Arial, "Helvetica Neue", Helvetica, sans-serif;
  border-bottom: 2px solid #02aa35 !important; }
  @media (max-width: 767px) {
    .logined-mob .dropdown-menu > li > a {
      border-bottom: 2px solid #009b85 !important; } }

#small-menu {
  display: block;
  text-align: right;
  margin-top: 16px; }
  #small-menu li {
    padding: 0 18px 0 7px;
    display: inline-block; }
    #small-menu li a {
      color: #00BFA4;
      font-size: 14px;
      padding: 0; }
      #small-menu li a:focus {
        background-color: inherit; }
      #small-menu li a:hover {
        background-color: inherit;
        text-decoration: underline; }
      #small-menu li a::after {
        content: '';
        width: 1px;
        position: absolute;
        top: 3px;
        right: -15px;
        height: 12px;
        background: #00BFA4; }
    #small-menu li:last-child {
      margin-right: 14px; }
      #small-menu li:last-child a:after {
        width: 0; }
  #small-menu li.active a {
    color: black; }

.logined + #small-menu {
  margin-top: 24px; }

#main-menu {
  min-height: inherit !important;
  box-shadow: none !important;
  border: 0 !important; }
  @media (min-width: 992px) {
    #main-menu {
      margin-bottom: 0; } }
  @media (max-width: 767px) {
    #main-menu {
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      z-index: 1000;
      background-color: transparent;
      padding-top: 15px; } }

#main-menu .navbar-nav {
  margin: 0; }

@media (max-width: 767px) {
  #main-menu .navbar-header {
    position: absolute;
    left: 5px;
    top: 20px; } }

#main-menu .navbar-collapse {
  padding: 0; }
  @media (max-width: 767px) {
    #main-menu .navbar-collapse {
      background-color: white;
      position: absolute;
      top: 55px;
      left: -15px;
      width: 85%;
      border: 1px solid lightgrey;
      height: 89vh;
      overflow-x: auto; } }

#main-menu ul:not(.dropdown-menu) {
  width: 100%;
  display: flex;
  justify-content: space-between; }
  @media (max-width: 767px) {
    #main-menu ul:not(.dropdown-menu) {
      flex-direction: column;
      border: none;
      height: 100%;
      display: block; } }
  #main-menu ul:not(.dropdown-menu) li {
    width: 100%;
    margin-right: 1px; }
    #main-menu ul:not(.dropdown-menu) li:last-child {
      margin-right: 0; }
    #main-menu ul:not(.dropdown-menu) li a, #main-menu ul:not(.dropdown-menu) li a:visited {
      display: block;
      width: 100%;
      padding: 0;
      margin: 0 1px 0 0;
      text-align: center;
      white-space: nowrap;
      color: #fff;
      font-size: 18px;
      line-height: 32px;
      font-family: Brandon, Arial, Helvetica, sans-serif;
      text-decoration: none;
      box-shadow: none;
      background-color: #00BFA4; }
      @media (max-width: 767px) {
        #main-menu ul:not(.dropdown-menu) li a, #main-menu ul:not(.dropdown-menu) li a:visited {
          color: #00BFA4;
          background: none;
          border-bottom: 1px solid lightgrey;
          text-align: left;
          padding: 0 15px 0 20px; } }
      #main-menu ul:not(.dropdown-menu) li a:hover, #main-menu ul:not(.dropdown-menu) li a:visited:hover {
        color: #00BFA4;
        background: #fff; }
        @media (max-width: 767px) {
          #main-menu ul:not(.dropdown-menu) li a:hover, #main-menu ul:not(.dropdown-menu) li a:visited:hover {
            color: #00BFA4; } }
      @media (max-width: 767px) {
        #main-menu ul:not(.dropdown-menu) li a:focus, #main-menu ul:not(.dropdown-menu) li a:active, #main-menu ul:not(.dropdown-menu) li a:visited:focus, #main-menu ul:not(.dropdown-menu) li a:visited:active {
          color: #fff;
          background: #00BFA4; } }
  @media (max-width: 767px) {
    #main-menu ul:not(.dropdown-menu) li.dropdown:focus, #main-menu ul:not(.dropdown-menu) li.dropdown:active {
      color: #00BFA4;
      background: #fff; } }
  #main-menu ul:not(.dropdown-menu) li.active a {
    background: #04BE04; }
    @media (max-width: 767px) {
      #main-menu ul:not(.dropdown-menu) li.active a {
        color: #fff;
        background: #00BFA4; } }

#main-menu .navbar-toggle.collapsed {
  background-color: #03ab03;
  border-color: #03ab03;
  padding: 6px 8px;
  padding-bottom: 2px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px; }
  @media (max-width: 767px) {
    #main-menu .navbar-toggle.collapsed {
      background: none;
      border: none;
      padding: 0;
      border-radius: 0;
      margin: 0; } }
  #main-menu .navbar-toggle.collapsed .icon-bar {
    display: block;
    width: 25px;
    height: 3px;
    background-color: #fff;
    -webkit-border-radius: 1px;
    -moz-border-radius: 1px;
    border-radius: 1px; }
    #main-menu .navbar-toggle.collapsed .icon-bar:nth-child(3) {
      width: 20px; }
  #main-menu .navbar-toggle.collapsed .menu-text {
    display: block;
    margin-top: 3px;
    color: white;
    font-weight: bold;
    font-size: 12px;
    font-family: Brandon, Arial, sans-serif; }
  #main-menu .navbar-toggle.collapsed .icon-bar + .icon-bar {
    margin-top: 4px; }
  #main-menu .navbar-toggle.collapsed .icon-close {
    display: none; }

#main-menu .navbar-toggle {
  background-color: #03ab03;
  border-color: #03ab03;
  padding: 0;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  position: relative; }
  #main-menu .navbar-toggle .icon-bar {
    display: none; }
  @media (max-width: 767px) {
    #main-menu .navbar-toggle {
      background: none;
      border: none;
      padding: 0;
      border-radius: 0;
      margin: 0; } }
  #main-menu .navbar-toggle .icon-close {
    position: absolute;
    top: -3px;
    display: block;
    color: #fff;
    width: 23px;
    height: 23px;
    font-size: 25px;
    text-align: center;
    margin: 0 auto;
    font-weight: bold;
    line-height: 1.1; }

.navbar-nav .open .mobile-menu-dropdown {
  background-color: #f1efef;
  padding: 0; }

#footer {
  text-align: center; }
  #footer .terms {
    color: #919191;
    margin-top: 10px;
    font-size: 12px;
    margin-bottom: 20px; }
    #footer .terms a, #footer .terms a:visited {
      color: #00BFA4;
      text-decoration: none; }
  #footer .fb-like-box {
    margin-top: 20px;
    margin-bottom: 10px; }

@media (min-width: 1200px) {
  .sidebar {
    width: 319px;
    float: right;
    padding-left: 0;
    padding-right: 14px; } }

.subtitle {
  display: block;
  padding: 0 5px;
  color: #eb6900;
  font-size: 19px;
  clear: both;
  margin: 0; }

.btn--main {
  margin-right: 22px;
  margin-top: 2px;
  padding: 3px 15px;
  float: right;
  background-color: #eb6900;
  border-radius: 0;
  border-color: #eb6900;
  font-size: 14px; }
  .btn--main:hover, .btn--main:focus, .btn--main:active, .btn--main:active:focus {
    text-decoration: underline;
    background-color: #eb6900;
    border-color: #eb6900;
    outline: none; }

.sidebar__people-title {
  color: #eb6900;
  font-size: 16px;
  margin-top: 14px;
  margin-left: 5px;
  margin-bottom: 10px; }

.sidebar__people-connect {
  margin-top: 17px; }

.sidebar__people-empty {
  margin-bottom: -7px; }

#choosePossibleConnectionsCountry_chosen {
  left: 4px; }

.ask + .find-friends {
  margin-top: 42px !important; }

/* styles for tinyMce content*/
.mce-last > * {
  font-family: Brandon, Arial, sans-serif !important;
  font-weight: 400 !important;
  color: #a6a6a6 !important;
  font-size: 16px !important; }

.mce-tinymce.mce-container.mce-panel {
  border: 0; }

.mce-toolbar-grp .mce-flow-layout-item {
  margin-left: 0 !important;
  margin-right: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important; }

.mce-toolbar-grp .mce-flow-layout-item button {
  padding: 4px 5px !important; }

.mce-toolbar-grp .mce-flow-layout-item button > i {
  padding: 0 !important; }

.mce-toolbar-grp .mce-first button > span {
  display: none; }

/*# styles for tinyMce content*/
/*posts modal styles*/
#ask-your-question-modal .form-group .b-form__input {
  border-radius: 0;
  border-color: #cacaca;
  padding: 0 4px; }

#postTitle {
  padding: 20px 4px;
  border: 0;
  box-shadow: none;
  font-size: 17px !important; }

#modal-post-title, #modal-post-desc {
  text-align: left;
  border: 1px solid #cacaca;
  padding: 10px;
  border-radius: 3px;
  box-shadow: 0 2px 1px rgba(0, 0, 0, 0.075); }

#close-post-modal {
  font-size: 18px;
  font-weight: 400;
  line-height: 1.4;
  margin-top: auto; }

#postTitle::-webkit-input-placeholder,
#postTitle::-ms-input-placeholder,
#postTitle::-moz-placeholder {
  color: #888888 !important; }

#modal-post-title-label {
  font-size: 18px; }

#autocomplete-content > p:first-child {
  margin-top: 12px;
  padding-top: 8px;
  border-top: 1px solid rgba(0, 0, 0, 0.075); }

#autocomplete-content > p:last-child {
  padding-bottom: unset;
  margin-bottom: unset; }

#autocomplete-content > p {
  width: 90%;
  overflow: hidden;
  white-space: nowrap;
  text-transform: capitalize;
  text-overflow: ellipsis;
  font-size: 13px; }

/*# posts modal styles */
#postStory {
  padding: 0;
  color: #81bd92;
  font-weight: 600;
  font-size: 9px;
  text-decoration: underline;
  margin-left: 21px;
  border: 0 !important;
  outline: none !important;
  margin-top: 10px; }

[placeholder]:focus::-webkit-input-placeholder {
  opacity: 0; }

[placeholder]:focus::-moz-placeholder {
  opacity: 0; }

[placeholder]:focus::-ms-input-placeholder {
  opacity: 0; }

.float-left {
  float: left; }

.mce-toolbar-grp .mce-flow-layout-item button > i.mce-i-image {
  padding-right: 5px !important; }

.mce-toolbar-grp .mce-flow-layout-item button > i.mce-i-image:before {
  font-size: 20px; }

.ask {
  text-align: center; }
  .ask:after {
    content: "";
    display: table;
    clear: both; }

#ask-your-question {
  position: relative;
  height: 62px;
  /*border: 1px solid #3F76CA;*/
  border: 1px solid #52c0a3;
  border-radius: 22px;
  font-family: Brandon, monospace;
  font-size: 17px;
  line-height: 3.5;
  color: #999999; }

.triangle-bottom {
  position: absolute;
  width: 30px;
  height: 30px;
  display: block;
  left: calc(50% - 14px);
  top: 61px;
  /*#ask-your-question:after, #ask-your-question:before */ }
  .triangle-bottom::after, .triangle-bottom::before {
    position: absolute;
    content: " ";
    border: solid transparent;
    height: 0;
    width: 0;
    left: 0;
    pointer-events: none; }
  .triangle-bottom::after {
    border-right-color: white;
    border-width: 0 28px 28px 0;
    margin-top: -1px; }
  .triangle-bottom::before {
    /*border-right-color: #3F76CA;*/
    border-right-color: #52c0a3;
    border-width: 0 30px 30px 0;
    margin-left: -1px; }
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .triangle-bottom {
      top: 60px; } }

@media (max-width: 767px) {
  #ask-your-question {
    margin-top: 11px; } }

#ask-your-question-modal .form-group #postTitle.b-form__input.form-warning {
  border: 2px solid rgba(255, 193, 7, 0.6); }

#ask-your-question-modal .form-group #postTitle.b-form__input.form-danger {
  border: 2px solid rgba(220, 53, 69, 0.6); }

@media (max-width: 767px) {
  #ask-your-question-modal .modal-dialog {
    margin: 10px 2px; } }

#postTitle-notification-content {
  font-size: 13px;
  margin-top: 15px; }

@media (max-width: 767px) {
  form .post-as-anonymous {
    font-size: 11px; } }

@media (max-width: 330px) {
  form .post-as-anonymous {
    font-size: 9px; } }

@media (max-width: 767px) {
  form #postsSubmit {
    width: auto;
    padding: 3px 25px; } }

@media (max-width: 330px) {
  form #postsSubmit {
    padding: 3px 12px; } }

.modal-footer {
  padding-top: 3px; }
  @media (max-width: 767px) {
    .modal-footer {
      padding-top: 3px; } }

@media (max-width: 767px) {
  .form-group.mobile-select {
    margin-bottom: 8px; } }

.modal-body.modal-body--ask {
  padding-bottom: 3px; }
  @media (max-width: 767px) {
    .modal-body.modal-body--ask {
      padding-bottom: 3px; } }

.find-friends {
  margin-bottom: 17px; }
  .find-friends:after {
    content: "";
    display: table;
    clear: both; }
  .find-friends .xdsoft_autocomplete {
    width: 315px !important;
    max-width: 100%; }
    .find-friends .xdsoft_autocomplete .xdsoft_input {
      padding: 0;
      padding-left: 4px;
      height: 21px;
      font: 13.3333px Brandon, Arial !important;
      border: 1px solid #B8C7D9; }
    .find-friends .xdsoft_autocomplete .xdsoft_autocomplete_dropdown {
      overflow: hidden; }

.recent-posts {
  margin-top: 7px; }

.recent-post__table {
  font-size: 16px;
  margin-bottom: 0; }

.recent-post__table tr:nth-child(even) {
  background-color: #f3f3f3; }

.recent-post__td {
  border-top: none !important; }

.recent-post__avatar-wrap {
  width: 57px;
  vertical-align: top;
  text-align: right;
  padding-left: 0 !important;
  padding-top: 6px !important;
  padding-right: 9px !important; }

.recent-post__avatar {
  height: 40px;
  width: 40px;
  /*margin-top: -2px;
  margin-right: 1px;*/ }

.recent-post__text-wrap {
  max-width: 210px;
  padding-right: 0 !important; }

.recent-post__author,
.recent-post__author > a {
  font-weight: bold;
  color: #009b85;
  line-height: 12px; }

.recent-post__date {
  float: right;
  padding-right: 5px;
  font-size: 12px;
  color: #a0a0a0;
  font-weight: 400; }

.recent-post__action {
  color: #6d6d6d; }

.recent-post__reply-text {
  width: 240px;
  overflow: hidden;
  color: #009b85;
  word-break: break-word; }
  .recent-post__reply-text:hover {
    color: #009b85; }

.feed-wrap {
  background-color: transparent !important; }

.feed-nav {
  cursor: pointer;
  font-size: 14px;
  color: black; }
  .feed-nav:hover {
    text-decoration: none;
    color: black; }

.feed-nav--doctor {
  padding-right: 0; }
  @media (max-width: 767px) {
    .feed-nav--doctor {
      padding-right: 15px; } }

.breadcrumb {
  padding-left: 10px;
  margin-bottom: 0;
  list-style: none;
  background-color: white;
  border-radius: 0;
  position: fixed;
  top: 114px;
  padding-top: 15px;
  width: 100%;
  z-index: 300;
  font-size: 16px; }
  @media (max-width: 767px) {
    .breadcrumb {
      left: 0;
      top: 45px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; } }

.admin .breadcrumb {
  position: static; }

.breadcrumb > li + li:before {
  padding: 0 5px;
  content: "\203A" !important;
  color: black;
  font-size: 22px;
  line-height: 22px; }

.breadcrumb .breadcrumb-home {
  display: inline-block;
  background-image: url("/assets/images/home-icon.png");
  background-repeat: no-repeat;
  background-size: contain;
  width: 16px;
  height: 14px;
  position: relative;
  top: 2px; }

.breadcrumb li a {
  color: #00BFA4 !important; }

.breadcrumb > .active {
  color: #00BFA4; }
  .breadcrumb > .active a {
    color: #00BFA4 !important; }

.pagination {
  margin-top: 13px;
  margin-bottom: 5px; }

.pagination > li > a,
.pagination > li > span {
  font-family: Brandon, Verdana, Arial, sans-serif;
  padding: 0 8px;
  padding-top: 1px;
  border: none;
  color: #009b85;
  font-size: 16px;
  background-color: transparent;
  margin-right: 6px; }
  .pagination > li > a:hover,
  .pagination > li > span:hover {
    color: #009b85;
    text-decoration: underline;
    background-color: transparent; }

.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover {
  text-decoration: none;
  background-color: #009b85; }

.pagination > .disabled > a,
.pagination > .disabled > a:focus,
.pagination > .disabled > a:hover,
.pagination > .disabled > span,
.pagination > .disabled > span:focus,
.pagination > .disabled > span:hover {
  background-color: transparent; }

.pagination > li:first-child > a,
.pagination > li:first-child > span {
  margin-left: 10px;
  border-radius: 0; }

.description-box {
  font-size: 17px;
  margin-bottom: 0;
  color: black; }
  @media (max-width: 767px) {
    .description-box {
      margin-top: 15px;
      padding-bottom: 52px; } }

@media (max-width: 767px) {
  .description-box table {
    width: 100% !important; } }

@media (max-width: 767px) {
  .description-box td {
    display: block;
    width: 100% !important;
    margin: 0 auto; } }

@media (min-width: 992px) {
  .description-box table:first-child {
    margin-bottom: 9px; } }

@media (min-width: 992px) {
  .description-box table:first-child td:first-child {
    padding: 5px;
    padding-right: 8px; } }

@media (max-width: 767px) {
  .description-box table:first-child td:first-child {
    margin-bottom: -40px; } }

.description-box table:first-child td:first-child p {
  margin: 0;
  line-height: 1.3em; }
  @media (max-width: 767px) {
    .description-box table:first-child td:first-child p {
      text-align: center !important; } }

.description-box table:first-child form input {
  margin-bottom: -1px;
  padding: 2px 4px;
  border: 1px solid #B8C7D9;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px; }
  @media (max-width: 767px) {
    .description-box table:first-child form input {
      display: block;
      margin: 0 auto; } }

@media (max-width: 767px) {
  .description-box form {
    margin-top: 15px;
    margin-right: 8px; } }

@media (max-width: 767px) {
  .description-box table:first-child form + p {
    margin-top: 17px !important; } }

@media (max-width: 767px) {
  .description-box table:first-child td:nth-child(2) {
    margin-bottom: -36px; } }

.description-box table:first-child td:nth-child(2) p {
  text-align: center !important; }
  @media (max-width: 767px) {
    .description-box table:first-child td:nth-child(2) p {
      margin-left: 10px; } }

.description-box table:first-child img {
  position: relative;
  bottom: -4px;
  left: -1px;
  float: none !important;
  margin: 0 auto !important; }
  @media (max-width: 767px) {
    .description-box table:first-child img {
      display: block;
      width: 100% !important; } }

.description-box > p {
  margin-left: 5px;
  margin-bottom: -2px; }
  @media (max-width: 767px) {
    .description-box > p {
      margin-top: 40px; } }

.description-box table:nth-child(3) {
  margin-bottom: 2px;
  margin-left: 5px; }

@media (max-width: 767px) {
  .description-box table:nth-child(3) td {
    margin-bottom: 3px; } }

@media (max-width: 767px) {
  .description-box table img {
    display: block;
    height: 143px;
    width: 96% !important; } }

.top-post__title {
  margin-bottom: 1px;
  margin-left: 1px; }
  @media (max-width: 767px) {
    .top-post__title {
      margin-bottom: -1px;
      margin-left: 9px;
      margin-top: 5px; } }

.top-post__table {
  margin-left: 5px; }

.category__head {
  margin-top: 10px;
  padding-bottom: 10px; }
  .category__head:after {
    content: "";
    display: table;
    clear: both; }

.category__title {
  display: inline-block;
  font-size: 25px;
  color: #eb6900;
  margin: 0;
  padding-left: 10px; }

.category__follow {
  position: relative;
  top: 7px;
  right: 4px;
  float: right;
  color: #00BFA4;
  font-size: 16px; }
  .category__follow:focus {
    color: inherit;
    text-decoration: none; }
  .category__follow:hover {
    color: #009b85;
    opacity: 0.9;
    text-decoration: none; }

.category__description {
  margin-right: -10px;
  font-size: 17px;
  margin-top: 6px;
  padding-left: 5px;
  padding-right: 5px;
  margin-bottom: 18px;
  color: black; }
  @media (max-width: 767px) {
    .category__description {
      margin-right: 0; } }

.category__description p {
  line-height: normal;
  margin: 0; }

.category__description img {
  margin-bottom: 5px; }

.category__local-organizations {
  margin-top: -8px;
  margin-bottom: 26px; }

.category__local-organizations p {
  line-height: 1.4em;
  margin-bottom: 1.3em; }

.local-organizations .subcategories__item {
  padding-left: 10px; }

.category__business-investments p:first-child {
  margin-bottom: 3px; }

.category__blogs-filipino-literature {
  margin-bottom: 11px; }

.category__blogs {
  margin-bottom: 28px; }

.category__description a {
  color: #eb6900; }
  .category__description a:hover {
    color: #eb6900; }

@media (max-width: 767px) {
  .category__description img {
    display: block !important;
    float: none    !important;
    margin: 0 auto !important; } }

.category__link {
  font-size: 16px; }

.doctors {
  margin-bottom: 26px; }

.doctors__title {
  display: inline-block;
  margin-top: 11px;
  color: #00BFA4;
  font-size: 14px;
  padding-left: 5px;
  margin-right: 6px; }

.doctors-noactivity {
  margin-top: 20px;
  margin-left: 15px;
  margin-bottom: 57px; }

.subcategories {
  margin-bottom: 6px;
  padding-top: 6px; }

.local-organizations {
  margin-bottom: 47px; }

.subcategories__item {
  margin-bottom: 8px;
  font-size: 15px;
  padding-left: 25px; }
  .subcategories__item--doctors {
    margin: 4px 0; }

@media (min-width: 992px) {
  .tanggapan-ng-konsulado .subcategories {
    margin-bottom: 47px;
    padding-top: 14px;
    margin-left: -15px;
    margin-right: 73px; } }

.tanggapan-ng-konsulado .subcategories .col-md-4 {
  padding-right: 0;
  padding-left: 0; }

.business-img {
  margin-bottom: -12px !important; }

.business-investments {
  margin-top: -10px; }

.blogs .subcategories {
  margin-left: -30px; }
  .blogs .subcategories .subcategories__item a {
    line-height: normal; }

.sign-up__header-top {
  max-width: 950px;
  margin: 0 auto; }
  @media (max-width: 767px) {
    .sign-up__header-top #headerLogo {
      position: relative;
      top: 0;
      left: 0; } }

.sing-up__fb-like {
  margin-top: 14px; }

.sing-up__panel-heading {
  color: #eb6900 !important;
  font-family: Brandon, Verdana, Geneva, sans-serif;
  font-size: 20px;
  border: none;
  background-color: white !important; }
  @media (min-width: 992px) {
    .sing-up__panel-heading {
      padding-top: 8px;
      padding-left: 9px;
      padding-bottom: 0;
      margin-bottom: -1px; } }
  .sing-up__panel-heading span {
    font-size: 12px; }

.sing-up__example {
  font-size: 12px;
  line-height: 1.25;
  margin-top: 2px;
  margin-bottom: 0;
  color: black; }

.sing-up__login-body {
  padding-left: 9px;
  padding-right: 16px; }

.sing-up__login-body .help-block {
  display: block;
  max-width: 190px;
  margin: 0 auto;
  text-align: left;
  font-size: 12px; }
  .sing-up__login-body .help-block strong {
    font-weight: 400;
    color: red;
    margin-left: -14px; }

.sing-up__login-body .has-error .control-label {
  color: black; }

.sing-up__login-body .has-error .form-control {
  border-color: red;
  border-style: dotted;
  margin-bottom: -1px; }

.sing-up__login-body label {
  margin-bottom: 0; }

.sing-up__control-label {
  font-family: Brandon, Verdana, Geneva, sans-serif;
  font-weight: normal;
  font-size: 14px;
  color: black; }

.sign-up__label-gender {
  min-width: 200px; }

.registration-step .help-block {
  display: block;
  max-width: 190px;
  margin: 0 auto;
  text-align: left;
  font-size: 12px;
  line-height: 1em; }
  .registration-step .help-block strong {
    display: block;
    margin-left: -8px;
    font-weight: 400;
    color: red; }

.registration-step .has-error .form-control {
  border-color: red;
  border-style: dotted;
  margin-bottom: -1px; }

.registration-step label {
  margin-bottom: 0; }

.registration-step .has-error .control-label {
  color: black; }

.registration-step1 .help-block {
  display: block;
  max-width: 190px;
  margin: 0 auto;
  text-align: left;
  font-size: 12px; }
  .registration-step1 .help-block strong {
    font-weight: 400;
    color: red;
    margin-left: 5px; }

@media (min-width: 992px) {
  .sing-up__sing-in-block {
    padding: 0;
    margin-right: -15px;
    width: 247px;
    float: left;
    border-top: 1px solid #CFE0CE; } }

@media (min-width: 992px) {
  .sing-up__sing-up-block {
    padding: 0;
    margin-left: -15px;
    width: 704px;
    float: right;
    border: 2px solid #CFE0CE;
    border-top: 1px solid #CFE0CE; } }

.sing-up__container {
  max-width: 950px !important;
  padding-top: 0; }

.sing-up__input-login {
  height: 27px;
  border-radius: 3px;
  padding: 5px;
  width: 232px;
  color: #4D4D4D;
  border: 1px solid #B8C7D9;
  font-family: Brandon, Arial, sans-serif;
  font-size: 15px; }
  @media (max-width: 767px) {
    .sing-up__input-login {
      margin: 0 auto; } }

.sing-up__login-btn {
  border-radius: 3px;
  padding: 3px 27px;
  color: #4D4D4D;
  font-size: 17px;
  border: 1px solid #B8C7D9;
  font-family: Brandon, Arial, sans-serif; }

.sing-up__forgot {
  color: #336600; }
  .sing-up__forgot:hover {
    color: #336600;
    text-decoration: underline; }

.sing-up__forgot-wrap {
  margin-top: 14px; }

.sing-up__login-form--email {
  margin-bottom: 16px; }

.sing-up__control-label--password {
  margin-bottom: 2px !important; }

.sing-up__panel {
  border-color: transparent;
  box-shadow: none; }

.register__body {
  padding-top: 12px;
  padding-left: 10px;
  padding-right: 10px; }
  @media (min-width: 992px) {
    .register__body {
      margin-bottom: 23px; } }

.registration-step1 .form-group:nth-child(3) {
  margin-bottom: 10px; }

.registration__label {
  color: black;
  font-size: 14px;
  font-family: Brandon, Verdana, Geneva, sans-serif;
  font-weight: 400;
  padding-top: 0; }

.registration__input {
  margin-bottom: 1px;
  border-radius: 3px;
  padding: 2px 4px;
  height: 28px !important;
  width: 191px;
  border: 1px solid #B8C7D9;
  font-family: Brandon, Arial, sans-serif;
  font-size: 15px;
  font-weight: 400;
  color: #4d4d4d; }

.registration__input--select {
  color: #99a2ac;
  padding-left: 0; }

@media (min-width: 992px) {
  .register__checkbox1 {
    padding-top: 0;
    padding-left: 70px !important;
    font-size: 12px;
    color: #000; } }

.register__text-block1 {
  padding-left: 20px;
  /*p:nth-last-child(2) {
    margin-bottom: -2px;
  }*/ }
  .register__text-block1 p {
    margin-bottom: 8px; }
    .register__text-block1 p span {
      color: black; }
  .register__text-block1 p:last-child {
    padding: 0 45px;
    line-height: 1.3em;
    margin-bottom: 10px;
    margin-top: 9px; }

.register__join-btn {
  margin-left: 20px;
  width: 94px;
  height: 36px;
  background-color: #EB6900;
  text-align: center;
  color: white;
  font-size: 17px;
  text-transform: uppercase;
  border: 0;
  cursor: pointer;
  font-family: Brandon, Arial, sans-serif;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px; }

.register-description {
  padding-left: 19px;
  padding-right: 18px;
  margin-bottom: 10px;
  padding-top: 2px; }
  .register-description p {
    color: black;
    line-height: 1.3em;
    margin-bottom: 8px; }
  .register-description a {
    color: #336000;
    text-decoration: none; }
    .register-description a:hover, .register-description a:focus, .register-description a:hover:focus {
      text-decoration: underline;
      color: #336000; }

@media (max-width: 767px) {
  .register-description td {
    display: block;
    width: 100% !important; } }

.sing-up__panel--expert-select {
  margin-bottom: 2px; }

.register__save-btn {
  font-size: 17px;
  width: 156px;
  height: 36px;
  border-radius: 2px;
  font-family: Brandon, Arial, sans-serif; }

.register__location-group {
  padding-top: 2px;
  margin-bottom: 15px; }

.registration__label--location {
  margin-top: -1px;
  margin-left: -2px;
  padding: 0 !important;
  text-align: left !important; }

.registration__input--location {
  margin-left: -3px;
  width: 190px; }

.registration__form--location {
  margin-bottom: 8px; }

.registration__detect {
  position: relative;
  left: 32px;
  top: 2px;
  font-size: 12px;
  color: black;
  font-style: italic; }
  @media (max-width: 767px) {
    .registration__detect {
      left: 0; } }

.registration__form--step-2 {
  margin-bottom: 18px; }

.registration__label--step-2 {
  text-align: left !important;
  padding-top: 2px !important; }

.registration__input--step-2 {
  margin-left: -17px; }

.help-block--step2 {
  margin-left: -17px; }

.register__save-btn--step-2 {
  margin-left: -83px; }
  @media (max-width: 991px) {
    .register__save-btn--step-2 {
      margin-left: 0; } }
  @media (max-width: 767px) {
    .register__save-btn--step-2 {
      margin-left: 0; } }

.registration__form--step-2 .help-block strong {
  margin-left: -25px; }

.register__text-block3 {
  margin-bottom: 6px; }

.registration__input--s3-country {
  margin-left: 145px;
  top: 3px;
  position: relative;
  height: 28px !important;
  width: 180px;
  padding-left: 4px; }
  @media (max-width: 991px) {
    .registration__input--s3-country {
      margin-left: 15px; } }

.form-group--country-select {
  margin-bottom: 17px; }

.registration__input--s3-phone-code {
  display: inline-block;
  margin-left: 27px;
  max-width: 55px;
  padding-left: 12px; }
  @media (max-width: 991px) {
    .registration__input--s3-phone-code {
      margin-left: 15px; } }

.registration__input--s3-phone-number {
  max-width: 140px; }

.form-group--phone {
  margin-bottom: 7px; }
  .form-group--phone label {
    vertical-align: bottom; }

@media (min-width: 992px) {
  .form-group--phone.has-error .help-block {
    margin-left: 153px; } }

@media (max-width: 991px) {
  .form-group--phone.has-error .help-block {
    margin-left: 23px; } }

.register__s3-example {
  margin-bottom: 14px; }
  @media (min-width: 992px) {
    .register__s3-example {
      margin-left: 130px; } }
  .register__s3-example span {
    font-size: 12px; }

.registering {
  padding-right: 30px !important;
  background: url(/assets/images/loading.gif) #dddddd 95% 50% no-repeat;
  background-size: 20px;
  color: black;
  text-shadow: none !important; }

.experts-select__input {
  border-radius: 3px;
  padding: 2px 4px;
  width: 180px;
  color: #4D4D4D;
  border: 1px solid #B8C7D9;
  display: block;
  margin-top: -8px;
  height: 23px;
  margin-bottom: 8px; }

.experts-select__btn {
  font-family: Brandon, Arial, sans-serif;
  font-size: 17px;
  color: #4d4d4d;
  border: 1px solid #B8C7D9;
  padding: 0 59px;
  border-radius: 3px;
  background-color: #DDDDDD; }

.experts__container {
  padding-top: 0; }
  @media (min-width: 992px) {
    .experts__container {
      width: 980px; } }

@media (min-width: 992px) {
  .experts__row {
    margin: 0; } }

@media (min-width: 992px) {
  .experts__col-descr {
    float: left;
    width: 416px;
    padding: 0; } }

.experts___col-reg {
  color: black; }
  @media (min-width: 992px) {
    .experts___col-reg {
      float: left;
      width: 534px;
      padding: 0; } }

.experts__form-group {
  margin-bottom: 10px; }

.experts__panel-all {
  border: 2px solid #CFE0CE;
  border-top-width: 1px;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0; }

.experts__panel-top {
  border-top: 1px solid #CFE0CE;
  border-bottom: none;
  border-left: none;
  border-right: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  box-shadow: none; }

.experts___panel-heading {
  color: #eb6900 !important;
  font-family: Brandon, Verdana, Geneva, sans-serif;
  font-size: 20px;
  border: none;
  background-color: white !important; }
  @media (min-width: 992px) {
    .experts___panel-heading {
      padding-top: 9px;
      padding-left: 11px;
      padding-bottom: 0;
      margin-bottom: 3px; } }

@media (min-width: 992px) {
  .experts__panel-body {
    padding: 10px; } }

.experts__text-block {
  color: black; }
  .experts__text-block p {
    line-height: 1.3em; }

.expert__empty-space {
  margin-bottom: 12px; }

.experts__first-line {
  margin-top: -2px;
  margin-bottom: 14px; }

.experts__second-line {
  margin-bottom: 14px; }

.experts__location {
  font-size: 12px;
  color: black;
  font-style: italic;
  font-weight: 400; }

.experts__btn-upload {
  margin-top: 14px;
  margin-left: -10px;
  margin-bottom: 2px;
  padding: 5px 39px; }

.experts__btn-submit {
  margin-top: 14px;
  margin-bottom: -14px;
  padding: 9px 20px;
  border-radius: 3px;
  font-size: 14px; }

.experts__btn-submit--margins {
  margin-top: 25px;
  margin-bottom: 15px; }

.experts__field {
  max-width: 201px; }

.experts__textarea {
  width: 400px;
  height: 79px !important;
  padding: 0; }

.experts__first-p {
  line-height: 1.7em !important;
  margin-bottom: 7px; }

.experts__panel-body .help-block {
  width: 260px;
  margin: 0 auto;
  margin-top: -4px; }

.experts__form-group .chosen-container {
  max-width: 260px !important; }

.experts__form-group .chosen-container a {
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px; }

.experts__form-group .chosen-container span {
  color: #4d4d4d;
  font-weight: 400;
  text-align: left;
  padding-left: 5px; }

.forgot-password__title {
  color: #1A1A1A;
  font-size: 20px;
  margin-top: 7px;
  margin-bottom: 15px; }

.forgot-password__label {
  margin-top: 6px;
  margin-bottom: 17px;
  padding: 0;
  font-weight: 400;
  font-size: 14px;
  text-align: left; }

.forgot-password__input {
  height: 21px;
  width: 178px;
  border-radius: 3px;
  padding: 2px 4px;
  color: #4D4D4D;
  font-size: 15px;
  border: 1px solid #B8C7D9;
  margin: 0; }

.myposts__title {
  margin-top: 6px;
  font-size: 26px;
  margin-left: 15px;
  margin-bottom: 26px; }

.empty-table--myposts {
  margin-top: 36px;
  margin-bottom: 34px;
  font-size: 16px; }

.myposts__table {
  width: 100%; }

.myconnection__title {
  margin-top: 17px;
  margin-bottom: 15px; }

.myconnection__descr {
  margin-left: 5px;
  margin-right: -10px;
  margin-bottom: 21px; }
  .myconnection__descr p {
    margin-bottom: 0;
    text-align: justify;
    line-height: 1.3em; }

.mymessages__title {
  margin-top: 16px;
  margin-bottom: 17px; }

.mymessages__descr {
  margin-left: 5px;
  margin-right: 5px;
  line-height: 1.3em;
  margin-bottom: 20px; }

@media (min-width: 992px) {
  .mymessages .b-table__wrap {
    width: 100%; } }

.mymessages .empty-table {
  margin-top: 47px;
  margin-bottom: 45px;
  letter-spacing: 1px; }

.c-messages__col-label {
  display: inline-block;
  vertical-align: top; }
  @media (min-width: 992px) {
    .c-messages__col-label {
      width: 113px; } }
  @media (max-width: 991px) {
    .c-messages__col-label {
      width: 100%; } }

.c-messages__col-input {
  display: inline-block;
  vertical-align: top; }
  @media (min-width: 992px) {
    .c-messages__col-input {
      width: 412px; } }
  @media (max-width: 991px) {
    .c-messages__col-input {
      width: 100%; } }

.c-messages__form {
  margin-top: 20px; }

.c-messages__form-group {
  margin: 0 !important; }

.c-messages__label {
  padding-top: 0 !important;
  padding-left: 18px;
  font-weight: 400;
  font-family: Brandon, Verdana, Arial, sans-serif;
  font-size: 14px;
  color: #222222; }

.c-messages__help {
  margin-left: 127px;
  margin-top: 9px;
  margin-bottom: 26px;
  padding: 0;
  font-weight: 400;
  font-family: Brandon, Verdana, Arial, sans-serif;
  font-size: 15px;
  color: #000000; }

.c-messages__input {
  width: 100%;
  height: 27px;
  font-family: Brandon, monospace;
  font-size: 15px;
  color: #333333;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  border: 1px solid #B8C7D9; }

.c-messages__form-group--textarea {
  margin-top: 17px !important; }

.c-messages__input--textarea {
  height: 162px !important;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0; }

.c-messages__btns-wrap {
  margin-top: 33px;
  margin-bottom: 19px; }

.c-messages__btn {
  font-size: 18px;
  width: 94px;
  border-radius: 3px; }

.c-messages__btn--submit {
  margin-left: 52px; }

.c-messages__btn--reset {
  float: right; }

.modal__content {
  padding: 3px;
  overflow: hidden;
  border: 1px solid #009b85;
  color: #222222;
  font-family: Brandon, Verdana, Arial, sans-serif;
  max-width: 589px;
  margin-top: -20px; }
  @media (max-width: 767px) {
    .modal__content {
      margin-top: unset; } }
  .modal__content a {
    color: #009b85; }
    .modal__content a:hover {
      color: #009b85; }

.login__content .modal-body {
  padding: 11px 19px 0; }

.login__content {
  z-index: 1002;
  position: fixed;
  left: 50%;
  top: 23vh;
  display: block;
  height: auto;
  width: 288px;
  margin-left: -144px;
  padding: 2px;
  overflow: hidden;
  outline: none;
  border: 1px solid #009b85;
  border-radius: 4px;
  box-shadow: none; }
  .login__content a {
    color: #009b85; }
    .login__content a:hover {
      color: #009b85; }
  .login__content .help-block {
    width: 100%;
    text-align: center;
    margin-top: 0;
    color: red;
    font-size: 12px; }
    .login__content .help-block strong {
      font-weight: 400; }
  .login__content .control-label {
    font-weight: normal; }

.login__content .form-group .control-label {
  font-size: 16px;
  color: #222222;
  font-family: Brandon, Verdana, Arial, sans-serif; }

.modal-body--ask {
  padding-top: 24px; }

.modal__title {
  color: white;
  font-size: 16px;
  font-weight: bold;
  font-family: Brandon, Verdana, Geneva, sans-serif; }

.login__login-form {
  margin-top: 5px;
  margin-bottom: 3px; }

.login__password-form {
  margin-bottom: 10px; }

.modal__form-control--login {
  height: 25px;
  width: 177px;
  margin: 0 auto;
  font-size: 14px;
  padding: 0 5px; }

.modal__header {
  padding: 5px 15px 5px 15px;
  font-size: 16px;
  color: white;
  border: 1px solid #00BFA4;
  border-radius: 5px;
  background-color: #00BFA4; }

.modal__close {
  position: relative;
  right: -8px;
  top: 5px;
  padding: 0 1px !important;
  text-shadow: none;
  font-size: 18px;
  font-weight: bold;
  opacity: 1;
  border: 1px solid transparent;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px; }
  .modal__close:hover {
    background-color: lightgray;
    opacity: 1;
    border: 1px solid black; }

.sign-in-modal__new-user {
  font-size: 16px;
  color: #222222;
  font-family: Brandon, Verdana, Arial, sans-serif; }

.post-text--label {
  font-size: 16px; }

.post-text--green {
  color: green;
  font-size: 13px; }

.modal__forgot {
  float: left;
  margin-top: 8px;
  margin-bottom: 3px;
  margin-left: 1px; }

.login__content .has-error .form-control {
  border: 1px dotted #ff0000; }

.sing-up-modal__post-info {
  margin-left: 5px;
  margin-bottom: 11px;
  font-size: 16px;
  font-family: Brandon, Verdana, Arial, sans-serif;
  color: #222222; }

.sing-up-modal__body {
  padding-top: 12px; }

.sing-up-modal__btn-wrap {
  margin-bottom: 0; }

#loginCol {
  margin-left: -5px;
  margin-top: 1px;
  font-size: 16px;
  text-align: right; }
  @media (max-width: 767px) {
    #loginCol {
      text-align: center;
      margin-top: 15px;
      margin-left: 0;
      font-size: 16px; } }

.sing-up-modal__body .step1 .help-block {
  margin-left: 27px; }

#loginFormContainer .step1 .b-form__input {
  margin-left: 20px;
  max-width: 200px; }

#loginFormContainer .step1 .next {
  margin-left: 20px;
  width: 94px;
  height: 36px; }

#loginFormContainer .step2 .b-form__label {
  padding-top: 4px !important;
  padding-left: 35px; }

#loginFormContainer .step2 .b-form__input {
  margin-left: 17px;
  width: 200px;
  margin-top: 1px; }

#loginFormContainer .step2 .form-group {
  margin-bottom: 11px; }

#loginFormContainer .step2 .form-group:last-child {
  margin-bottom: -1px; }

#loginFormContainer .step2 .checkbox {
  padding-top: 0;
  margin-top: -5px;
  margin-left: 40px;
  margin-bottom: -3px; }

#loginFormContainer .step2 .next {
  width: 94px;
  margin-left: -132px;
  height: 36px;
  border-radius: 3px; }

#loginFormContainer .step3 .b-form__label {
  padding-top: 4px !important;
  padding-left: 35px; }

#loginFormContainer .step3 .b-form__input {
  margin-left: 17px;
  width: 200px;
  margin-top: 1px; }

#loginFormContainer .step3 .form-group {
  margin-bottom: 11px; }

#loginFormContainer .step3 .form-group:last-child {
  text-align: center;
  margin-bottom: -1px; }

#loginFormContainer .step3 .next {
  margin-left: 6px;
  border-radius: 3px; }

#loginFormContainer .step3 .help-block strong {
  margin-left: 31px;
  width: 220px; }

#loginFormContainer .step4 .b-form__label {
  padding-top: 4px !important;
  padding-left: 35px;
  padding-right: 7px; }

#loginFormContainer .step4 .b-form__input {
  margin-left: 17px;
  width: 200px;
  margin-top: 1px; }

#loginFormContainer .step4 .form-group {
  margin-bottom: 11px; }

#loginFormContainer .step4 .form-group:last-child {
  text-align: center;
  margin-bottom: -1px; }

#loginFormContainer .step4 .next {
  margin-left: 6px;
  border-radius: 3px; }

#loginFormContainer .step4 .help-block strong {
  margin-left: 31px;
  width: 220px; }

.abuse-m__label {
  margin-bottom: 0;
  line-height: 1em; }

.abuse-m__form-group {
  margin-bottom: 1px; }

.abuse-m__select {
  width: 251px;
  margin-left: -3px;
  padding-top: 1px;
  padding-left: 1px;
  color: #333333;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0; }

.abuse-m__textarea {
  width: 251px;
  height: 103px !important;
  margin-left: -3px;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0; }

.abuse-m__footer {
  border-top: 0;
  padding-top: 12px;
  padding-bottom: 20px; }

.abuse-m__btn {
  height: 36px;
  width: 93px;
  border-radius: 3px;
  font-family: Brandon, Arial, sans-serif;
  font-size: 17px; }

.abuse-m__btn--left {
  float: left;
  margin-left: 4px; }

.abuse-m__btn--right {
  float: right;
  margin-right: 4px; }

.posted-m__content {
  z-index: 1002;
  position: fixed;
  left: 50%;
  top: 23vh;
  display: block;
  height: auto;
  width: 488px;
  margin-left: -239px;
  padding: 2px;
  overflow: hidden;
  outline: none;
  border: 1px solid #336600;
  border-radius: 4px;
  box-shadow: none;
  max-width: 100%; }
  @media (max-width: 767px) {
    .posted-m__content {
      left: 240px;
      top: 10vh; } }
  .posted-m__content .modal-body {
    padding: 10px; }

.posted-m__title {
  margin-top: 0;
  font-weight: bold;
  letter-spacing: -1px;
  margin-bottom: 22px; }

.posted-m__text {
  font-size: 16px; }

.posted-m__img {
  margin-top: 13px;
  margin-bottom: 19px; }

.posted-m__tips {
  max-width: 260px;
  margin: 0 auto;
  line-height: 1.3em;
  margin-bottom: 14px; }

.message-modal {
  border: 1px solid black;
  border-radius: 2px; }

.message-modal__inner {
  background-color: #E9F5E9;
  border-radius: 2px;
  padding: 0; }

.message-modal__img-wrap {
  float: left;
  padding: 10px; }

.message-modal__user-info {
  border: 1px solid #B8C7D9;
  background-color: #CEF2CE;
  margin: 13px 10px 0 100px;
  padding: 10px;
  height: 55px; }

.message-modal__author {
  color: #336600;
  font-weight: bold;
  font-size: 15px; }
  .message-modal__author:hover {
    text-decoration: underline; }

.message-modal__date {
  font-size: 14px;
  color: #505050;
  text-align: right; }

.message-modal__content {
  border: 1px solid #B8C7D9;
  padding: 10px;
  margin: 10px;
  clear: both; }

.message-modal__textarea {
  margin: 0 10px; }
  .message-modal__textarea textarea {
    border: 1px solid #B8C7D9;
    border-radius: 0; }

.message-modal__cancel {
  border: 0;
  float: right;
  height: 36px;
  margin: 10px;
  width: 94px;
  cursor: pointer; }

.profile a {
  color: #009b85; }
  .profile a:focus {
    color: #009b85;
    text-decoration: none; }
  .profile a:hover {
    color: #009b85;
    text-decoration: underline; }

/*.profile-avatar > a > img {
  display: block;
  $size: 182px !important;
  width: $size;
  height: $size;
  margin: 0 auto 10px;
  border-color: #CFE0CE;
}*/
.profile-avatar .img-thumbnail {
  border-radius: 50%; }

.profile__likes-wrap {
  margin-bottom: 10px;
  line-height: 1.1em; }

.profile__like-label {
  font-size: 13px;
  text-decoration: underline;
  color: #eb6900; }

.profile__like-username {
  font-size: 13px;
  color: #eb6900; }

.profile__like {
  position: relative;
  margin-top: 10px;
  font-size: 13px;
  color: #969595; }
  .profile__like::after {
    content: "";
    position: absolute;
    top: -4px;
    left: 45px;
    width: 14px;
    height: 17px;
    background: url("/assets/images/thumb_up.png") no-repeat; }

.profile__textarea {
  font-size: 13px;
  margin: 0 auto 10px;
  font-family: Brandon, monospace;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0; }

.profile__post {
  margin-bottom: 10px;
  padding: 3px 20px;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0; }

.profile__connect-btn {
  margin-bottom: 10px;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  font-size: 20px;
  padding: 4px 12px; }

.profile__sent-btn {
  margin-top: 10px; }

.profile__login {
  display: block;
  text-align: center; }

.profile__title {
  margin-top: 10px;
  margin-bottom: 15px;
  padding-left: 0;
  font-size: 32px; }

.profile__table > tbody > tr {
  background-color: #F3FFEC; }

.profile__table > tbody > tr > td {
  border-top: none; }

.profile__table .profile__table-label {
  padding: 5px 3px;
  color: #949469;
  font-size: 15px;
  vertical-align: middle; }

.profile__table .profile__table-value {
  padding: 0;
  font-size: 16px;
  vertical-align: middle; }

.profile__table--stripped > tbody > tr:nth-child(even) {
  background-color: #fafff7; }

.profile__table--sidebar > tbody > tr > td {
  padding-top: 2px;
  padding-bottom: 2px;
  color: #545454; }

.profile__table--sidebar .dashed-underline {
  color: #949469; }

.profile__table--sidebar .profile__all-posts {
  display: block;
  margin-top: 10px; }

.user-type-logo {
  display: inline-block; }

.user-type-logo-small {
  width: 20px !important;
  height: 20px;
  vertical-align: baseline;
  margin-left: 5px; }

.auth-label {
  margin-bottom: 15px;
  text-align: left; }

.auth-label a {
  font-weight: bold; }

.auth-label ul {
  padding-left: 15px;
  padding-top: 5px; }

.dashed-underline + .tooltip .tooltip-inner {
  background-color: #fafff7;
  color: #949469;
  border: 1px solid #949469; }

.dashed-underline + .tooltip.left > .tooltip-arrow {
  border-left: 5px solid #949469; }

.dashed-underline + .tooltip.top > .tooltip-arrow {
  border-top: 5px solid #949469; }

.dashed-underline + .tooltip.right > .tooltip-arrow {
  border-right: 5px solid #949469; }

.dashed-underline + .tooltip.bottom > .tooltip-arrow {
  border-bottom: 5px solid #949469; }

.profile-more-info {
  margin-bottom: 10px;
  padding: 5px;
  text-align: center;
  background-color: #f9f9f9;
  font-size: 15px; }
  .profile-more-info .profile-more-info__img {
    margin-top: 10px; }
  .profile-more-info .profile-more-info__text {
    margin-top: 10px;
    color: #949469;
    text-align: center; }
  .profile-more-info .profile-more-info__link-wrap {
    margin-top: 10px;
    text-align: center;
    color: #336600;
    text-decoration: none; }

.profile .subtitle {
  font-size: 18px;
  margin-bottom: 10px;
  font-weight: 400; }

.profile .connect {
  margin-top: 0;
  margin-right: 0; }

@media (max-width: 767px) {
  .profile-avatar {
    margin-top: 15px;
    margin-bottom: 15px;
    text-align: center; }
  .profile__like::after {
    top: -5;
    left: 75px; } }

.profile__profession {
  margin-top: -10px;
  margin-bottom: 5px;
  font-style: italic;
  font-size: 16px;
  color: #545454; }

.profile__hospitals-title {
  font-size: 16px;
  margin-bottom: 1px;
  margin-top: 20px;
  color: #545454; }

.profile__hospital {
  margin-bottom: 5px;
  color: #545454; }

.profile__hospital-name {
  text-decoration: underline; }

.profile__prof-info {
  font-size: 16px;
  color: #545454; }

.profile__visible {
  float: right;
  margin-top: 15px; }

.profile__last-active {
  color: #737373;
  font-size: 13px; }
  .profile__last-active:before {
    display: inline-block;
    vertical-align: middle;
    width: 10px;
    height: 10px;
    margin-right: 5px;
    content: '';
    background-image: url("/assets/images/icon_clock.svg");
    background-size: cover; }

.edit-profile .form-group {
  margin-left: 0; }

.edit-profile .control-label {
  font-weight: normal;
  font-size: 15px;
  text-align: left; }

.change-password {
  margin-top: 15px;
  margin-bottom: 15px;
  margin-left: 15px;
  font-size: 16px; }

.saving {
  padding-right: 30px !important;
  background: url(/assets/images/loading.gif) #dddddd 95% 50% no-repeat;
  background-size: 20px; }

.edit-picture__wrap {
  border: 2px solid #CFE0CE;
  padding: 10px;
  max-width: 950px;
  margin: 0 auto; }

.edit-picture__set {
  font-size: 18px;
  font-weight: bold;
  margin-top: -2px;
  margin-bottom: 13px; }

.edit-picture__img-wrap {
  margin: 0 auto;
  width: 50px; }

.edit-picture__img {
  display: block;
  margin: 0 auto;
  width: 50px;
  height: 50px;
  background-color: red;
  margin-bottom: 18px;
  margin-left: 10px; }

.edit-picture__upload-btn {
  padding: 5px 41px;
  margin-left: 15px; }

.edit-picture__txt {
  margin-top: 52px;
  margin-bottom: 22px;
  font-size: 25px;
  color: #eb6900;
  margin-left: 26px;
  line-height: 1.3em; }

.edit-picture__warning {
  margin-bottom: 60px;
  margin-left: -10px;
  color: black; }

.edit-picture__upload-later {
  padding: 2px 50px;
  color: #4D4D4D;
  border: 1px solid #B8C7D9;
  background-color: #dddddd;
  border-radius: 4px;
  font-family: Brandon, Arial, sans-serif;
  font-size: 17px; }

.crop-picture__wrap {
  border: 2px solid #CFE0CE;
  padding: 10px; }
  @media (min-width: 992px) {
    .crop-picture__wrap {
      width: 950px;
      margin: 0 auto; } }

@media (min-width: 992px) {
  .crop-picture__col-edit {
    float: left;
    width: 550px;
    max-width: 550px; } }

@media (min-width: 992px) {
  .crop-picture__col-preview {
    float: left;
    width: 250px;
    text-align: left; } }

.crop-picture__title {
  margin: 0;
  font-family: Brandon, Verdana, Geneva, sans-serif;
  font-size: 20px;
  color: #eb6900;
  font-weight: 400;
  line-height: 1.2em; }

.crop-picture__title--image {
  margin-bottom: 15px; }

.crop-picture__btn-wrap {
  margin-top: 15px; }

.crop-picture__btn {
  display: inline-block;
  padding: 0;
  font-family: Brandon, Arial, sans-serif;
  color: #333333;
  font-size: 15px; }

.crop-picture__preview {
  width: 82px;
  height: 82px;
  overflow: hidden;
  border: 1px solid #000; }

.recommendation__panel {
  position: relative;
  margin-top: 10px;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  border-color: #24B300;
  background-color: #E9F5E9; }
  .recommendation__panel::before {
    content: "";
    position: absolute;
    top: -5px;
    left: 20px;
    width: 11px;
    height: 34px;
    margin-top: -17px;
    background-image: url("/assets/images/answers-arrow.png");
    transform: rotate(90deg); }
  .recommendation__panel .recommendation__heading {
    background-color: transparent;
    border-bottom: 0;
    font-weight: bold; }
  .recommendation__panel .recommendation__body {
    padding-top: 0;
    color: #666666;
    font-size: 14px; }
  .recommendation__panel .recommendation__date {
    margin-top: 1em;
    font-size: 13px;
    color: black; }
  .recommendation__panel .recommendation__remove {
    position: absolute;
    top: 0;
    right: 0;
    width: 15px;
    text-align: center;
    background-color: #5cbd21;
    color: #E5F8DA;
    cursor: pointer; }

.recommended-by {
  max-width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 300px; }
  .recommended-by__user-name {
    color: #336600;
    font-weight: bold; }
  .recommended-by__user-job {
    font-weight: normal;
    font-style: italic;
    color: gray; }
  .recommended-by__user-state {
    font-weight: normal;
    font-style: italic;
    color: gray; }
  .recommended-by__connect a {
    color: orange;
    font-style: italic;
    font-weight: normal;
    cursor: pointer; }

.profile__like {
  /* Popover */
  /* Popover Header */
  /* Popover Body */
  /* Popover Arrow */ }
  .profile__like .popover {
    border: 5px solid #cfe0ce; }
  .profile__like .arrow {
    border-top-color: blue !important; }

.medcard {
  padding: 15px;
  border: 1px solid #08c; }

.medcard .row {
  margin-bottom: 15px; }

.medcard__warning {
  color: red;
  font-style: italic; }

.medcard__my-history {
  text-transform: uppercase;
  font-weight: bold; }

.medcard__update {
  font-style: italic; }

.medcard__update--doc {
  font-size: 12px; }

.med-history__tab-wrap {
  padding: 0 15px;
  padding-top: 10px; }

.med-history__update--tab {
  margin-top: 10px;
  margin-bottom: 10px; }

.med-history__last {
  margin-bottom: 15px; }

.post-user-name {
  font-weight: bold;
  color: green; }

.post-text {
  font-size: 18px;
  color: #666666;
  line-height: 1.5em;
  font-family: Brandon, sans-serif; }
  .post-text img {
    clear: both;
    margin: 5px 0;
    max-width: 100%; }
  .post-text a {
    color: #336600; }
    .post-text a:focus {
      color: #336600;
      text-decoration: none; }
    .post-text a:hover {
      color: #336600;
      text-decoration: underline; }
  .post-text p {
    text-align: justify;
    margin: 0; }

.post-timestamp {
  margin-left: 10px;
  margin-right: 2px;
  font-family: Brandon, Verdana, Geneva, sans-serif;
  font-size: 13px;
  color: black; }

.post-link-bullet {
  position: relative;
  font-size: 13px;
  margin-left: 11px;
  cursor: pointer; }
  .post-link-bullet:before {
    position: absolute;
    left: -13px;
    content: "\00A0 \00B7 \00A0"; }

.socialButtonsContainer {
  margin-left: 20px; }

.post__title {
  margin-top: 0;
  margin-bottom: 2px;
  color: #666;
  font: 25px/25px Brandon, sans-serif, Arial, Verdana, Hevletica;
  font-weight: bold; }

.post-user-name-from {
  color: #969595;
  font-style: italic;
  font-size: 15px; }

/* Start - Post author info block */
.about_the_author {
  border: 1px solid #548235;
  padding: 15px 25px;
  margin: 15px 0;
  font-size: 16px;
  color: #666666;
  line-height: 1.5em;
  font-family: Brandon, sans-serif; }

.about_the_author .space {
  margin-bottom: 10px; }

.about_the_author .header {
  color: #7f7f7f;
  margin: 0 0 15px 0; }

.about_the_author .author_name {
  margin: 0 0 15px 0;
  font: 25px/25px Brandon, sans-serif, Arial, Verdana, Hevletica;
  font-weight: bold; }

.author_ava {
  max-width: 110px;
  padding-top: 110px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 500px;
  border: 1px solid #dadada;
  margin: auto; }

/* End - Post author info block */
.post-answer {
  position: relative;
  border-top: 2px solid green;
  border-bottom: 2px solid #8adc41;
  margin-bottom: 15px;
  background-color: #E9F5E9;
  max-width: 710px; }
  @media (min-width: 992px) {
    .post-answer {
      left: 20px; } }
  .post-answer:before, .post-answer:after {
    content: "";
    z-index: 100;
    position: absolute;
    top: 0;
    bottom: 55px;
    display: block;
    width: 2px;
    height: calc(100% + 2px);
    background: #24b300;
    background: -moz-linear-gradient(top, #24b300 0%, #8adc41 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, #24b300), color-stop(100%, #8adc41));
    background: -webkit-linear-gradient(top, #24b300 0%, #8adc41 100%);
    background: -o-linear-gradient(top, #24b300 0%, #8adc41 100%);
    background: -ms-linear-gradient(top, #24b300 0%, #8adc41 100%);
    background: linear-gradient(to bottom, #24b300 0%, #8adc41 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#24b300', endColorstr='#8adc41', GradientType=0 ); }
  .post-answer:before {
    left: 0; }
  .post-answer:after {
    right: 0; }
  @media (max-width: 767px) {
    .post-answer {
      z-index: 1; } }

.post-answer--yellow {
  background-color: #fbfdab; }

.post-answer--yellow .post-answer__decorate-pic {
  background-image: url("/assets/images/answers-arrow--yellow.png"); }

.post-answer__decorate-pic {
  z-index: 200;
  content: "";
  position: absolute;
  top: 45px;
  left: -9px;
  width: 11px;
  height: 34px;
  background-image: url("/assets/images/answers-arrow.png"); }

.post-answer__user-wrap {
  margin-bottom: -12px;
  display: flex;
  align-items: center; }

.post-answer__avatar-wrap {
  display: inline-block;
  padding-left: 4px;
  position: relative;
  top: -3px;
  margin-right: 10px; }

.post-answer__avatar-wrap img {
  width: 40px !important;
  height: 40px !important; }

.main-post .post-answer__avatar-wrap {
  display: inline-block;
  position: relative;
  top: auto;
  margin-right: 10px; }

.main-post .post-answer__avatar-wrap img {
  width: 40px !important;
  height: 40px !important;
  border-radius: 50%; }
  @media (max-width: 767px) {
    .main-post .post-answer__avatar-wrap img {
      width: 30px !important;
      height: 30px !important; } }

.post-answer__sub-answer .post-answer {
  left: 15px; }

.post-answer .post-timestamp {
  margin-left: 10px;
  margin-bottom: 3px;
  font-size: 13px;
  color: #555555; }

.post-answer__text {
  font-family: Brandon, sans-serif, Arial, Verdana, Helvetica;
  font-size: 16px;
  color: #666666;
  line-height: 20px;
  padding: 10px 25px;
  padding-bottom: 9px; }
  .post-answer__text a {
    word-break: break-all; }

.post-answer img {
  width: 45px; }

@media (max-width: 767px) {
  .post-answer__decorate-pic {
    display: none; } }

.post__user-wrap {
  margin-top: 10px; }

.main-post .post__user-wrap {
  margin-top: 10px;
  display: flex;
  align-items: center;
  margin-bottom: 10px; }

.post__author {
  color: #00BFA4;
  font-weight: bold;
  font-size: 15px; }
  .post__author:hover {
    color: #009b85; }

.post__author--anon {
  color: black;
  font-weight: 400; }
  .post__author--anon:hover {
    color: black; }

.post__avatar-wrap {
  position: relative;
  top: -2px;
  display: inline-block; }

.post__avatar-wrap img {
  width: 40px;
  height: 40px;
  margin-right: 6px; }

.main-post .post__avatar-wrap img {
  width: 40px;
  height: 40px;
  margin-right: 10px;
  border-radius: 50%; }
  @media (max-width: 767px) {
    .main-post .post__avatar-wrap img {
      width: 30px;
      height: 30px; } }

.main-post .med-history__view {
  background-color: #00BFA4;
  padding: 2px 5px;
  border: none;
  color: white;
  border-radius: 4px;
  font-weight: normal; }

.post__anon-img {
  width: 50px;
  height: 50px; }

.post__anon {
  margin-left: 6px;
  margin-right: 10px;
  line-height: 50px;
  font-size: 15px;
  height: 50px;
  font-weight: 400;
  color: black; }

.post__footer {
  margin-top: 9px; }

.author_info {
  line-height: 54px;
  color: #969595;
  font-style: italic;
  font-size: 15px; }

/*.post__wrap {
  padding-left: 10px;
}*/
.post__question-likes {
  position: relative;
  padding-left: 38px;
  margin-bottom: 10px;
  font-size: 13px;
  color: #969595; }
  .post__question-likes:after {
    content: "";
    position: absolute;
    top: -6;
    left: 20px;
    width: 14px;
    height: 17px;
    background: url(/assets/images/thumb_up.png) no-repeat; }

@media (max-width: 767px) {
  .post__user-wrap {
    text-align: left; }
  .post__avatar-wrap {
    display: block;
    width: 50px;
    float: left;
    top: auto; } }

@media (max-width: 767px) and (max-width: 767px) {
  .post__author {
    font-size: 12px; } }

@media (max-width: 767px) {
  .author_info {
    font-size: 13px; }
  .post-answer__user-wrap {
    margin-top: 14px;
    margin-bottom: 0; }
  .post-answer__sub-answer .post-answer {
    left: 0; }
  .post-answer__avatar-wrap {
    float: left;
    padding-left: 0;
    margin-right: 10px;
    margin-left: 4px; }
  .author_info {
    line-height: 1em; }
  .main-post .post__user-wrap {
    text-align: left; }
  .main-post .post__avatar-wrap {
    display: block;
    width: 50px;
    float: left;
    top: auto; }
  .main-post .post-answer__avatar-wrap {
    float: none;
    display: block;
    top: 0;
    margin-top: 5px; }
  .main-post .post__anon {
    font-size: 13px;
    margin-right: 0; }
  .main-post .med-history__view {
    margin-left: 0;
    margin-top: 10px;
    font-size: 13px; } }

@media (max-width: 767px) and (max-width: 767px) {
  .main-post .author_info {
    font-size: 12px; } }

.closed-post {
  margin-bottom: 20px;
  margin-top: 20px;
  display: inline-block;
  border: 2px solid orange;
  padding: 5px;
  background-color: #fff1d8;
  text-align: center; }

.post-text br {
  /*display: none;*/ }

.post-text p br {
  display: inline-block; }

.reply__wrap {
  margin-bottom: 32px; }

@media (min-width: 992px) {
  .reply__form-group {
    margin-top: 16px;
    margin-right: 40px;
    margin-left: -2px; } }

.reply__author-wrap {
  margin-top: 16px;
  margin-bottom: -6px; }
  @media (max-width: 767px) {
    .reply__author-wrap {
      margin: 5px 0; } }

.reply__author-link:hover {
  text-decoration: none; }

.reply__author-name {
  font-weight: bold;
  color: #00BFA4;
  position: relative;
  top: 1px; }

.reply__author-avatar {
  width: 30px;
  height: 30px;
  margin-left: -2px;
  margin-right: 6px; }

.reply__textarea {
  min-height: 139px;
  margin: 0;
  margin-bottom: 10px;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  border: 1px solid #B8C7D9;
  padding: 1px;
  font-family: Brandon, monospace;
  font-size: 15px;
  color: #333333; }
  .reply__textarea:focus {
    border: 1px solid #B8C7D9; }

.has-error .reply__textarea {
  border-color: #B8C7D9;
  margin-bottom: 0; }

.reply__form-group .help-block {
  text-align: left;
  margin-top: 1px;
  margin-bottom: -5px; }
  .reply__form-group .help-block strong {
    font-size: 12px;
    line-height: 1em;
    color: #ff0000; }

.reply__as-anon-text {
  margin-bottom: 10px;
  margin-left: 6px;
  font-size: 13px;
  color: #00BFA4; }

.reply__btn-wrap {
  margin-top: 14px; }
  @media (min-width: 992px) {
    .reply__btn-wrap {
      margin-right: 30px; } }

.reply__button {
  margin-bottom: 10px;
  padding: 7px 11px;
  border-radius: 3px; }

/*.reply__subscribe {
  color: #A37C07;
  font-size: 16px;
}*/
.icon-replied {
  display: inline-block;
  vertical-align: bottom;
  width: 20px;
  height: 20px;
  background-image: url(/assets/images/post_with_replies.png); }

.replies-info {
  font-family: Brandon, Verdana, Arial, sans-serif;
  font-size: 13px;
  color: #222222;
  border-top: 1px solid black;
  margin-top: 20px;
  padding-top: 15px;
  padding-left: 15px; }

.reply-modal {
  z-index: 990; }
  .reply-modal__content {
    padding: 3px;
    border: 1px solid #009b85;
    width: 558px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px; }
    @media (max-width: 767px) {
      .reply-modal__content {
        width: 100%; } }

.reply-saving {
  padding-right: 30px !important;
  background: url(/assets/images/loading.gif) #dddddd 95% 50% no-repeat;
  background-size: 20px; }

.post-answer--reply-answer {
  left: 10px;
  max-width: 721px;
  margin-top: 27px;
  padding: 10px; }

.reply-answer__user-wrap {
  margin-bottom: 10px; }

.reply-answer__avatar {
  width: 30px !important;
  height: 30px; }

.reply-answer__name {
  margin-left: 8px;
  color: #336600; }
  .reply-answer__name:focus {
    color: #336600;
    text-decoration: none; }
  .reply-answer__name:hover {
    color: #336600;
    text-decoration: underline; }

.reply-answer__form-group {
  margin-bottom: 10px; }

.reply-answer__anon-wrap {
  margin-left: 10px; }

.reply-answer__btn-wrap {
  margin-top: 17px; }

.reply-answer__btn {
  float: right;
  width: 94px;
  height: 36px; }

.reply-answer__btn--cancel {
  margin-right: 7px; }

.post-answer__dec-triangle {
  z-index: 200;
  position: absolute;
  content: "";
  display: block;
  width: 80px;
  height: 26px;
  top: -26px;
  left: 60px;
  background-image: url("/assets/images/post_triangle.png"); }

.b-table__wrap {
  padding: 3px 2px;
  border-color: #336600;
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  width: 100%; }
  @media (min-width: 992px) {
    .b-table__wrap {
      width: 102.15%; } }
  @media (max-width: 767px) {
    .b-table__wrap {
      overflow: scroll; } }
  .b-table__wrap a {
    color: #009b85;
    font-weight: bold; }
    .b-table__wrap a:focus {
      color: #009b85;
      text-decoration: none; }
    .b-table__wrap a:hover {
      color: #009b85;
      text-decoration: underline; }
  .b-table__wrap .nav-tabs {
    min-height: 34px;
    margin-bottom: 7px;
    padding-left: 1px;
    border: 1px solid #009b85;
    border-radius: 4px;
    background-color: #00BFA4; }
    .b-table__wrap .nav-tabs li {
      margin-bottom: -3px; }
      @media (max-width: 767px) {
        .b-table__wrap .nav-tabs li {
          width: 100%; } }
      .b-table__wrap .nav-tabs li a {
        margin-left: 2px;
        margin-top: 4px;
        padding: 5px 8px;
        color: #555555;
        border-bottom-color: #009b85;
        font-family: Brandon, Verdana, Arial, sans-serif;
        background: #f0f0f0;
        background: -moz-linear-gradient(top, #f0f0f0 0%, #fcfcfc 50%, #ededed 100%);
        background: -webkit-gradient(left top, left bottom, color-stop(0%, #f0f0f0), color-stop(50%, #fcfcfc), color-stop(100%, #ededed));
        background: -webkit-linear-gradient(top, #f0f0f0 0%, #fcfcfc 50%, #ededed 100%);
        background: -o-linear-gradient(top, #f0f0f0 0%, #fcfcfc 50%, #ededed 100%);
        background: -ms-linear-gradient(top, #f0f0f0 0%, #fcfcfc 50%, #ededed 100%);
        background: linear-gradient(to bottom, #f0f0f0 0%, #fcfcfc 50%, #ededed 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f0f0f0', endColorstr='#ededed', GradientType=0 );
        font-weight: 400; }
        .b-table__wrap .nav-tabs li a:hover {
          color: #212121;
          border-color: #212121;
          border-bottom-color: #009b85; }
    .b-table__wrap .nav-tabs li.active a {
      margin-top: 4px;
      padding: 6px 9px;
      padding-bottom: 4px;
      border-color: black;
      border-bottom-color: white;
      font-weight: bold;
      background: white;
      color: #212121;
      font-family: Brandon, Verdana, Arial, sans-serif; }
      .b-table__wrap .nav-tabs li.active a:hover {
        border-color: black;
        border-bottom-color: white; }

.b-table__wrap .tab-content {
  padding-left: 2px;
  padding-right: 3px; }

.b-table__wrap thead tr {
  background-color: #B8E6B8;
  border-bottom: 0; }

.b-table__wrap thead th {
  font-weight: normal;
  font-family: Brandon, Verdana, Arial, sans-serif;
  font-size: 16px;
  padding: 3px;
  color: #222222; }
  @media (max-width: 767px) {
    .b-table__wrap thead th {
      font-size: 13px; } }

.b-table__wrap tbody td {
  padding: 4px !important;
  font-size: 15px;
  border-top: none !important;
  border-bottom: none;
  line-height: 1.3em; }

.b-table td a {
  font-family: Brandon, Verdana, Arial, sans-serif;
  color: #009b85;
  font-weight: bold; }
  .b-table td a:focus {
    color: #009b85;
    text-decoration: none; }
  .b-table td a:hover {
    color: #009b85;
    text-decoration: underline; }

.b-table__date {
  font-family: Brandon, Verdana, Arial, sans-serif;
  color: #222222;
  font-size: 15px; }

.b-table__date span {
  color: #777777; }

.b-table__tbody tr:nth-child(even) {
  background-color: #E0FFE1; }

.b-table__avatar {
  width: 35px;
  height: 35px; }

.b-table__reply a {
  font-size: 14px;
  color: #009b85; }

.b-table__btn {
  margin-top: 15px; }

.test-block2 {
  position: absolute;
  top: 10px;
  height: 20px;
  display: flex;
  opacity: .3;
  width: 20px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px; }

.subscribe {
  text-align: center;
  background-color: #e9f5e9;
  margin-right: -15px;
  padding-top: 12px;
  padding-bottom: 9px; }
  @media (max-width: 767px) {
    .subscribe {
      margin-right: 0; } }

.subscribe__link {
  color: #A37C07;
  font-size: 18px;
  line-height: 1em; }
  .subscribe__link:hover, .subscribe__link:focus {
    color: #138A21;
    text-decoration: none; }

.subscribe__text {
  color: black;
  font-style: italic;
  margin-top: -2px; }

.connect {
  font-size: 14px;
  margin-top: 6px; }
  @media (min-width: 992px) {
    .connect {
      margin-right: -15px; } }

.connect__title {
  margin-left: 5px;
  margin-right: 6px; }

.connect .row:nth-child(even) {
  background-color: #f3f3f3; }
  @media (max-width: 767px) {
    .connect .row:nth-child(even) {
      background-color: white; } }

@media (max-width: 767px) {
  .connect .row .connect__wrap:nth-child(2) {
    background-color: #f3f3f3; } }

.connect__wrap {
  padding: 11px 8px; }

.connect__avatar {
  float: left;
  height: 40px;
  width: 40px; }

.connect__wrap-text {
  margin-left: 50px;
  line-height: 1em;
  padding-top: 2px; }

.connect__author {
  font-weight: bold;
  color: #009b85; }
  .connect__author:hover {
    color: #009b85; }

.connect__job {
  color: #808080;
  line-height: 1.5em; }

.connect__location {
  font-style: italic;
  color: #808080; }

.connect__connect {
  color: orange;
  line-height: 1.5em;
  font-style: italic; }
  .connect__connect:hover {
    color: orange; }

.connect__noactivity {
  margin-top: 19px;
  margin-left: 15px;
  color: black; }

.xdsoft_autocomplete_dropdown div {
  color: black !important;
  word-wrap: normal;
  max-width: 100%; }
  .xdsoft_autocomplete_dropdown div:nth-child(even) {
    background-color: #eee; }

.xdsoft_autocomplete_dropdown div.active {
  background-color: #009933 !important;
  color: white !important; }
  @media (max-width: 767px) {
    .xdsoft_autocomplete_dropdown div.active {
      background-color: #00BFA4 !important; } }

.contact-us__text-block {
  margin-top: -25px;
  margin-bottom: 30px; }
  .contact-us__text-block P {
    margin-bottom: 12px; }

@media (min-width: 992px) {
  .contact-us__col-label {
    display: inline-block;
    vertical-align: top;
    width: 39%;
    padding-left: 8px; } }

@media (min-width: 992px) {
  .contact-us__col-input {
    display: inline-block;
    vertical-align: top;
    width: 58%; } }

.contact-us__form-group {
  margin-bottom: 10px; }

.contact-us__form-group--last {
  margin-bottom: 23px; }

.contact-us__label {
  font-family: Brandon, Verdana, Geneva, sans-serif;
  font-size: 14px;
  color: #000;
  font-weight: 400;
  padding-top: 0 !important;
  /*@include laptop {
    margin-left: -7px;
  }*/ }

.contact-us__input {
  height: 21px;
  border-radius: 3px;
  padding: 2px 4px;
  border: 1px solid #B8C7D9;
  font-family: Brandon, Arial, sans-serif;
  font-size: 15px;
  color: #333333; }

.contact-us__input--textarea {
  height: 104px !important;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0; }

.contact-us__input--select {
  height: 21px !important; }

.contact-us__btns-wrap {
  margin-top: 23px; }

.contact-us__btn {
  font-size: 17px;
  font-family: Brandon, Arial, sans-serif;
  border-radius: 3px;
  width: 95px; }

.confirmation {
  border-top: 1px solid #cfe0ce;
  padding-left: 6px; }

.confirmation__title {
  margin-top: 12px;
  font-size: 20px; }

.confirmation__text {
  font-size: 17px;
  margin-bottom: 7px; }

.confirmation__link {
  font-size: 17px; }

.search-result a {
  color: #336600;
  font-weight: bold; }
  .search-result a:focus {
    color: #336600;
    text-decoration: none; }
  .search-result a:hover {
    color: #336600;
    text-decoration: underline; }

.search-result img {
  width: 50px;
  height: 50px; }

.search-result td {
  border: none !important; }

.search-result__counter {
  padding-left: 10px; }

.med-history__descr {
  font-size: 11px;
  color: red; }

.med-history__recommend-text {
  font-size: 12px; }

.med-history__label {
  font-size: 14px; }

.med-history__view {
  font-weight: bold;
  color: #eb6900;
  border-color: #eb6900;
  margin-left: 25px; }

.med-popover {
  padding: 15px;
  color: #666666; }
  .med-popover table td {
    vertical-align: top; }

.med-popover .row {
  margin-bottom: 10px; }

.med-popover b {
  color: #009b85; }

.med-popover-wrap .popover {
  width: 330px;
  max-width: 800px;
  border: 2px solid #009b85;
  background-color: #e9f5e9; }

.med-popover-wrap .arrow {
  border-bottom-color: #009b85 !important; }

.med-popover-wrap .popover.bottom > .arrow:after {
  border-bottom-color: #e9f5e9; }

.datepicker-dropdown {
  padding-top: 27px; }
  .datepicker-dropdown::after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    text-align: center;
    content: 'Please enter your Birthdate';
    text-decoration: underline;
    font-weight: 700;
    font-size: 14px;
    height: 20px;
    display: block; }

.socialButtonsContainer {
  margin-left: 10px; }
  .socialButtonsContainer .addthis_sharing_toolbox {
    max-height: 33px;
    position: relative;
    top: -8px; }
  .socialButtonsContainer p {
    margin: 0; }
  .socialButtonsContainer .at-share-tbx-element .at-share-btn {
    margin-bottom: 0; }

.ads_block p {
  margin: 0; }

#ad-123 {
  position: static; }
  #ad-123 p {
    height: 300px;
    overflow: auto; }

#ad-133 ins {
  max-width: 305px; }

@media (max-width: 767px) {
  #ad-141 ins {
    max-width: 345px !important; } }

@media (max-width: 767px) {
  .ads_top iframe {
    max-width: initial !important; } }

@media (max-width: 767px) {
  .ads_top span {
    display: block;
    overflow: hidden;
    max-width: 100%; } }

.profile-views .profile__title {
  font-size: 26px; }

.profile-views__item {
  border: 1px solid #cccccc;
  padding: 10px;
  width: 49.5%; }
  .profile-views__item:nth-child(2n-1) {
    margin-right: 5px; }
  .profile-views__item:nth-child(2n) {
    margin-left: 5px; }
  @media (max-width: 767px) {
    .profile-views__item {
      width: 100%;
      margin: 7px 0 !important; } }

.profile-views-item {
  font-size: 18px;
  margin-bottom: 10px; }
  @media (max-width: 767px) {
    .profile-views-item {
      font-size: 12px;
      display: flex;
      align-items: center; } }
  .profile-views-item__img-container img {
    border-radius: 50%;
    width: 100%;
    max-width: 175px;
    max-height: 175px; }
  @media (max-width: 767px) {
    .profile-views-item .profile-views-item-user {
      font-size: 16px; } }
  .profile-views-item .profile-views-item-user__name {
    display: inline-block;
    font-size: 22px;
    margin-bottom: 10px; }
    @media (max-width: 767px) {
      .profile-views-item .profile-views-item-user__name {
        font-size: 16px;
        margin-bottom: 5px; } }

.profile-icon {
  background-image: url("/assets/images/mobile-menu-icons/profile.png");
  background-size: contain;
  width: 20px;
  height: 15px;
  margin-right: 3px;
  display: inline-block;
  position: relative;
  top: 2px;
  background-repeat: no-repeat; }

.posts-icon {
  background-image: url("/assets/images/mobile-menu-icons/posts.png");
  background-size: contain;
  width: 20px;
  height: 15px;
  margin-right: 3px;
  display: inline-block;
  position: relative;
  top: 2px;
  background-repeat: no-repeat; }

.dark-eye-icon {
  background-image: url("/assets/images/mobile-menu-icons/eye.png");
  background-size: contain;
  width: 20px;
  height: 15px;
  margin-right: 3px;
  display: inline-block;
  position: relative;
  top: 2px;
  background-repeat: no-repeat; }

.m-arrow-down-icon {
  background-image: url("/assets/images/mobile-menu-icons/arrow-down.png");
  background-size: contain;
  width: 20px;
  height: 20px;
  margin-right: 0;
  display: inline-block;
  position: relative;
  background-repeat: no-repeat;
  float: right;
  top: 7px; }

.m-categories-icon {
  background-image: url("/assets/images/mobile-menu-icons/categories.png");
  background-size: contain;
  width: 20px;
  height: 15px;
  margin-right: 3px;
  display: inline-block;
  position: relative;
  top: 2px;
  background-repeat: no-repeat; }

.m-connections-icon {
  background-image: url("/assets/images/mobile-menu-icons/connections.png");
  background-size: contain;
  width: 20px;
  height: 15px;
  margin-right: 3px;
  display: inline-block;
  position: relative;
  top: 2px;
  background-repeat: no-repeat; }

.m-contact-us-icon {
  background-image: url("/assets/images/mobile-menu-icons/contact-us.png");
  background-size: contain;
  width: 20px;
  height: 15px;
  margin-right: 3px;
  display: inline-block;
  position: relative;
  top: 2px;
  background-repeat: no-repeat; }

.m-messages-icon {
  background-image: url("/assets/images/mobile-menu-icons/messages.png");
  background-size: contain;
  width: 20px;
  height: 15px;
  margin-right: 3px;
  display: inline-block;
  position: relative;
  top: 2px;
  background-repeat: no-repeat; }

.m-home-icon {
  background-image: url("/assets/images/mobile-menu-icons/home.png");
  background-size: contain;
  width: 15px;
  height: 15px;
  margin-right: 3px;
  display: inline-block;
  position: relative;
  top: 2px;
  background-repeat: no-repeat; }
