@import "vars.scss";
@import "mixin.scss";

.sing-up-modal__post-info {
  margin-left: 5px;
  margin-bottom: 11px;
  font-size: 16px;
  font-family: Brandon, Verdana, Arial, sans-serif;
  color: #222222;
}

.sing-up-modal__body {
  padding-top: 12px;
}

.sing-up-modal__btn-wrap {
  margin-bottom: 0;
}

.sing-up-modal__btn-next {

}

.sing-up-modal__input {

}

#loginCol {
  margin-left: -5px;
  margin-top: 1px;
  font-size: 16px;
  text-align: right;
  @include mobile {
    text-align: center;
    margin-top: 15px;
    margin-left: 0;
    font-size: 16px;
  }
}

//error style

.sing-up-modal__body .step1 .help-block {
  margin-left: 27px;
}

//steps style, because every step has own style
#loginFormContainer .step1 {
  .b-form__label {

  }

  .b-form__input {
    margin-left: 20px;
    max-width: 200px;
  }

  .form-group {

  }

  .form-group:last-child {

  }

  .next {
    margin-left: 20px;
    width: 94px;
    height: 36px;
  }

}

#loginFormContainer .step2 {
  .b-form__label {
    padding-top: 4px !important;
    padding-left: 35px;
  }

  .b-form__input {
    margin-left: 17px;
    width: 200px;
    margin-top: 1px;
  }

  .form-group {
    margin-bottom: 11px;
  }

  .form-group:last-child {
    margin-bottom: -1px;
  }

  .checkbox {
    padding-top: 0;
    margin-top: -5px;
    margin-left: 40px;
    margin-bottom: -3px;
  }

  .next {
    width: 94px;
    margin-left: -132px;
    height: 36px;
    border-radius: 3px;
  }

}

#loginFormContainer .step3 {
  .b-form__label {
    padding-top: 4px !important;
    padding-left: 35px;
  }

  .b-form__input {
    margin-left: 17px;
    width: 200px;
    margin-top: 1px;
  }

  .form-group {
    margin-bottom: 11px;
  }

  .form-group:last-child {
    text-align: center;
    margin-bottom: -1px;
  }

  .next {
    //width: 94px;
    margin-left: 6px;
    //height: 36px;
    border-radius: 3px;
  }

  .help-block strong {
    margin-left: 31px;
    width: 220px;
  }

}

#loginFormContainer .step4 {
  .b-form__label {
    padding-top: 4px !important;
    padding-left: 35px;
    padding-right: 7px; //because not enough place for required symbol
  }

  .b-form__input {
    margin-left: 17px;
    width: 200px;
    margin-top: 1px;
  }

  .form-group {
    margin-bottom: 11px;
  }

  .form-group:last-child {
    text-align: center;
    margin-bottom: -1px;
  }

  .next {
    //width: 94px;
    margin-left: 6px;
    //height: 36px;
    border-radius: 3px;
  }

  .help-block strong {
    margin-left: 31px;
    width: 220px;
  }

}