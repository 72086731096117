@import "vars.scss";
@import "mixin.scss";

.post-answer--reply-answer {
  left: 10px;
  max-width: 721px;
  margin-top: 27px;
  padding: 10px;
}

.reply-answer__user-wrap {
  margin-bottom: 10px;
}

.reply-answer__avatar {
  width: 30px !important;
  height: 30px;
}

.reply-answer__name {
  margin-left: 8px;
  @include link-green;
}

.reply-answer__form-group {
  margin-bottom: 10px;
}

.reply-answer__anon-wrap {
  margin-left: 10px;
}

.reply-answer__btn-wrap {
  margin-top: 17px;
}

.reply-answer__btn {
  float: right;
  width: 94px;
  height: 36px;
}

.reply-answer__btn--cancel {
  margin-right: 7px;
}

.post-answer__dec-triangle {
  z-index: 200;
  position: absolute;
  content: "";
  display: block;
  width: 80px;
  height: 26px;
  top: -26px;
  left: 60px;
  background-image: url("/assets/images/post_triangle.png");
}