@import "vars.scss";
@import "mixin.scss";

.myposts__title {
  margin-top: 6px;
  font-size: 26px;
  margin-left: 15px;
  margin-bottom: 26px;
}

.empty-table--myposts {
  margin-top: 36px;
  margin-bottom: 34px;
  font-size: 16px;
}

.myposts__table {
  width: 100%;
}