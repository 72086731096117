@import "vars.scss";
@import "mixin.scss";

.posted-m__content {
  //$width: 488px;
  //width: $width;
  //margin-left:-($width/2);

  z-index: 1002;
  position: fixed;
  left: 50%;
  top: 23vh;
  display: block;
  height: auto;
  width: 488px;
  margin-left: -239px;
  padding: 2px;
  overflow: hidden;
  outline: none;
  border: 1px solid #336600;
  border-radius: 4px;
  box-shadow: none;
  max-width: 100%;
  @include mobile {
    left: 240px;
    top: 10vh;
  }

  .modal-body {
    padding: 10px;
  }
}

.posted-m__title {
  margin-top: 0;
  font-weight: bold;
  letter-spacing: -1px;
  margin-bottom: 22px;
}

.posted-m__text {
  font-size: 16px;
}

.posted-m__img {
  margin-top: 13px;
  margin-bottom: 19px;
}

.posted-m__tips {
  max-width: 260px;
  margin: 0 auto;
  line-height: 1.3em;
  margin-bottom: 14px;
}