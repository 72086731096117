@import "vars";
@import "mixin";


#small-menu {
  display: block;
  text-align: right;
  margin-top: 16px;

  li {
    padding: 0 18px 0 7px;
    display: inline-block;
    a {
      color: $greenColor3;
      font-size: 14px;
      padding: 0;
      &:focus {
        background-color: inherit;
      }
      &:hover {
        background-color: inherit;
        text-decoration: underline;
      }
      &::after {
        content: '';
        width: 1px;
        position: absolute;
        top: 3px;
        right: -15px;
        height: 12px;
        background: $greenColor3;
      }
    }
    &:last-child {
      margin-right: 14px;
      a {
        &:after {
          width: 0;
        }
      }

    }
  }

  li.active {
    a {
      color: black;
    }
  }
}

.logined + #small-menu {
  margin-top: 24px;
}