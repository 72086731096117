@import "vars.scss";
@import "mixin.scss";

.profile-views {
  .profile__title {
    font-size: 26px;
  }

  &__item {
    border: 1px solid #cccccc;
    padding: 10px;
    width: 49.5%;

    &:nth-child(2n-1) {
      margin-right: 5px;
    }

    &:nth-child(2n) {
      margin-left: 5px;
    }

    @include mobile {
      width: 100%;
      margin: 7px 0 !important;
    }
  }
}

.profile-views-item {
  font-size: 18px;
  margin-bottom: 10px;

  @include mobile {
    font-size: 12px;
    display: flex;
    align-items: center;
  }

  &__img-container {
    img {
      border-radius: 50%;
      width: 100%;
      max-width: 175px;
      max-height: 175px;
    }
  }

  .profile-views-item-user {
    @include mobile {
      font-size: 16px;
    }

    &__name {
      display: inline-block;
      font-size: 22px;
      margin-bottom: 10px;

      @include mobile {
        font-size: 16px;
        margin-bottom: 5px;
      }
    }
  }
}