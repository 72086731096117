@import "vars.scss";
@import "mixin.scss";

////////////////// Layout //////////////////

.experts__container {
  padding-top: 0;
  @include  laptop { width: 980px; }
}

.experts__row {
  @include laptop { margin: 0; }
}

.experts__col-descr {
  @include laptop {
    float: left;
    width: 416px;
    padding: 0;
  }
}

.experts___col-reg {
  color: black;
  @include laptop {
    float: left;
    width: 534px;
    padding: 0;
  }
}

.experts__form-group { margin-bottom: 10px; }

.experts__panel-all {
  border: 2px solid #CFE0CE;
  border-top-width: 1px;
  -webkit-border-radius: 0;
     -moz-border-radius: 0;
          border-radius: 0;
}

.experts__panel-top {
  border-top: 1px solid #CFE0CE;
  border-bottom: none;
  border-left: none;
  border-right: none;
  -webkit-border-radius: 0;
     -moz-border-radius: 0;
          border-radius: 0;
  box-shadow: none;
}

.experts___panel-heading {
  color: #eb6900 !important;
  //font-family: Verdana, Geneva, sans-serif;
  @include mainFont;
  font-size: 20px;
  border: none;
  background-color: white !important;
  @include laptop {
    padding-top: 9px;
    padding-left: 11px;
    padding-bottom: 0;
    margin-bottom: 3px;
  }
}

.experts__panel-body {
  @include laptop { padding: 10px; }
}

////

/////////// Text block from admin-panel ///////////

.experts__text-block {
  color: black;
  p { line-height: 1.3em; }
}
 //Paragraf before subtitle
.expert__empty-space { margin-bottom: 12px; }

////

.experts__first-line {
  margin-top: -2px;
  margin-bottom: 14px;
}

.experts__second-line { margin-bottom: 14px; }

.experts__location {
  font-size: 12px;
  color: black;
  font-style: italic;
  font-weight: 400;
}


.experts__btn-upload {
  margin-top: 14px;
  margin-left: -10px;
  margin-bottom: 2px;
  padding: 5px 39px;
}

.experts__btn-submit {
  margin-top: 14px;
  margin-bottom: -14px; //rewrite (add class for form-group)
  padding: 9px 20px;
  border-radius: 3px;
  font-size: 14px;
}

.experts__btn-submit--margins {
  margin-top: 25px;
  margin-bottom: 15px;
}

.experts__field { max-width: 201px; }

.experts__textarea {
  width: 400px;
  height: 79px !important;
  padding: 0;
}

// Styles for text-block from admin panel for doctors, lawyers registration description

.experts__first-p {
  line-height: 1.7em !important;
  margin-bottom: 7px;
}

//error styles
.experts__panel-body .help-block {
  width: 260px;
  margin: 0 auto;
  margin-top: -4px;
}

/////////////// chosen-container //////////////////////////////////////

.experts__form-group .chosen-container { max-width: 260px !important; }

.experts__form-group .chosen-container a {
  -webkit-border-radius: 2px;
     -moz-border-radius: 2px;
          border-radius: 2px;
}

.experts__form-group .chosen-container span {
  color: #4d4d4d;
  font-weight: 400;
  text-align: left;
  padding-left: 5px;
}