@import "vars.scss";
@import "mixin.scss";

.modal__content {
  padding: 3px;
  overflow: hidden;
  border: 1px solid $textGreen;
  color: #222222;
  font-family: Brandon, Verdana, Arial, sans-serif;
  max-width: 589px;
  margin-top: -20px;

  @media (max-width: 767px) {
    margin-top: unset;
  }

  a {
    color: $textGreen;
    &:hover {
      color: $textGreen;
    }
  }
}

.login__content .modal-body {
  padding: 11px 19px 0;
}

.login__content {
  z-index: 1002;
  position: fixed;
  //top: 307px;
  left: 50%;
  top: 23vh;

  display: block;
  height: auto;
  width: 288px;
  margin-left: -144px;
  padding: 2px;

  overflow: hidden;
  outline: none;

  border: 1px solid $textGreen;
  border-radius: 4px;
  box-shadow: none;

  a {
    color: $textGreen;
    &:hover {
      color: $textGreen;
    }
  }

  .help-block {
    width: 100%;
    text-align: center;
    margin-top: 0;
    color: red;
    font-size: 12px;

    strong {
      font-weight: 400;
    }
  }

  .control-label {
    font-weight: normal;
  }
}

.login__content .form-group .control-label {
  font-size: 16px;
  color: #222222;
  font-family: Brandon, Verdana, Arial, sans-serif;
}

.modal-body--ask {
  padding-top: 24px;
}

.modal__title {
  color: white;
  font-size: 16px;
  font-weight: bold;
  @include mainFont;
}

.login__login-form {
  margin-top: 5px;
  margin-bottom: 3px;
}

.login__password-form {
  margin-bottom: 10px;
}

.modal__form-control--login {
  height: 25px;
  width: 177px;
  margin: 0 auto;
  font-size: 14px;
  padding: 0 5px;
}

.modal__header {
  padding: 5px 15px 5px 15px;
  font-size: 16px;
  color: white;
  border: 1px solid $greenColor3;
  border-radius: 5px;
  //@include modal-header-gradient;
  background-color: $greenColor3;
}

.modal__close {
  position: relative;
  right: -8px;
  top: 5px;
  padding: 0 1px !important;
  text-shadow: none;
  font-size: 18px;
  font-weight: bold;
  opacity: 1;
  border: 1px solid transparent;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;

  &:hover {
    background-color: lightgray;
    opacity: 1;
    border: 1px solid black;
  }
}

.sign-in-modal__new-user {
  font-size: 16px;
  color: #222222;
  font-family: Brandon, Verdana, Arial, sans-serif;
}

.post-text--label {
  font-size: 16px;
}

.post-text--green {
  color: green;
  font-size: 13px;
}

.modal__forgot {
  float: left;
  margin-top: 8px;
  margin-bottom: 3px;
  margin-left: 1px;
}

.login__content .has-error .form-control {
  border: 1px dotted #ff0000;
}