@import "vars.scss";
@import "mixin.scss";

.edit-profile {
  .form-group {
    margin-left: 0;
  }
  .control-label {
    font-weight: normal;
    font-size: 15px;
    text-align: left;
  }
}

.change-password {
  margin-top: 15px;
  margin-bottom: 15px;
  margin-left: 15px;
  font-size: 16px;
}

.saving {
  padding-right: 30px!important;
  background: url(/assets/images/loading.gif) #dddddd 95% 50% no-repeat;
  background-size: 20px;
}