@import "vars.scss";
@import "mixin.scss";

.subscribe {
  text-align: center;
  background-color: #e9f5e9;
  margin-right: -15px;
  padding-top: 12px;
  padding-bottom: 9px;
  @include mobile {
    margin-right: 0;
  }
}

.subscribe__link {
  color: #A37C07;
  font-size: 18px;
  line-height: 1em;
  &:hover,
  &:focus {
    color: #138A21;
    text-decoration: none;
  }
}

.subscribe__text {
  color: black;
  font-style: italic;
  margin-top: -2px;
}