@import "vars.scss";
@import "mixin.scss";

.forgot-password__title {
  color: #1A1A1A;
  font-size: 20px;
  margin-top: 7px;
  margin-bottom: 15px;
}

.forgot-password__label {
  margin-top: 6px;
  margin-bottom: 17px;
  padding: 0;
  font-weight: 400;
  font-size: 14px;
  //min-width: 100px;
  text-align: left;
}

.forgot-password__input {
  height: 21px;
  width: 178px;
  border-radius: 3px;
  padding: 2px 4px;
  color: #4D4D4D;
  font-size: 15px;
  border: 1px solid #B8C7D9;
  margin: 0;
}

//Don't change
.form-horizontal .forgot-password__mail-form {

}