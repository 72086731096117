@import "vars.scss";
@import "mixin.scss";

.float-left {
  float: left;
}

// Fix editor //

.mce-toolbar-grp .mce-flow-layout-item button > i.mce-i-image {
  padding-right: 5px !important;
}

.mce-toolbar-grp .mce-flow-layout-item button > i.mce-i-image:before {
  font-size: 20px;
}

// Ask block //

.ask {
  text-align: center;
  @include clearfix;
}

//add this wrap because textarea don`t support pseudo-element
#ask-your-question {
  position: relative;
  height: 62px;
  /*border: 1px solid #3F76CA;*/
  border: 1px solid #52c0a3;
  border-radius: 22px;
  font-family: Brandon, monospace;
  font-size: 17px;
  line-height: 3.5;
  color: #999999;
}

.triangle-bottom {
  position: absolute;
  width: 30px;
  height: 30px;
  display: block;
  left: calc(50% - 14px);
  top: 61px;
  /*#ask-your-question:after, #ask-your-question:before */
  &::after, &::before {
    position: absolute;
    content: " ";
    border: solid transparent;
    height: 0;
    width: 0;
    left: 0;
    pointer-events: none;
  }

  &::after {
    border-right-color: white;
    border-width: 0 28px 28px 0;
    margin-top: -1px;
  }

  &::before {
    /*border-right-color: #3F76CA;*/
    border-right-color: #52c0a3;
    border-width: 0 30px 30px 0;
    margin-left: -1px;
  }

  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    top: 60px;
  }

}

#ask-your-question {
  @media (max-width: 767px) {
    margin-top: 11px;
  }
}

#ask-your-question-modal .form-group #postTitle.b-form__input.form-warning {
  border: 2px solid rgba(255, 193, 7, 0.6);
}

#ask-your-question-modal .form-group #postTitle.b-form__input.form-danger {
  border: 2px solid rgba(220, 53, 69, 0.6);
}

#ask-your-question-modal .modal-dialog {
  @media (max-width: $mobile-max) {
    margin: 10px 2px;
  }
}

#postTitle-notification-content {
  font-size: 13px;
  margin-top: 15px;
}

form .post-as-anonymous {
  @media (max-width: $mobile-max) {
    font-size: 11px;
  }
  @media (max-width: 330px) {
    font-size: 9px;
  }
}

form #postsSubmit {
  @media (max-width: $mobile-max) {
    width: auto;
    padding: 3px 25px;
  }
  @media (max-width: 330px) {
    padding: 3px 12px;
  }
}

.modal-footer {
  padding-top: 3px;
  @media (max-width: $mobile-max) {
    padding-top: 3px;
  }
}

.form-group.mobile-select {
  @media (max-width: $mobile-max) {
    margin-bottom: 8px;
  }
}

.modal-body.modal-body--ask {
  padding-bottom: 3px;
  @media (max-width: $mobile-max) {
    padding-bottom: 3px;
  }
}