@import "vars.scss";
@import "mixin.scss";

.reply__wrap {
  margin-bottom: 32px;
}

.reply__form-group {
  @include laptop {
    margin-top: 16px;
    margin-right: 40px;
    margin-left: -2px;
  }

}

.reply__author-wrap {
  margin-top: 16px;
  margin-bottom: -6px;
  @include mobile {
    margin: 5px 0;
  }
}

.reply__author-link {
  &:hover {
    text-decoration: none;
  }
}

.reply__author-name {
  font-weight: bold;
  color: $greenColor3;
  position: relative;
  top: 1px;
}

.reply__author-avatar {
  width: 30px;
  height: 30px;
  margin-left: -2px;
  margin-right: 6px;
}

.reply__textarea {
  min-height: 139px;
  margin: 0;
  margin-bottom: 10px;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  border: 1px solid #B8C7D9;
  padding: 1px;
  font-family: Brandon, monospace;
  font-size: 15px;
  color: #333333;
  &:focus {
    border: 1px solid #B8C7D9;
  }
}

// Error style//


.has-error .reply__textarea { //rewrite bootstrap error styles
  border-color: #B8C7D9;
  margin-bottom: 0;
}

.reply__form-group .help-block {
  text-align: left;
  margin-top: 1px;
  margin-bottom: -5px;
  strong {
    font-size: 12px;
    line-height: 1em;
    color: #ff0000;
  }
}

////
.reply__as-anon-text {
  margin-bottom: 10px;
  margin-left: 6px;
  font-size: 13px;
  color: $greenColor3;
}

.reply__btn-wrap {
  margin-top: 14px;
  @include laptop {
    margin-right: 30px; //style forpixel perfect
  }
}

.reply__button {
  margin-bottom: 10px;
  padding: 7px 11px;
  border-radius: 3px;
}

/*.reply__subscribe {
  color: #A37C07;
  font-size: 16px;
}*/

.icon-replied {
  display: inline-block;
  vertical-align: bottom;
  width: 20px;
  height: 20px;
  background-image: url(/assets/images/post_with_replies.png);
}

.replies-info {
  font-family: Brandon, Verdana, Arial, sans-serif;
  font-size: 13px;
  color: #222222;
  border-top: 1px solid black;
  margin-top: 20px;
  padding-top: 15px;
  padding-left: 15px;
}

.reply-modal {
  z-index: 990;
  &__content {
    padding: 3px;
    border: 1px solid $textGreen;
    width: 558px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    @include mobile {
      width: 100%;
    }
  }
}

.reply-saving {
  padding-right: 30px!important;
  background: url(/assets/images/loading.gif) #dddddd 95% 50% no-repeat;
  background-size: 20px;

}