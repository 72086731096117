@import "vars.scss";
@import "mixin.scss";

.message-modal {
  //top: 331px;
  //display: block;
  border: 1px solid black;
  border-radius: 2px;
}

.message-modal__inner {
  background-color: #E9F5E9;
  border-radius: 2px;
  padding: 0;
}

.message-modal__img-wrap{
  float: left;
  padding: 10px;
}

.message-modal__user-info {
  border: 1px solid #B8C7D9;
  background-color: #CEF2CE;
  margin: 13px 10px 0 100px;
  padding: 10px;
  height: 55px;
}

.message-modal__author {
  color: #336600;
  font-weight: bold;
  font-size: 15px;
  &:hover {
    text-decoration: underline;
  }
}

.message-modal__date {
  font-size: 14px;
  color: #505050;
  text-align: right;
}

.message-modal__content {
  border: 1px solid #B8C7D9;
  padding: 10px;
  margin: 10px;
  clear: both;
}

.message-modal__textarea {
  margin: 0 10px;

  textarea {
    border: 1px solid #B8C7D9;
    border-radius: 0;
  }
}

.message-modal__cancel {
  border: 0;
  float: right;
  height: 36px;
  margin: 10px;
  width: 94px;
  cursor: pointer;
}