@import "vars.scss";
@import "mixin.scss";

.socialButtonsContainer {
  margin-left: 10px;

  .addthis_sharing_toolbox {
    max-height: 33px;
    position: relative;
    top: -8px;
  }

  p{
    margin: 0;
  }

  .at-share-tbx-element .at-share-btn{
    margin-bottom: 0;
  }
}

.ads_block{
  p{margin: 0}
}


#ad-123{
  position: static;

  p {
    height: 300px;
    overflow: auto;
  }
}

#ad-133{
  ins {
    max-width: 305px;
  }
}

#ad-141{
  ins {
    @include mobile {
      max-width: 345px !important;
    }
  }
}

.ads_top {
  iframe {
    @include mobile {
      max-width: initial !important;
    }
  }

  span {
    @include mobile {
      display: block;
      overflow: hidden;
      max-width: 100%;
    }
  }
}