@import "vars.scss";
@import "mixin.scss";

.sidebar {
  @media (min-width: 1200px) {
    width: 319px;
    float: right;
    padding-left: 0;
    padding-right: 14px;
  }
}

.subtitle {
  display: block;
  padding: 0 5px;
  color: $orangeColor;
  font-size: 19px;
  clear: both;
  margin: 0;
}

.btn--main {
  margin-right: 22px;
  margin-top: 2px;
  padding: 3px 15px;
  float: right;
  background-color: #eb6900;
  border-radius: 0;
  border-color: #eb6900;
  font-size: 14px;
  &:hover, &:focus, &:active, &:active:focus {
    text-decoration: underline;
    background-color: $orangeColor;
    border-color: $orangeColor;
    outline: none;
  }
}

.sidebar__people-title {
  color: #eb6900;
  font-size: 16px;
  margin-top: 14px;
  margin-left: 5px;
  margin-bottom: 10px;
}

.sidebar__choose-country {

}

.sidebar__people-connect {
  margin-top: 17px;
}

.sidebar__people-empty {
  margin-bottom: -7px;
}

#choosePossibleConnectionsCountry_chosen {
  left: 4px;
}

.ask + .find-friends {
  margin-top: 42px !important;
}

/* styles for tinyMce content*/
.mce-last > * {
  font-family: Brandon, Arial, sans-serif !important;
  font-weight: 400 !important;
  color: rgb(166, 166, 166) !important;
  font-size: 16px !important;
}

.mce-tinymce.mce-container.mce-panel {
  border: 0;
}

.mce-toolbar-grp .mce-flow-layout-item {
  margin-left: 0 !important;
  margin-right: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.mce-toolbar-grp .mce-flow-layout-item button {
  padding: 4px 5px !important;
}

.mce-toolbar-grp .mce-flow-layout-item button > i {
  padding: 0 !important;
}

.mce-toolbar-grp .mce-first button > span {
  display: none;
}

/*# styles for tinyMce content*/

/*posts modal styles*/
#ask-your-question-modal .form-group .b-form__input {
  border-radius: 0;
  border-color: #cacaca;
  padding: 0 4px;
}

#postTitle {
  padding: 20px 4px;
  border: 0;
  box-shadow: none;
  font-size: 17px !important;
}

#modal-post-title, #modal-post-desc {
  text-align: left;
  border: 1px solid #cacaca;
  padding: 10px;
  border-radius: 3px;
  box-shadow: 0 2px 1px rgba(0, 0, 0, .075);
}

#close-post-modal {
  font-size: 18px;
  font-weight: 400;
  line-height: 1.4;
  margin-top: auto;
}

#postTitle::-webkit-input-placeholder,
#postTitle::-ms-input-placeholder,
#postTitle::-moz-placeholder {
  color: rgb(136, 136, 136) !important;
}

#modal-post-title-label {
  font-size: 18px;
}

#autocomplete-content > p:first-child {
  margin-top: 12px;
  padding-top: 8px;
  border-top: 1px solid rgba(0, 0, 0, .075);
}

#autocomplete-content > p:last-child {
  padding-bottom: unset;
  margin-bottom: unset;
}

#autocomplete-content > p {
  width: 90%;
  overflow: hidden;
  white-space: nowrap;
  text-transform: capitalize;
  text-overflow: ellipsis;
  font-size: 13px;
}

/*# posts modal styles */

#postStory {
  padding: 0;
  color: #81bd92;
  font-weight: 600;
  font-size: 9px;
  text-decoration: underline;
  margin-left: 21px;
  border: 0 !important;
  outline: none !important;
  margin-top: 10px;
}

[placeholder]:focus::-webkit-input-placeholder {
  opacity: 0;
}

[placeholder]:focus::-moz-placeholder {
  opacity: 0;
}

[placeholder]:focus::-ms-input-placeholder {
  opacity: 0;
}
