@import "vars.scss";
@import "mixin.scss";


.sign-up__header-top {
  max-width: 950px;
  margin: 0 auto;

  #headerLogo {
    @include mobile {
      position: relative;
      top: 0;
      left: 0;
    }
  }
}

.sing-up__fb-like { margin-top: 14px; }



.sing-up__panel-heading {
  color: $orangeColor !important;
  @include mainFont;
  font-size: 20px;
  border: none;
  background-color: white !important;
  @include laptop {
    padding-top: 8px;
    padding-left: 9px;
    padding-bottom: 0;
    margin-bottom: -1px;
  }

  span { font-size: 12px; }
}

.sing-up__example {
  font-size: 12px;
  line-height: 1.25;
  margin-top: 2px;
  margin-bottom: 0;
  color: black;
}

.sing-up__login-body {
  padding-left: 9px;
  padding-right: 16px;
}

.sing-up__login-body {

  .help-block {
    display: block;
    max-width: 190px;
    margin: 0 auto;
    text-align: left;
    font-size: 12px;

    strong {
      font-weight: 400;
      color: red;
      margin-left: -14px;
    }
  }
  .has-error .control-label { color: black; }

  .has-error .form-control {
    border-color: red;
    border-style: dotted;
    margin-bottom: -1px;
  }

  label { margin-bottom: 0; }
}


.sing-up__control-label {
  @include mainFont;
  font-weight: normal;
  font-size: 14px;
  color: black;
}

.sign-up__label-gender { min-width: 200px; }

.registration-step {
  .help-block {
    display: block;
    max-width: 190px;
    margin: 0 auto;
    text-align: left;
    font-size: 12px;
    line-height: 1em;

    strong {
      display: block;
      margin-left: -8px;
      font-weight: 400;
      color: red;
    }
  }
  .has-error .form-control {
    border-color: red;
    border-style: dotted;
    margin-bottom: -1px;
  }

  label { margin-bottom: 0; }

  .has-error .control-label { color: black; }
}

.registration-step1 {
  .help-block {
    display: block;
    max-width: 190px;
    margin: 0 auto;
    text-align: left;
    font-size: 12px;

    strong {
      font-weight: 400;
      color: red;
      margin-left: 5px;
    }
  }
}


.sing-up__sing-in-block {
  @include laptop {
    padding: 0;
    margin-right: -15px;
    width: 247px;
    float: left;
    border-top: 1px solid #CFE0CE;
  }
}

.sing-up__sing-up-block {
  @include laptop {
    padding: 0;
    margin-left: -15px;
    width: 704px;
    float: right;
    border: 2px solid #CFE0CE;
    border-top: 1px solid #CFE0CE;
  }
}

.sing-up__container {
  max-width: 950px !important;
  padding-top: 0;
}

.sing-up__input-login {
  height: 27px;
  border-radius: 3px;
  padding: 5px;
  width: 232px;
  color: #4D4D4D;
  border: 1px solid #B8C7D9;
  font-family: Brandon, Arial, sans-serif;
  font-size: 15px;
  @include mobile { margin: 0 auto; }
}

.sing-up__login-btn {
  border-radius: 3px;
  padding: 3px 27px;
  color: #4D4D4D;
  font-size: 17px;
  border: 1px solid #B8C7D9;
  font-family: Brandon, Arial, sans-serif;
}

.sing-up__forgot {
  color: #336600;
  &:hover {
    color: #336600;
    text-decoration: underline;
  }
}

.sing-up__forgot-wrap { margin-top: 14px; }

.sing-up__login-form--email { margin-bottom: 16px; }

.sing-up__control-label--password { margin-bottom: 2px !important; }

.sing-up__panel {
  border-color: transparent;
  box-shadow: none;
}

/////////////////////////////////////////////////////// Register ///////////////////////////////////////////////////////

.register__body {
  padding-top: 12px;
  padding-left: 10px;
  padding-right: 10px;
  @include laptop { margin-bottom: 23px; }
}

.registration-step1 {
  //last form group before checkbox
  .form-group:nth-child(3) { margin-bottom: 10px; }
}

.registration__label {
  color: black;
  font-size: 14px;
  //font-family: Verdana, Geneva, sans-serif;
  @include mainFont;
  font-weight: 400;
  padding-top: 0;
}

.registration__input {
  margin-bottom: 1px;
  border-radius: 3px;
  padding: 2px 4px;
  height: 28px !important;
  width: 191px;
  border: 1px solid #B8C7D9;
  font-family: Brandon, Arial, sans-serif;
  font-size: 15px;
  font-weight: 400;
  color: #4d4d4d;
}

.registration__input--select {
  color: #99a2ac;
  padding-left: 0;
}

.register__checkbox1 {
  @include laptop{
    padding-top: 0;
    padding-left: 70px !important;
    font-size: 12px;
    color: #000;
  }
}



// first text block from admin panel //
.register__text-block1 {
  padding-left: 20px; //Style for pixel-perfect
  p {
    margin-bottom: 8px;

    span { color: black; }
  }
  /*p:nth-last-child(2) {
    margin-bottom: -2px;
  }*/
  p:last-child {
    padding: 0 45px;
    line-height: 1.3em;
    margin-bottom: 10px;
    margin-top: 9px;
  }
}
////

.register__join-btn {
  margin-left: 20px; //style for pixel-perfect
  width: 94px;
  height: 36px;
  background-color: #EB6900;
  text-align: center;
  color: white;
  font-size: 17px;
  text-transform: uppercase;
  border: 0;
  cursor: pointer;
  font-family: Brandon, Arial, sans-serif;
  -webkit-border-radius: 3px;
     -moz-border-radius: 3px;
          border-radius: 3px;
}

// second text block from admin panel //

.register-description {
  padding-left: 19px;
  padding-right: 18px;
  margin-bottom: 10px;
  padding-top: 2px;

  p {
    color: black;
    line-height: 1.3em;
    margin-bottom: 8px;
  }

  a {
    color: #336000;
    text-decoration: none;
    &:hover,
    &:focus,
    &:hover:focus  {
      text-decoration: underline;
      color: #336000;
    }
  }
}

.register-description td {
  @include mobile {
    display: block;
    width: 100% !important;
  }
}

////

.sing-up__panel--expert-select { margin-bottom: 2px; }

.register__save-btn {
  font-size: 17px;
  width: 156px;
  height: 36px;
  border-radius: 2px;
  font-family: Brandon, Arial, sans-serif;
}

/////////////////////////////////////////////////// Register step 2 ///////////////////////////////////////////////////

////////////////// Step 2 location group //////////////////

.register__location-group {
  padding-top: 2px;
  margin-bottom: 15px;
}

.registration__label--location {
  margin-top: -1px;
  margin-left: -2px;
  padding: 0 !important;
  text-align: left !important;
}

.registration__input--location {
  margin-left: -3px;
  width: 190px;
}

.registration__form--location { margin-bottom: 8px; }

.registration__detect {
  position: relative;
  left: 32px;
  top: 2px;
  font-size: 12px;
  color: black;
  font-style: italic;
  @include mobile {
    left: 0;
  }
}

//////////////////////////////////////////////////////////


.registration__form--step-2 { margin-bottom: 18px; }

.registration__label--step-2 {
  text-align: left !important;
  padding-top: 2px !important;
}

// Refactor needed (change layout and delete margins)

.registration__input--step-2 { margin-left: -17px; }

.help-block--step2 { margin-left: -17px; }

.register__save-btn--step-2 {
  margin-left: -83px;
  @include tablet {
    margin-left: 0;
  }

  @include mobile {
    margin-left: 0;
  }
}

.registration__form--step-2 .help-block strong { margin-left: -25px; }


////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

.register__text-block3 { margin-bottom: 6px; }

.registration__input--s3-country { //
  margin-left: 145px;
  top: 3px;
  position: relative;
  height: 28px !important;
  width: 180px;
  padding-left: 4px;
  @include tablet { margin-left: 15px; }
}

.form-group--country-select { margin-bottom: 17px; }

.registration__input--s3-phone-code {
  display: inline-block;
  margin-left: 27px;
  max-width: 55px;
  padding-left: 12px;
  @include tablet { margin-left: 15px; }
}

.registration__input--s3-phone-number { max-width: 140px; }

.form-group--phone {
  margin-bottom: 7px;
  label {
    vertical-align: bottom;
  }
}

.form-group--phone.has-error .help-block {
  @include laptop { margin-left: 153px; }
  @include tablet { margin-left: 23px; }
}

.register__s3-example {
  margin-bottom: 14px;
  @include laptop { margin-left: 130px; }

  span { font-size: 12px; }
}
.registering {
  padding-right: 30px!important;
  background: url(/assets/images/loading.gif) #dddddd 95% 50% no-repeat;
  background-size: 20px;
  color: black;
  text-shadow: none !important;
}