@import "vars.scss";
@import "mixin.scss";

.feed-wrap {
  background-color: transparent !important;
}

.feed-nav {
  cursor: pointer;
  font-size: 14px;
  color: black;
  &:hover {
    text-decoration: none;
    color: black;
  }
}

.feed-nav--doctor {
  padding-right: 0;
  @include mobile {
    padding-right: 15px;
  }
}