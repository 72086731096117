@import "vars.scss";
@import "mixin.scss";

.pagination {
  margin-top: 13px;
  margin-bottom: 5px;
}

.pagination > li > a,
.pagination > li > span {
  font-family: Brandon, Verdana, Arial, sans-serif;
  padding: 0 8px;
  padding-top: 1px;
  border: none;
  color: $textGreen;
  font-size: 16px;
  background-color: transparent;
  margin-right: 6px;
  &:hover {
    color: $textGreen;
    text-decoration: underline;
    background-color: transparent;
  }
}

.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover {
  text-decoration: none;
  background-color: $textGreen;
}

.pagination > .disabled > a,
.pagination > .disabled > a:focus,
.pagination > .disabled > a:hover,
.pagination > .disabled > span,
.pagination > .disabled > span:focus,
.pagination > .disabled > span:hover {
  background-color: transparent;
}

.pagination>li:first-child>a,
.pagination>li:first-child>span {
  margin-left: 10px;
  border-radius: 0;
}