@import "vars.scss";
@import "mixin.scss";

.btn--orange {
  background: #eb6900;
  color: #fff !important;
  border-radius: 0;
}

.btn-link--green {
  color: $greenColor3;
  &:hover {
    color: $greenColor3;
  }
}

.btn-link--width {
  padding: 10px;
}

.btn--green-gradient {
  //@include green-gradient;
  background-color: $greenColor3;
  border: 1px solid darken($greenColor3, 7%) !important;
  color: white;
  text-shadow: -1px -1px 0px hsl(0, 0, 25%);

  &:focus, &:active {
    outline: none;
    color: white;
  }
  &:hover {
    //@include green-gradient--hover;
    background-color: darken($greenColor3, 7%);
    border-color: darken($greenColor3, 7%);
    color: white;
  }
  &.disabled {
    @include grey-gradient;
    color: white;
  }
}

.btn--grey-gradient {
  text-shadow: -1px -1px 0px hsl(0, 0, 25%);
  @include grey-gradient;
  color: white;
}

.btn--cancel {
  margin-left: 40px;
}

.btn--mlogin {
  -webkit-border-radius: 2px;
     -moz-border-radius: 2px;
          border-radius: 2px;
  border: 0;
  height: 36px;
  width: 94px;
  cursor: pointer;
  padding: 0;
  font-size: 17px;
}

.btn--grey {
  padding: 4px 40px;
  color: #4D4D4D;
  border: 1px solid #B8C7D9;
  background-color: #dddddd;
  &:hover {
    color: #4D4D4D;
    text-decoration: none;
  }
}

.btn--wgr {
  padding: 6px 38px;
  -webkit-border-radius: 0;
     -moz-border-radius: 0;
          border-radius: 0;
  background-color: $greenColor3;
  border-color: $greenColor3;
  font-size: 14px;
  color: white;

  &:hover {
    background-color: #cc0000;
    border-color: #cc0000;
    color: white;
  }
}

//.btn--cancel {
//  width: 100px;
//  font-size: 16px;
//}

.btn--post {
  width: 130px;
  font-size: 18px;
}