@import "vars";
@import "mixin";

.header-profile-menu {
  float: right;
  text-align: right;
  @include mobile {
    position: absolute;
    right: 0;
    z-index: 100;
  }
}

//////////////////// Not logined menu ////////////////////

.notlogined {
  margin-top: 12px;
  margin-right: 10px;
  font-size: 0;
  @include mobile {
    margin-bottom: 23px;
    margin-top: 0;
    display: none;
  }
}

.notlogined li {  display: inline-block; }

.notlogined__link {
  display: block;
  padding: 5px 15px !important;
  font-size: 16px;
  font-family: Brandon, Arial, Helvetica, sans-serif;
  @include mobile {
    position: relative;
    display: block;
    top: 22px;
    left: 4px;

    font: 12px/34px Brandon, Arial, Helvetica, sans-serif;
    padding: 6px 3px !important;
    margin-left: 0;
    line-height: 1em;
  }

  &:hover { text-decoration: underline !important; }

  &:focus { outline: none; }
}

.notlogined__link--sing-in {
  color: #005801;
  background-color: white;
  &:hover,
  &:focus {
    color: #005801;
    background-color: white !important;
  }
}

.notlogined__link--sing-up {
  color: white;
  background-color: #eb6900;
  &:hover,
  &:focus {
    color: white;
    background-color: #eb6900 !important;

  }
}

////////////////////// logined menu //////////////////////

.logined { font-size: 0; }

.logined li {
  display: inline-block;
  min-height: 34px;
}

.logined__link {
  //min-height: 34px;
  font-family: Brandon, Arial, Helvetica, sans-serif;
  font-size: 16px;
  color: $greenColor3;
  background-color: #f3f3f3;
  &:hover {
    color: darken($greenColor3, 7%) !important;
    background-color: #f3f3f3 !important;
  }
  &:focus {
    color: darken($greenColor3, 7%);
    background-color: #f3f3f3 !important;
  }
}

.logined__link--user { padding: 3px 0 1px 16px !important; }

.logined__link--posts {
  padding: 7px 15px 7px 18px !important;
  margin-right: 2px;
}

////////////////////// Dropdown menu //////////////////////

.logined .dropdown-menu {
  top: 40px;
  min-width: 100px;
  padding: 0 10px;
  color: white;

  background-color: $greenColor3;

  $border: 1px solid #78736f;
  border: $border;
  border-radius: 0;
  z-index: 1000;

  li {
    width: 100%;
    padding-top: 7px;
    padding-bottom: 7px;
    border-bottom: 2px solid darken($greenColor3, 7%);

    // add decorative element for first li, because .dropdown-menu already has property position:absolute
    &:first-child {
      position: relative;
      &::before {
        content: "";
        z-index: 20;
        position: absolute;
        top: -6px;
        left: 9px;
        display: block;
        width: 10px;
        height: 10px;
        transform: rotate(-45deg);
        background-color: $greenColor3;
        border-top: $border;
        border-right: $border;
      }
    }

    a {
      width: 100%;
      display: block;
      padding: 5px 8px;
      font-size: 14px;
      line-height: 14px;
      color: white;
      font-family: Brandon, Arial, Helvetica, sans-serif;

      &:hover, &:focus {
        text-decoration: none;
        background-color: darken($greenColor3, 7%);
      }

    }

    &:last-child { border-bottom: 0; }
  }
}

////////////////////// Caret //////////////////////

.caret--rotate { transform: rotate(270deg); }

.caret { margin-left: 0; }

.logined__link:hover .caret { color: #5cbd21; }

// On mobile devices hide user (logined) menu, show user (logined) menu inside main-menu (dropdown)

#login-links.logined {
  @include mobile { display: none !important; }
}

.logined-mob__wrap {
  display: none !important;
  @include mobile { display: block !important; }
}

//////////////////////////////////////// User (logined) menu on mobile devices ////////////////////////////////////////
//Refactor

.logined-mob {
  background-color: #ccc;
  flex-direction: row !important;
  border: none !important;

  @include mobile {
    background-color: #eae9e9;
  }
}

.logined-mob > li {
  width: 50% !important;
  display: inline-block;
}

.logined-mob > li > a {
  font-family: Brandon, Arial, "Helvetica Neue", Helvetica, sans-serif !important;
  font-size: 14px !important;
  background-color: #ccc;
  &:focus,
  &:hover {
    background-color: #ccc !important;

    @include mobile {
      background-color: #eae9e9 !important;
    }
  }

  @include mobile {
    background-color: #eae9e9;
  }
}

.logined-mob .dropdown .dropdown-menu {
  display: none !important;
  background-color: $greenColor3;
  border: 1px solid #78736f !important;
  margin-top: -1px;
  position: absolute;
  left: 80px;
  top: 35px;
  z-index: 5450;
  padding: 10px;
  min-width: 100px;
  width: 122px !important;
  text-align: left;
  border-radius: 0;

  @include mobile {
    background-color: $greenColor3;
  }
}

.logined-mob .dropdown.open .dropdown-menu { display: block !important; }

.logined-mob .dropdown-menu > li  {
  &:first-child {
    position: relative;
    &::before {
      $border: 1px solid #78736f;
      content: "";
      z-index: 20;
      position: absolute;
      top: -16px;
      left: 2px;
      display: block;
      width: 10px;
      height: 10px;
      transform: rotate(-45deg);
      background-color: $greenColor3;
      border-top: $border;
      border-right: $border;

      @include mobile {
        background-color: $greenColor3
      }
    }
  }

  &:last-child a { border-bottom: none !important; }
}

.logined-mob .dropdown-menu > li > a {
  display: block;
  padding: 10px 3px !important;
  font-size: 14px !important;
  line-height: 14px !important;
  color: #FFFFFF !important;
  background: none !important;
  font-family: Brandon, Arial, "Helvetica Neue", Helvetica, sans-serif;
  border-bottom: 2px solid #02aa35 !important;

  @include mobile {
    border-bottom: 2px solid darken($greenColor3, 7%) !important;
  }
}









