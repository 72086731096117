@import "vars.scss";
@import "mixin.scss";

html { box-sizing: border-box; }

*,
*::before,
*::after { box-sizing: inherit; }

body {
  background: $whiteColor;
  font-size: 14px;
  //font-family: Brandon, Verdana, Geneva, sans-serif;
  @include mainFont;
  color: black;
}

a {
  color: $orangeColor;
  &:hover { color: $orangeColor; }
}

.container {
  padding-top: 136px;
  @media (min-width: 1200px) { width: 1080px; }

  @include mobile {
    padding-top: 55px;
  }
}

.main-content {
  @media (min-width: 1200px) {
    width: 735px;
    padding: 0 0 0 15px;
  }
}

@media (max-width: $mobile-max) {
  .text-xs-center { text-align: center !important; }

  .center-xs-block {
    margin: 0 auto !important;
    display: block
  }
}

@media (min-width: $tablet-min) and (max-width: $tablet-max) {
  .text-sm-center { text-align: center !important; }

  .center-sm-block {
    margin: 0 auto !important;
    display: block
  }
}

.selected {
  color: $whiteColor !important;
  background: #04be04 !important;
}

.margin-0 { margin: 0; }

.none { display: none; }

span.required { color: red; }

//class for JS-code (Show big image on hover)
.img-large-hidden {
  display: none;
  z-index: 1000;
}

.dashed-underline {
  border-bottom: 1px dashed #949469;
  cursor: default;
}

.link--green {
  color: $greenColor3;
  &:focus {
    color: $greenColor3;
    text-decoration: none;
  }
  &:hover {
    color: $greenColor3;
    text-decoration: underline;
  }
}

.bold { font-weight: bold; }

.help-block {
  clear: both;
  display: table;
}

.clearfix::after {
  display: table;
  clear: both;
  content: "";
}

#choosePossibleConnectionsCountry,
#chooseDoctorsCountry,
.chosen-container { max-width: 100% !important; }

#choosePossibleConnectionsCountry {
  @include mobile {
    display: block;
    width: 97%;
    height: 21px;
    margin-bottom: 8px;
    margin-left: 7px;
    padding-left: 1px;
  }
}

iframe { max-width: 100% !important; }

@media (min-width: $laptop-min) {
  .padding-l-md-0 { margin-left: 0; }

  .padding-r-md-0 { margin-right: 0; }
}

.rectangular_orange {
  padding: 10px;
  color: #555555;
  background: #fff9e5;
  border: 1px solid orange;

  a { @extend .link--green; }
}

.birthDateExample {
  padding-top: 5px;
  font-size: 16px;
}

label { position: relative; }

.error404__txt {
  font-size: 26px;
  line-height: 1.3em;
  a { @include link-green; }
}

label.required {
  &::before {
    content: "*";
    top: 1px;
    left: -11px;
    position: absolute;
    color: red;
  }
}

label.required--doc {
  &::before { left: -12px; }
}

.edit-title {
  padding: 0 15px 10px;
  color: #505050;
  font-weight: normal;
  font-size: 25px;
}

#professional .alert-success,
#personal .alert-success,
#account .alert-success,
#email-notifications .alert-success,
#medical-history .alert-success {
  margin: 15px;
  padding: 15px;
  text-align: center;
  background-color: transparent;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  color: green;
  border: 1px solid #00af25;
  font-size: 16px;
}

.empty-table {
  margin: 50px auto;
  font-family: Brandon, Verdana, Arial, sans-serif;
  font-size: 15px;
  letter-spacing: 1px;
  color: #222222;
}

.empty-table--light { color: #626258; }

.change-profile-picture {
  font-style: italic;
  font-size: 13px;
}

.chosen-container-single {
  width: 299px !important;
  padding: 0;
  margin: 0;
  top: -2px;
  .chosen-single {
    height: 21px;
    padding: 0;
    margin: 0;
    border: 1px solid #b8c7d9;
    background-color: #ffffff;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    box-shadow: none !important;
    span {
      margin: 0;
      background-color: #ffffff;
      font-size: 15px;
      color: #333333;
      font-family: Brandon, Arial, sans-serif;
      height: 21px;
      line-height: 20px;
      padding-left: 5px;
    }
  }
}

//rewrite symbol "\/" position
.chosen-container-single .chosen-single div b { background-position: 4px -1px; }

.modal { background-color: rgba(170, 170, 170, 0.3); }

.privacy-title {
  margin-top: 7px;
  margin-left: 4px;
  margin-bottom: 18px;
  font-size: 14px;
  color: #1a1a1a;
  font-weight: bold;
}

.privacy-descr {
  margin-top: -10px;
  p {
    line-height: 1.3em;
    margin-bottom: 15px;
  }

  a { color: #336600; }
}

.crop-picture__img {
  max-width: 450px;
  max-height: 350px;
}

.modal-backdrop.in { opacity: 0; }

.link_orange {
  color: #eb6900 !important;
  text-decoration: none !important;

  &:hover,
  &:focus {
    color: #eb6900 !important;
    text-decoration: underline !important;
  }
}

.link_red {
  color: #ff0000 !important;
  text-decoration: none !important;

  &:hover,
  &:focus {
    color: #ff0000 !important;
    text-decoration: underline !important;
  }
}

.abuse {
  font-family: Brandon, Ariana, Verdana, Helvetica, sans-serif;
  font-size: 18px;
  color: #ff0000;
  margin-bottom: 10px;
}

.abuse--answer {
  margin-left: 1px;
  color: #ff0000;
  margin-bottom: 8px;
}

.text-right { text-align: right; }

.modal-footer { border-top: 0; }

.no-border { border: none !important; }

// Must refactor!
.btn-fix-padding { padding: 6px !important; }

.ads-main-content {
  width: 730px;
  max-width: 100%;
}

.ads-sidebar {
  width: 320px;
  max-width: 100%;
}

.margin-bottom-20 { margin-bottom: 20px; }

.ads_block {
  border-bottom: 1px solid #808080;
  border-top: 1px solid #808080;
  margin: 15px 0;
  overflow: hidden;
}

.cancer-input {
  width: 60% !important;
  display: inline-block;
}

.margin-bottom-0 { margin-bottom: 0; }

//post ads
.post-ads {
  font-size: 16px;
  color: #666666;
  padding: 5px 0;
  font-family: Brandon, Arial, "Helvetica Neue", Helvetica, sans-serif;
  line-height: 1.1em;
}

.mce-ico.mce-i-fa {
  display: inline-block;
  font: normal normal normal 1em/1 FontAwesome;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-right: 0 !important;
  margin-right: -1px;
}

.mce-ico { font-size: 13px !important; }

.connect-send { margin-bottom: 10px; }

.hospitals-map {
  width: 220px;
  height: 100px;
}

.c-pointer { cursor: pointer; }

.login-page.has-error .help-block { margin-left: 75px; }

.declaine-message { font-weight: 400; }

.modal__header .modal-title { font-size: 16px; }

.decline {
  max-width: 100% !important;
  width: 400px;
  margin-left: 100px;
  margin-top: 170px!important;
  @include mobile { margin-left: 0; }
}

.margin-left-0 { margin-left: 0; }

ul.tagit li.tagit-new { width: 100%; }

.blue-line { border-color: #0073ea; }

#medicalSpecialization_chosen {
  width: 100% !important;

  .chosen-single {
    height: 30px;
    line-height: 30px;
    border-radius: 3px;

    span {
      height: 30px;
      padding: 5px 10px;
    }
  }
}

#medicalSpecialization_chosen .chosen-single div b { background-position: 2px 4px !important; }

#medicalSpecialization_chosen.chosen-with-drop .chosen-single div b { background-position: -17px 6px !important; }

.hospitals-wrapper {
  border: 2px solid lightblue;
  padding: 10px 0;
  border-radius: 10px;
  max-width: 100%;
  margin: 0 auto;
}

.hospital-node { color: #888888; }

#changeRegisterType {
  border: none;
  margin-bottom: 25px;
  color: black;
  font-size: 18px;
}

#expertRegisterForm #medicalSpecialization_chosen .chosen-single {
  width: 260px;
  height: 21px !important;
  border: 1px solid #B8C7D9;

  span { padding: 1px 4px !important; }

  div b { background-position: 4px 0 !important; }
}

#expertRegisterForm #medicalSpecialization_chosen.chosen-with-drop .chosen-single div b {
  background-position: -16px 0px !important;
}

//#content { overflow: hidden; }

/*.#questionAnswers {
  margin-bottom: 200px;
}*/

.hello__text {
  font-size: 20px;
  margin-top: 6px;
  margin-bottom: 12px;
}

.hello__btn {
  margin-bottom: 29px;
}

.blured-medical-info {
  width: 100%;
  height: 300px;
  background-size: cover;
  background: url('/assets/images/medical-blur-ready.jpg') no-repeat;
}

.posted-success {
  color: $textGreen;
}

a:focus {
  outline: none;
  color: $orangeColor;
}


.container {
  @include mobile {
    overflow-y: hidden;
  }
}

.g-recaptcha {
  margin-top: 10px;
}

.data-saving {
  padding-right: 30px !important;
  background: url(/assets/images/loading.gif) #dddddd 95% 50% no-repeat !important;
  background-size: 20px !important;
  color: $greyColor !important;
  text-shadow: none !important;

  &:hover {
    padding-right: 30px !important;
    background: url(/assets/images/loading.gif) #dddddd 95% 50% no-repeat !important;
    background-size: 20px !important;
    color: $greyColor !important;
    text-shadow: none !important;
  }
}

.mobile-only-show {
  display: none;

  @include mobile {
    display: block;
  }
}
.mobile-only-show-i {
  display: none !important;

  @include mobile {
    display: block !important;
  }
}

.mobile-hide {
  @include mobile {
    display: none;
  }
}

.mobile-hide-i {
  @include mobile {
    display: none !important;
  }
}

.main-site-container {
  @include mobile {
    position: absolute;
    top: 0;
    max-width: 100%;
  }
}

.viewed-me-badge {
  background-color: red;
  border-radius: 50%;
  margin: 0 0 0 3px;
  position: relative;
  top: -1px;

  @include mobile {
    padding: 3px 5px;
    float: right;
    top: 7px;
  }
}

.eye-icon {
  background-image: url('/assets/images/eye-icon.png');
  background-size: contain;
  width: 20px;
  height: 13px;
  margin-right: 3px;
  display: inline-block;
  position: relative;
  top: 2px;
  background-repeat: no-repeat;
}

.main-header {
  top: 0;
  position: fixed;
  width: 1052px;
  z-index: 301;
  background-color: white;

  @include mobile {
    width: 92%;
  }
}

.m-padding-left-40 {
  @include mobile {
    padding-left: 40px !important;
  }
}

.signup-recaptcha {
  margin-bottom: 10px;
}

.experts-signup-recaptcha {
  display: flex;
  justify-content: center;
}

.main-site-content {
  padding-top: 25px;

  @include mobile {
    padding-top: 35px;
  }
}

.padding-top-0 {
  padding-top: 0;
}