@import "vars.scss";
@import "mixin.scss";

.mymessages__title {
  margin-top: 16px;
  margin-bottom: 17px;
}

.mymessages__descr {
  margin-left: 5px;
  margin-right: 5px;
  line-height: 1.3em;
  margin-bottom: 20px;
}

.mymessages {

  .b-table__wrap {
    @include laptop {
      width: 100%;
    }
  }

  .empty-table {
    margin-top: 47px;
    margin-bottom: 45px;
    letter-spacing: 1px;
  }

}