@import "vars.scss";
@import "mixin.scss";

.medcard {
  padding: 15px;
  border: 1px solid #08c;
}

.medcard .row { margin-bottom: 15px; }

.medcard__warning {
  color: red;
  font-style: italic;
}

.medcard__my-history {
  text-transform: uppercase;
  font-weight: bold;
}

.medcard__update { font-style: italic; }

.medcard__update--doc { font-size: 12px; }

.medcard__title {}

.med-history__tab-wrap {
  padding: 0 15px;
  padding-top: 10px;
}

.med-history__update--tab {
  margin-top: 10px;
  margin-bottom: 10px;
}

.med-history__last { margin-bottom: 15px; }