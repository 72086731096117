@import "vars.scss";
@import "mixin.scss";

.find-friends {
  margin-bottom: 17px;
  @include clearfix;

  .xdsoft_autocomplete {
    width: 315px !important;
    max-width: 100%;

    .xdsoft_input {
      padding: 0;
      padding-left: 4px;
      height: 21px;
      font: 13.3333px Brandon, Arial !important;
      border: 1px solid #B8C7D9;
    }

    .xdsoft_autocomplete_dropdown { overflow: hidden; }
  }
}