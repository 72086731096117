@import "vars.scss";
@import "mixin.scss";

.recommendation__panel {
  position: relative;
  margin-top: 10px;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  border-color: #24B300;
  background-color: #E9F5E9;
  &::before {
    content: "";
    position: absolute;
    top: -5px;
    left: 20px;
    width: 11px;
    $height: 34px;
    height: $height;
    margin-top: -($height/2);
    background-image: url("/assets/images/answers-arrow.png");
    transform: rotate(90deg);
  }

  .recommendation__heading {
    background-color: transparent;
    border-bottom: 0;
    font-weight: bold;
  }

  .recommendation__body {
    padding-top: 0;
    color: #666666;
    font-size: 14px;
  }

  .recommendation__date {
    margin-top: 1em;
    font-size: 13px;
    color: black;
  }
  .recommendation__remove {
    position: absolute;
    top: 0;
    right: 0;
    width: 15px;
    text-align: center;
    background-color: #5cbd21;
    color: #E5F8DA;
    cursor: pointer;
  }
}