@import "vars";
@import "mixin";

#headerLogo {
  display: inline-block;
  vertical-align: bottom;
  margin-right: 2px;
  height: 50px;
  width: 50px;
  margin-bottom: 1px;
  text-decoration: none !important;
  background: $whiteColor url("/assets/images/buhayofw-logo.png") no-repeat;
  -webkit-background-size: cover;
          background-size: cover;
  @media (max-width: $mobile-max) {
    position: absolute;
    top: 7px;
    left: 55px;
    z-index: 1000;
    height: 35px;
    width: 100px;
    background: $greenColor3 url("/assets/images/buhayofw-logo-m.png") no-repeat;
    background-size: contain;
  }
}

.header-top{
  margin-bottom: 4px;
  @include mobile {
    background-color: $greenColor3;
    padding-top: 0;
    margin-bottom: 0;
    height: 55px;
  }
}

.mobile-header-profile-img {
  display: none;

  @include mobile {
    display: block;
    position: absolute;
    right: 20px;
    top: 10px;
  }

  img {
    @include mobile {
      width: 35px;
      border-radius: 50%;
    }
  }
}