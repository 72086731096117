@import "vars.scss";
@import "mixin.scss";

.category__head {
  margin-top: 10px;
  padding-bottom: 10px;
  @include clearfix();
}

.category__title {
  display: inline-block;
  font-size: 25px;
  color: $orangeColor;
  margin: 0;
  padding-left: 10px;
}

.category__follow {
  position: relative;
  top: 7px;
  right: 4px;
  float: right;
  color: $greenColor3;
  font-size: 16px;
  &:focus {
    color: inherit;
    text-decoration: none;
  }
  &:hover {
    color: darken($greenColor3, 7%);
    opacity: 0.9;
    text-decoration: none;
  }
}

.category__description {
  margin-right: -10px;
  font-size: 17px;
  margin-top: 6px;
  padding-left: 5px;
  padding-right: 5px;
  margin-bottom: 18px;
  color: black;
  @include mobile {
    margin-right: 0;
  }
}

.category__description p {
  line-height: normal;
  margin: 0;
}

.category__description img {
  margin-bottom: 5px;
}

.category__local-organizations {
  margin-top: -8px;
  margin-bottom: 26px;
}

.category__local-organizations p {
  line-height: 1.4em;
  margin-bottom: 1.3em;
}

.local-organizations .subcategories__item {
  padding-left: 10px;
}

/////////////// Home> Pera Payo> Negosyo/Business ///////////////

.category__business-investments p:first-child {
  margin-bottom: 3px;
}

////////////////////////////////////////////////////////////////


.category__blogs-filipino-literature {
  margin-bottom: 11px;
}

.category__blogs {
  margin-bottom: 28px;
}

.category__description a {
  color: $orangeColor;
  &:hover {
    color: $orangeColor;
  }
}

.category__description img {
  @include mobile {
    display: block !important;
    float: none    !important;
    margin: 0 auto !important;
  }
}

.category__link {
  font-size: 16px;
}

.doctors {
  margin-bottom: 26px;
}

.doctors__title {
  display: inline-block;
  margin-top: 11px;
  color: $greenColor3;
  font-size: 14px;
  padding-left: 5px;
  margin-right: 6px;
}

.doctors-noactivity {
  margin-top: 20px;
  margin-left: 15px;
  margin-bottom: 57px;
}