@import "vars.scss";
@import "mixin.scss";

.crop-picture__wrap {
  border: 2px solid #CFE0CE;
  padding: 10px;
  @include laptop {
    width: 950px;
    margin: 0 auto;
  }
}
///////////////////////////////// Layout /////////////////////////////////

.crop-picture__col-edit {
  @include laptop {
    float: left;
    width: 550px;
    max-width: 550px;
  }
}

.crop-picture__col-preview {
  @include laptop {
    float: left;
    //width: 144px;
    width: 250px;
    text-align: left;
  }
}

//////////////////////////////////////////////////////////////////////////

.crop-picture__title {
  margin: 0;
  //font-family: Verdana, Geneva, sans-serif;
  @include mainFont;
  font-size: 20px;
  color: #eb6900;
  font-weight: 400;
  line-height: 1.2em;
}

.crop-picture__title--image {
  margin-bottom: 15px;
}

.crop-picture__btn-wrap {
  margin-top: 15px;
}

.crop-picture__btn {
  display: inline-block;
  padding: 0;
  font-family: Brandon, Arial, sans-serif;
  color: #333333;
  font-size: 15px;
}

.crop-picture__preview {
  $size: 82px;
  width: $size;
  height: $size;
  overflow: hidden;
  border: 1px solid #000;
}