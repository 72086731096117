@import "vars.scss";
@import "mixin.scss";

//////////////////////////////////////////////////// Subcategories ////////////////////////////////////////////////////

.subcategories {
  margin-bottom: 6px;
  padding-top: 6px;
}

.local-organizations {
  margin-bottom: 47px;
}

.subcategories__item {
  margin-bottom: 8px;
  font-size: 15px;
  padding-left: 25px;

  &--doctors {
    margin: 4px 0;
  }
}

//class from back-end, unique from every page
.tanggapan-ng-konsulado {
  .subcategories {
    @include laptop {
      margin-bottom: 47px;
      padding-top: 14px;
      margin-left: -15px;
      margin-right: 73px;
    }
    .col-md-4 {
      padding-right: 0;
      padding-left: 0;
    }
  }

}

// Negosyo/Business //

.business-img {
  margin-bottom: -12px !important;
}

.business-investments {
  margin-top: -10px;
}

////

.blogs .subcategories {
  margin-left: -30px;

  .subcategories__item a {
    line-height: normal;
  }
}