@import "vars.scss";
@import "mixin.scss";

.experts-select {
  &__input {
    border-radius: 3px;
    padding: 2px 4px;
    width: 180px;
    color: #4D4D4D;
    border: 1px solid #B8C7D9;
    display: block;
    margin-top: -8px;
    height: 23px;
    margin-bottom: 8px;
  }
  &__btn {
    font-family: Brandon, Arial, sans-serif;
    font-size: 17px;
    color: #4d4d4d;
    border: 1px solid #B8C7D9;
    padding: 0 59px;
    border-radius: 3px;
    background-color: #DDDDDD;
  }
}
