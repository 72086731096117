@import "vars.scss";
@import "mixin.scss";

.b-form__label {
  font-weight: 400;
  font-family: Brandon, Verdana, Arial, sans-serif;
  padding-top: 0 !important;
  text-align: left !important;
  color: #222222;
}

.b-form__input {
  position: relative;
  border-radius: 3px;
  padding: 2px 4px;
  height: 27px !important;
  width: 260px;
  border: 1px solid #B8C7D9;

  font-family: Brandon, Arial, sans-serif;
  font-size: 15px;
  font-weight: 400;
  color: #4d4d4d;
  cursor: default;
}

.b-form__input--post-m {
  width: 100%;
  height: 27px !important;
}

.b-form__input--textarea {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  font-family: monospace;
  font-size: 15px;
  color: #333333;
}

.b-form__input--select {
  padding-left: 1px;
}

//Page styles

.b-form__input--expert {
  width: 260px;
}

.b-form__input--square {
  -webkit-border-radius: 0;
     -moz-border-radius: 0;
          border-radius: 0;
}

////

// Doctor registration styles
.b-form__input--expert-doc {
  display: inline-block;
  vertical-align: top;
  width: 238px;
  margin-left: -1px;
}

.b-form__doc-prefix {
  display: inline-block;
  vertical-align: top;
  padding-top: 2px; // vertical centered
  //font-family: Verdana, Geneva, sans-serif;
  @include mainFont;
  font-size: 16px;
  font-weight: 400;
  color: black;
}

////

.b-form__input--sing-up-modal {
  width: 200px;
  margin-left: 21px;
}

.b-form__group {
  margin-bottom: 10px;
}

.b-form--tags-in {
  max-width: 260px;
}

.experts__form-group .tagit {
  position: relative;
  border-radius: 3px;
  padding: 2px 4px;
  width: 260px;
  border: 1px solid #B8C7D9;

  font-family: Brandon, Arial, sans-serif;
  font-size: 15px;
  font-weight: 400;
  color: #4d4d4d;
  cursor: default;
}