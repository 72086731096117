@import "vars.scss";
@import "mixin.scss";

.abuse-m__label {
  margin-bottom: 0;
  line-height: 1em;
}

.abuse-m__form-group {
  margin-bottom: 1px;
}

.abuse-m__select {
  //font-family: Arial, sans-serif;
  //font-size: 13px;
  width: 251px;
  margin-left: -3px;
  padding-top: 1px;
  padding-left: 1px;
  color: #333333;
  -webkit-border-radius: 0;
     -moz-border-radius: 0;
          border-radius: 0;
}

.abuse-m__textarea {
  width: 251px;
  height: 103px !important;
  margin-left: -3px;
  -webkit-border-radius: 0;
     -moz-border-radius: 0;
          border-radius: 0;
}

.abuse-m__footer {
  border-top: 0;
  padding-top: 12px;
  padding-bottom: 20px;
}

.abuse-m__btn {
  height: 36px;
  width: 93px;
  border-radius: 3px;
  font-family: Brandon, Arial, sans-serif;
  font-size: 17px;
}

.abuse-m__btn--left {
  float: left;
  margin-left: 4px;
}

.abuse-m__btn--right {
  float: right;
  margin-right: 4px;
}