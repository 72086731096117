@import "vars.scss";
@import "mixin.scss";

.recommended-by {
  max-width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 300px;

  &__user-name {
    color: #336600;
    font-weight: bold;
  }
  &__user-job {
    font-weight: normal;
    font-style: italic;
    color: gray;
  }
  &__user-state {
    font-weight: normal;
    font-style: italic;
    color: gray;
  }
  &__connect  {
    a {
    color: orange;
    font-style: italic;
    font-weight: normal;
    cursor: pointer;
    }
  }
}

.profile__like {
  /* Popover */
  .popover {
    border: 5px solid #cfe0ce;
  }

  /* Popover Header */
  .popover-title {

  }

  /* Popover Body */
  .popover-content {

  }
  /* Popover Arrow */
  .arrow {
    border-top-color: blue !important;
  }
}