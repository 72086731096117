@import "vars.scss";

@mixin mainFont {
  font-family: Brandon, Verdana, Geneva, sans-serif;
}

@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

//add this mixin too late, code refactor needed

@mixin link-green {
  color: #336600;
  &:focus {
    color: #336600;
    text-decoration: none;
  }
  &:hover {
    color: #336600;
    text-decoration: underline;
  }
}

////////////////////////////////////////////////////// Gradients //////////////////////////////////////////////////////

@mixin table-gradient {
  background: rgba(0,229,0,1);
  background: -moz-linear-gradient(top, rgba(0,229,0,1) 0%, rgba(0,229,0,1) 25%, rgba(0,198,0,1) 50%, rgba(0,149,0,1) 75%, rgba(0,118,0,1) 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(0,229,0,1)), color-stop(25%, rgba(0,229,0,1)), color-stop(50%, rgba(0,198,0,1)), color-stop(75%, rgba(0,149,0,1)), color-stop(100%, rgba(0,118,0,1)));
  background: -webkit-linear-gradient(top, rgba(0,229,0,1) 0%, rgba(0,229,0,1) 25%, rgba(0,198,0,1) 50%, rgba(0,149,0,1) 75%, rgba(0,118,0,1) 100%);
  background: -o-linear-gradient(top, rgba(0,229,0,1) 0%, rgba(0,229,0,1) 25%, rgba(0,198,0,1) 50%, rgba(0,149,0,1) 75%, rgba(0,118,0,1) 100%);
  background: -ms-linear-gradient(top, rgba(0,229,0,1) 0%, rgba(0,229,0,1) 25%, rgba(0,198,0,1) 50%, rgba(0,149,0,1) 75%, rgba(0,118,0,1) 100%);
  background: linear-gradient(to bottom, rgba(0,229,0,1) 0%, rgba(0,229,0,1) 25%, rgba(0,198,0,1) 50%, rgba(0,149,0,1) 75%, rgba(0,118,0,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00e500', endColorstr='#007600', GradientType=0 );
}

@mixin menu-gradient {
  background: hsla(120, 100%, 45%, 1);
  background:    -moz-linear-gradient(top, hsla(120, 100%, 45%, 1) 1%, hsla(120, 100%, 45%, 1) 45%, hsla(120, 100%, 30%, 1) 100%);
  background:        -webkit-gradient(left top, left bottom, color-stop(1%, hsla(120, 100%, 45%, 1)), color-stop(45%, hsla(120, 100%, 45%, 1)), color-stop(100%, hsla(120, 100%, 30%, 1)));
  background: -webkit-linear-gradient(top, hsla(120, 100%, 45%, 1) 1%, hsla(120, 100%, 45%, 1) 45%, hsla(120, 100%, 30%, 1) 100%);
  background:      -o-linear-gradient(top, hsla(120, 100%, 45%, 1) 1%, hsla(120, 100%, 45%, 1) 45%, hsla(120, 100%, 30%, 1) 100%);
  background:     -ms-linear-gradient(top, hsla(120, 100%, 45%, 1) 1%, hsla(120, 100%, 45%, 1) 45%, hsla(120, 100%, 30%, 1) 100%);
  background:         linear-gradient(to bottom, hsla(120, 100%, 45%, 1) 1%, hsla(120, 100%, 45%, 1) 45%, hsla(120, 100%, 30%, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00e500', endColorstr='#009a00', GradientType=0);
}

@mixin green-gradient {
  background: rgba(152,232,177,1);
  background:    -moz-linear-gradient(top, rgba(152,232,177,1) 0%, rgba(0,149,75,1) 100%);
  background:        -webkit-gradient(left top, left bottom, color-stop(0%, rgba(152,232,177,1)), color-stop(100%, rgba(0,149,75,1)));
  background: -webkit-linear-gradient(top, rgba(152,232,177,1) 0%, rgba(0,149,75,1) 100%);
  background:      -o-linear-gradient(top, rgba(152,232,177,1) 0%, rgba(0,149,75,1) 100%);
  background:     -ms-linear-gradient(top, rgba(152,232,177,1) 0%, rgba(0,149,75,1) 100%);
  background:         linear-gradient(to bottom, rgba(152,232,177,1) 0%, rgba(0,149,75,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#98e8b1', endColorstr='#00954b', GradientType=0 );
}

@mixin green-gradient--hover {
  background: rgba(113, 186, 137, 1);
  background:    -moz-linear-gradient(top, rgba(113, 186, 137, 1) 0%, rgba(0, 145, 70, 1) 100%);
  background:        -webkit-gradient(left top, left bottom, color-stop(0%, rgba(113, 186, 137, 1)), color-stop(100%, rgba(0, 145, 70, 1)));
  background: -webkit-linear-gradient(top, rgba(113, 186, 137, 1) 0%, rgba(0, 145, 70, 1) 100%);
  background:      -o-linear-gradient(top, rgba(113, 186, 137, 1) 0%, rgba(0, 145, 70, 1) 100%);
  background:     -ms-linear-gradient(top, rgba(113, 186, 137, 1) 0%, rgba(0, 145, 70, 1) 100%);
  background:         linear-gradient(to bottom, rgba(113, 186, 137, 1) 0%, rgba(0, 145, 70, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#71ba89', endColorstr='#009146', GradientType=0);
}

@mixin grey-gradient {
  background: rgba(183, 183, 183, 1);
  background:    -moz-linear-gradient(top, rgba(183, 183, 183, 1) 0%, rgba(130, 130, 130, 1) 100%);
  background:        -webkit-gradient(left top, left bottom, color-stop(0%, rgba(183, 183, 183, 1)), color-stop(100%, rgba(130, 130, 130, 1)));
  background: -webkit-linear-gradient(top, rgba(183, 183, 183, 1) 0%, rgba(130, 130, 130, 1) 100%);
  background:      -o-linear-gradient(top, rgba(183, 183, 183, 1) 0%, rgba(130, 130, 130, 1) 100%);
  background:     -ms-linear-gradient(top, rgba(183, 183, 183, 1) 0%, rgba(130, 130, 130, 1) 100%);
  background:         linear-gradient(to bottom, rgba(183, 183, 183, 1) 0%, rgba(130, 130, 130, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#b7b7b7', endColorstr='#828282', GradientType=0);
}

@mixin tabs-gradient {
  background: rgba(0,229,0,1);
  background:    -moz-linear-gradient(top, rgba(0,229,0,1) 0%, rgba(0,133,0,1) 100%);
  background:        -webkit-gradient(left top, left bottom, color-stop(0%, rgba(0,229,0,1)), color-stop(100%, rgba(0,133,0,1)));
  background: -webkit-linear-gradient(top, rgba(0,229,0,1) 0%, rgba(0,133,0,1) 100%);
  background:      -o-linear-gradient(top, rgba(0,229,0,1) 0%, rgba(0,133,0,1) 100%);
  background:     -ms-linear-gradient(top, rgba(0,229,0,1) 0%, rgba(0,133,0,1) 100%);
  background:         linear-gradient(to bottom, rgba(0,229,0,1) 0%, rgba(0,133,0,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00e500', endColorstr='#008500', GradientType=0 );
}

@mixin tab-gradient {
  background: rgba(240,240,240,1);
  background:    -moz-linear-gradient(top, rgba(240,240,240,1) 0%, rgba(252,252,252,1) 50%, rgba(237,237,237,1) 100%);
  background:        -webkit-gradient(left top, left bottom, color-stop(0%, rgba(240,240,240,1)), color-stop(50%, rgba(252,252,252,1)), color-stop(100%, rgba(237,237,237,1)));
  background: -webkit-linear-gradient(top, rgba(240,240,240,1) 0%, rgba(252,252,252,1) 50%, rgba(237,237,237,1) 100%);
  background:      -o-linear-gradient(top, rgba(240,240,240,1) 0%, rgba(252,252,252,1) 50%, rgba(237,237,237,1) 100%);
  background:     -ms-linear-gradient(top, rgba(240,240,240,1) 0%, rgba(252,252,252,1) 50%, rgba(237,237,237,1) 100%);
  background:         linear-gradient(to bottom, rgba(240,240,240,1) 0%, rgba(252,252,252,1) 50%, rgba(237,237,237,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f0f0f0', endColorstr='#ededed', GradientType=0 );
}

@mixin modal-header-gradient {
  background: rgba(0, 229, 0, 1);
  background:    -moz-linear-gradient(top, rgba(0, 229, 0, 1) 0%, rgba(0, 133, 0, 1) 100%);
  background:        -webkit-gradient(left top, left bottom, color-stop(0%, rgba(0, 229, 0, 1)), color-stop(100%, rgba(0, 133, 0, 1)));
  background: -webkit-linear-gradient(top, rgba(0, 229, 0, 1) 0%, rgba(0, 133, 0, 1) 100%);
  background:      -o-linear-gradient(top, rgba(0, 229, 0, 1) 0%, rgba(0, 133, 0, 1) 100%);
  background:     -ms-linear-gradient(top, rgba(0, 229, 0, 1) 0%, rgba(0, 133, 0, 1) 100%);
  background:         linear-gradient(to bottom, rgba(0, 229, 0, 1) 0%, rgba(0, 133, 0, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00e500', endColorstr='#008500', GradientType=0);
}

@mixin border-gradient {
  background: rgba(36,179,0,1);
  background:    -moz-linear-gradient(top, rgba(36,179,0,1) 0%, rgba(138,220,65,1) 100%);
  background:        -webkit-gradient(left top, left bottom, color-stop(0%, rgba(36,179,0,1)), color-stop(100%, rgba(138,220,65,1)));
  background: -webkit-linear-gradient(top, rgba(36,179,0,1) 0%, rgba(138,220,65,1) 100%);
  background:      -o-linear-gradient(top, rgba(36,179,0,1) 0%, rgba(138,220,65,1) 100%);
  background:     -ms-linear-gradient(top, rgba(36,179,0,1) 0%, rgba(138,220,65,1) 100%);
  background:         linear-gradient(to bottom, rgba(36,179,0,1) 0%, rgba(138,220,65,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#24b300', endColorstr='#8adc41', GradientType=0 );
}
///////////////////////////////////////////////////// Mediaqueries /////////////////////////////////////////////////////

@mixin breakepoint-max ($width) {
  @media (max-width: $width) {
    @content;
  }
}

@mixin breakepoint-min ($width) {
  @media (min-width: $width) {
    @content;
  }
}

@mixin breakepoint-only ($min-width, $max-width) {
  @media (min-width: $min-width) and (max-width: $max-width){
    @content;
  }
}

@mixin mobile {
  @include breakepoint-max ($mobile-max) {
    @content;
  }
}

@mixin tablet {
  @include breakepoint-max ($tablet-max) {
    @content;
  }
}

@mixin laptop {
  @include breakepoint-min ($laptop-min) {
    @content;
  }
}


@mixin tablet-only {
  @include breakepoint-only($tablet-min, $tablet-max) {
    @content;
  }
}