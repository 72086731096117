//Project colors

$greenColor: #03AB03;
$greenColor2: #04BE04;
$greenColor3: #00BFA4;
$whiteColor: #fff;
$orangeColor: #eb6900;
$borderBlueColor: #3F76CA;
$thTrColor: #B8E6B8;
$tableBodyRowColor: #E0FFE1;
$textGreen: darken($greenColor3, 7%);


$greyColor: #777;
$greyColor1: #a0a0a0;
$greyColor2: #6d6d6d;
$greyColor3: #808080;
$greyColor4: #f3f3f3;
$greyColor5: #555555;
$greyColor6: #969595;
$greyDoctors: #545454;


$mobile-max: 767px;
$tablet-min: 768px;
$tablet-max: 991px;
$laptop-min: 992px;
$laptop-max: 1199px;
$desktop-min: 1200px;

