@import "vars";
@import "mixin";

.main-header__search-line {
  //overflow: hidden;
  display: inline-block;
  margin-top: 27px;
  width: calc(100% - 57px);
  margin-bottom: 1px;
  @include mobile {
    width: 103%;
    margin-left: -5px;
    margin-top: 60px;
  }

  .main-search-header-form {
    @include mobile {
      position: absolute;
      width: 100%;
      z-index: 9999;
      top: -15px;
      margin-left: -10px;
      font-size: 16px;
    }

    .search-submit {
      @include mobile {
        display: none;
      }
    }

    .search-close-mobile {
      @include mobile {
        position: absolute;
        z-index: 10;
        bottom: 20px;
        left: 15px;
        height: 20px;
        width: 20px;
        background: url('/assets/images/back-icon.png') no-repeat 50%;
        background-size: contain;
      }
    }

    .search-clear-mobile {
      @include mobile {
        position: absolute;
        z-index: 10;
        bottom: 20px;
        right: 15px;
        width: 20px;
        height: 20px;
        background: url('/assets/images/close-icon.png') no-repeat 50%;
        background-size: contain;
      }
    }
  }

  .xdsoft_autocomplete {
    display: inline-block;;
    position: relative;
    word-spacing: normal;
    text-transform: none;
    text-indent: 0;
    text-shadow: none;
    text-align: start;
    width: calc(100% - 172px) !important;
    float: left;
    @include tablet {
      width: calc(100% - 80px) !important;
    }

    @include mobile {
      width: 100% !important;
    }
  }

  .xdsoft_autocomplete_dropdown {
    overflow: hidden;
  }
}

input.search-input {
  @include laptop {
    max-width: 367px;
  }
  @include mobile {
    //max-width: scalc(100% - 84px);
    border: none;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
    padding: 50px 50px 31px 55px;
    width: 100%;

    &::placeholder {
      color: $greenColor3;
      font-size: 16px;
    }
  }
  display: inline-block;
  vertical-align: bottom;
  height: 47px;
  width: calc(100% - 4px);
  padding: 0 10px;
  border: 1px solid $borderBlueColor;
  box-shadow: none;

  &:focus {
    outline: none;
  }
}

input.search-input::-webkit-input-placeholder {
  color: #00BFA4;
  font-size: 16px;
}

.search-submit {
  display: inline-block;
  margin-left: 10px;
  vertical-align: bottom;
  height: 47px;
  width: 67px;
  background: $orangeColor url('https://www.google.com/uds/css/v2/search_box_icon.png') no-repeat 50%;
  border: 0;

  @include mobile {
    position: absolute;
    right: 10px;

    background: $greenColor3 url('https://www.google.com/uds/css/v2/search_box_icon.png') no-repeat 50%;
  }
}

.search-submit-new {
  display: none;
  border: 0;
  height: 15px;
  width: 15px;
  @include mobile {
    display: inline-block;
    position: absolute;
    right: 85px;
    top: 16px;
    height: 25px;
    width: 25px;
    background: $greenColor3 url('/assets/images/search-icon.png') no-repeat 50%;
    background-size: contain;
  }
}