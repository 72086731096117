@import "vars.scss";
@import "mixin.scss";

.myconnection__title {
  margin-top: 17px;
  margin-bottom: 15px;
}

.myconnection__descr {
  margin-left: 5px;
  margin-right: -10px;
  margin-bottom: 21px;
  p {
    margin-bottom: 0;
    text-align: justify;
    line-height: 1.3em;
  }
}