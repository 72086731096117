@import "vars";
@import "mixin";

#main-menu {
  min-height: inherit !important;
  box-shadow: none !important;
  border: 0 !important;
  @include laptop {
    margin-bottom: 0;
  }
  @include mobile {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    z-index: 1000;
    background-color: transparent;
    padding-top: 15px;
  }
}

#main-menu .navbar-nav {
  margin: 0;
}

#main-menu .navbar-header {
  @include mobile {
    position: absolute;
    left: 5px;
    top: 20px;
    //max-width: 50px;
  }
}

#main-menu .navbar-collapse {
  padding: 0;

  @include mobile {
    background-color: white;
    position: absolute;
    top: 55px;
    left: -15px;
    width: 85%;
    border: 1px solid lightgrey;
    height: 89vh;
    overflow-x: auto;
  }
}

#main-menu  ul:not(.dropdown-menu) {
  width: 100%;
  display: flex;
  justify-content: space-between;
  //background-color: $whiteColor;

  @include mobile {
    flex-direction: column;
    //border: 1px solid #3F76CA;
    border: none;
    height: 100%;
    display: block;
  }

  li {
    width: 100%;
    margin-right: 1px;

    &:last-child {
      margin-right: 0;
    }
    a, a:visited {
      display: block;
      width: 100%;
      padding: 0;
      margin: 0 1px 0 0;
      text-align: center;
      white-space: nowrap;
      color: $whiteColor;
      font-size: 18px;
      line-height: 32px;
      font-family: Brandon, Arial, Helvetica, sans-serif;
      text-decoration: none;
      box-shadow: none;
      //background: url("images/mainmenu.gif") repeat scroll 0 0 #04be04; // Old style for gradient
      //@include menu-gradient;
      background-color: $greenColor3;
      @include mobile {
        color: $greenColor3;
        background: none;
        border-bottom: 1px solid lightgrey;
        //background-color: $whiteColor;
        text-align: left;
        padding: 0 15px 0 20px;
      }
      &:hover {
        color: $greenColor3;
        background: $whiteColor;

        @include mobile {
          color: $greenColor3;
        }
      }

      &:focus,
      &:active {
        @include mobile {
          color: $whiteColor;
          background: $greenColor3;
        }
      }
    }
  }

  li.dropdown {
    &:focus,
    &:active {
      @include mobile {
        color: $greenColor3;
        background: $whiteColor;
      }
    }
  }

  li.active a {
    background: $greenColor2;
    @include mobile {
      color: $whiteColor;
      background: $greenColor3;
    }
  }
}

// Main-menu mobile toggle button//

// "open menu" state styles

#main-menu .navbar-toggle.collapsed {
  background-color: #03ab03;
  border-color: #03ab03;
  padding: 6px 8px;
  padding-bottom: 2px;
  -webkit-border-radius: 6px;
     -moz-border-radius: 6px;
          border-radius: 6px;

  @include mobile {
    background: none;
    border: none;
    padding: 0;
    border-radius: 0;
    margin: 0;
  }

  .icon-bar {
    display: block;
    width: 25px;
    height: 3px;
    background-color: #fff;
    -webkit-border-radius: 1px;
       -moz-border-radius: 1px;
            border-radius: 1px;

    &:nth-child(3) {
      width: 20px;
    }
  }

  .menu-text {
    display: block;
    margin-top: 3px;
    color: white;
    font-weight: bold;
    font-size: 12px;
    font-family: Brandon, Arial, sans-serif;
  }

  .icon-bar + .icon-bar {
    margin-top: 4px;
  }

  .icon-close {
    display: none;
  }
}

//"close menu" state styles

#main-menu .navbar-toggle {
  background-color: #03ab03;
  border-color: #03ab03;
  padding: 0;
  -webkit-border-radius: 6px;
     -moz-border-radius: 6px;
          border-radius: 6px;
  position: relative;

  .icon-bar {
    display: none;
  }

  @include mobile {
    background: none;
    border: none;
    padding: 0;
    border-radius: 0;
    margin: 0;
  }

  .icon-close {
    position: absolute;
    top: -3px;
    display: block;
    color: #fff;
    width: 23px;
    height: 23px;
    font-size: 25px;
    text-align: center;
    margin: 0 auto;
    font-weight: bold;
    line-height: 1.1;
  }
}

.navbar-nav .open .mobile-menu-dropdown {
  background-color: #f1efef;
  padding: 0;
}
