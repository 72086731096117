@import "vars.scss";
@import "mixin.scss";

//Footer

#footer {
  text-align: center;
  .terms {
    color: #919191;
    margin-top: 10px;
    font-size: 12px;
    margin-bottom: 20px;
    a, a:visited {
      color: $greenColor3;
      text-decoration: none;
    }
  }
  .fb-like-box {
    margin-top: 20px;
    margin-bottom: 10px;
  }
}