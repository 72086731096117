@import "vars.scss";
@import "mixin.scss";

.edit-picture__wrap {
  border: 2px solid #CFE0CE;
  padding: 10px;
  max-width: 950px;
  margin: 0 auto;
}

.edit-picture__set {
  font-size: 18px;
  font-weight: bold;
  margin-top: -2px;
  margin-bottom: 13px;
}

.edit-picture__img-wrap {
  margin: 0 auto;
  width: 50px;
}

.edit-picture__img {
  display: block;
  margin: 0 auto;
  width: 50px;
  height: 50px;
  background-color: red;
  margin-bottom: 18px;
  margin-left: 10px;
}

.edit-picture__upload-btn {
  padding: 5px 41px;
  margin-left: 15px;
}

.edit-picture__txt {
  margin-top: 52px;
  margin-bottom: 22px;
  font-size: 25px;
  color: #eb6900;
  margin-left: 26px;
  line-height: 1.3em;
}

.edit-picture__warning {
  margin-bottom: 60px;
  margin-left: -10px;
  color: black;
}

.edit-picture__upload-later {
  padding: 2px 50px;
  color: #4D4D4D;
  border: 1px solid #B8C7D9;
  background-color: #dddddd;
  border-radius: 4px;
  font-family: Brandon, Arial, sans-serif;
  font-size: 17px;
}