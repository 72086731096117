@import "vars.scss";
@import "mixin.scss";

.c-messages__col-label {
  display: inline-block;
  vertical-align: top;
  @include laptop {
    width: 113px;
  }
  @include tablet {
    width: 100%;
  }
}

.c-messages__col-input {
  display: inline-block;
  vertical-align: top;
  @include laptop {
    width: 412px;
  }
  @include tablet {
    width: 100%;
  }
}

.c-messages__form {
  margin-top: 20px;
}

.c-messages__form-group {
  margin: 0 !important;
}

.c-messages__label {
  padding-top: 0 !important;
  padding-left: 18px;
  font-weight: 400;
  font-family: Brandon, Verdana, Arial, sans-serif;
  font-size: 14px;
  color: #222222;
}

.c-messages__help {
  margin-left: 127px;
  margin-top: 9px;
  margin-bottom: 26px;
  padding: 0;
  font-weight: 400;
  font-family: Brandon, Verdana, Arial, sans-serif;
  font-size: 15px;
  color: #000000;
}


.c-messages__input {
  width: 100%;
  height: 27px;
  font-family: Brandon, monospace;
  font-size: 15px;
  color: #333333;
  -webkit-border-radius: 3px;
     -moz-border-radius: 3px;
          border-radius: 3px;
  border: 1px solid #B8C7D9;
}

.c-messages__form-group--textarea {
  margin-top: 17px !important;
}

.c-messages__input--textarea {
  height: 162px !important;
  -webkit-border-radius: 0;
     -moz-border-radius: 0;
          border-radius: 0;
}

.c-messages__btns-wrap {
  margin-top: 33px;
  margin-bottom: 19px;
}

.c-messages__btn {

  font-size: 18px;
  width: 94px;
  border-radius: 3px;
}

.c-messages__btn--submit {
  margin-left: 52px;
}

.c-messages__btn--reset {
  float: right;
}

