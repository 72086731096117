@import "vars.scss";
@import "mixin.scss";

.xdsoft_autocomplete_dropdown {
  div {
    color: black !important;
    word-wrap: normal;
    max-width: 100%;

    &:nth-child(even) {
      background-color: #eee;
    }
  }
  div.active {
    //reset autocomplete style
    background-color: #009933 !important;
    color: white !important;

    @include mobile {
      background-color: $greenColor3 !important;
    }
  }
}