@import "vars.scss";
@import "mixin.scss";

.profile-icon {
  background-image: url('/assets/images/mobile-menu-icons/profile.png');
  background-size: contain;
  width: 20px;
  height: 15px;
  margin-right: 3px;
  display: inline-block;
  position: relative;
  top: 2px;
  background-repeat: no-repeat;
}
.posts-icon {
  background-image: url('/assets/images/mobile-menu-icons/posts.png');
  background-size: contain;
  width: 20px;
  height: 15px;
  margin-right: 3px;
  display: inline-block;
  position: relative;
  top: 2px;
  background-repeat: no-repeat;
}
.dark-eye-icon {
  background-image: url('/assets/images/mobile-menu-icons/eye.png');
  background-size: contain;
  width: 20px;
  height: 15px;
  margin-right: 3px;
  display: inline-block;
  position: relative;
  top: 2px;
  background-repeat: no-repeat;
}
.m-arrow-down-icon {
  background-image: url('/assets/images/mobile-menu-icons/arrow-down.png');
  background-size: contain;
  width: 20px;
  height: 20px;
  margin-right: 0;
  display: inline-block;
  position: relative;
  background-repeat: no-repeat;
  float: right;
  top: 7px;
}
.m-categories-icon {
  background-image: url('/assets/images/mobile-menu-icons/categories.png');
  background-size: contain;
  width: 20px;
  height: 15px;
  margin-right: 3px;
  display: inline-block;
  position: relative;
  top: 2px;
  background-repeat: no-repeat;
}
.m-connections-icon {
  background-image: url('/assets/images/mobile-menu-icons/connections.png');
  background-size: contain;
  width: 20px;
  height: 15px;
  margin-right: 3px;
  display: inline-block;
  position: relative;
  top: 2px;
  background-repeat: no-repeat;
}
.m-contact-us-icon {
  background-image: url('/assets/images/mobile-menu-icons/contact-us.png');
  background-size: contain;
  width: 20px;
  height: 15px;
  margin-right: 3px;
  display: inline-block;
  position: relative;
  top: 2px;
  background-repeat: no-repeat;
}

.m-messages-icon {
  background-image: url('/assets/images/mobile-menu-icons/messages.png');
  background-size: contain;
  width: 20px;
  height: 15px;
  margin-right: 3px;
  display: inline-block;
  position: relative;
  top: 2px;
  background-repeat: no-repeat;
}

.m-home-icon {
  background-image: url('/assets/images/mobile-menu-icons/home.png');
  background-size: contain;
  width: 15px;
  height: 15px;
  margin-right: 3px;
  display: inline-block;
  position: relative;
  top: 2px;
  background-repeat: no-repeat;
}