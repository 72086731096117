@import "vars.scss";
@import "mixin.scss";

// Block from admin panel //

.contact-us__text-block {
  margin-top: -25px;
  margin-bottom: 30px;
  P {
    margin-bottom: 12px;
  }
}

////


// Custom layout, because bootstrap layout, do not fix problem with pixel perfect //

.contact-us__col-label {
  @include laptop {
    display: inline-block;
    vertical-align: top;
    width: 39%;
    padding-left: 8px;
  }
}

.contact-us__col-input {
  @include laptop {
    display: inline-block;
    vertical-align: top;
    width: 58%;
  }
}

////


.contact-us__form-group {
  margin-bottom: 10px;
}

.contact-us__form-group--last {
  margin-bottom: 23px;
}

.contact-us__label {
  //font-family: Verdana, Geneva, sans-serif;
  @include mainFont;
  font-size: 14px;
  color: #000;
  font-weight: 400;
  padding-top: 0 !important;

  /*@include laptop {
    margin-left: -7px;
  }*/
}

.contact-us__input {
  height: 21px ;
  //width: 305px;
  border-radius: 3px;
  padding: 2px 4px;
  border: 1px solid #B8C7D9;

  font-family: Brandon, Arial, sans-serif;
  font-size: 15px;
  color: #333333;
}

.contact-us__input--textarea {
  height: 104px !important;
  -webkit-border-radius: 0;
     -moz-border-radius: 0;
          border-radius: 0;
}

.contact-us__input--select {
  height: 21px !important;
}

.contact-us__btns-wrap {
  margin-top: 23px;
}

.contact-us__btn {
  font-size: 17px;
  font-family: Brandon, Arial, sans-serif;
  border-radius: 3px;
  width: 95px;
}