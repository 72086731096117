@import "vars.scss";
@import "mixin.scss";

.search-result {

  a {
    @include link-green;
    font-weight: bold;
  }

  img {
    width: 50px;
    height: 50px;
  }

  td {
    border: none !important;
  }

}

.search-result__counter {
  padding-left: 10px;
}