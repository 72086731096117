@import "vars.scss";
@import "mixin.scss";

.help-block {
  display: block;
  text-align: left;
  font-size: 12px;
  line-height: 1em;
  margin: 0;

  strong {
    display: block;
    font-weight: 400;
    color: red;
    font-size: 12px;
    line-height: 1em;
  }
}

.has-error .form-control {
  border: 1px dotted red;
  box-shadow: none;
  &:focus {
    box-shadow: none !important;
    outline: -webkit-focus-ring-color auto 5px;
  }
}

.has-error .control-label {
  color: currentColor;
}