@import "vars.scss";
@import "mixin.scss";

.profile a {
  color: $textGreen;
  &:focus {
    color: $textGreen;
    text-decoration: none;
  }
  &:hover {
    color: $textGreen;
    text-decoration: underline;
  }
}

/*.profile-avatar > a > img {
  display: block;
  $size: 182px !important;
  width: $size;
  height: $size;
  margin: 0 auto 10px;
  border-color: #CFE0CE;
}*/

.profile-avatar {
  .img-thumbnail {
    border-radius: 50%;
  }
}

.profile__likes-wrap {
  margin-bottom: 10px;
  line-height: 1.1em;
}

.profile__like-label {
  font-size: 13px;
  text-decoration: underline;
  color: $orangeColor;
}

.profile__like-username {
  font-size: 13px;
  color: $orangeColor;
}

.profile__like {
  position: relative;
  //opacity: 0.7;
  margin-top: 10px;
  font-size: 13px;
  color: $greyColor6;
  &::after {
    content: "";
    position: absolute;
    top: -4px;
    left: 45px;
    width: 14px;
    height: 17px;
    background: url("/assets/images/thumb_up.png") no-repeat;
  }
}

.profile__textarea {
  font-size: 13px;
  //max-width: 180px;
  margin: 0 auto 10px;
  font-family: Brandon, monospace;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}

.profile__post {
  margin-bottom: 10px;
  padding: 3px 20px;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}

.profile__connect-btn {
  margin-bottom: 10px;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  font-size: 20px;
  padding: 4px 12px;
}

.profile__sent-btn {
  margin-top: 10px;
}

.profile__login {
  display: block;
  text-align: center;
}

.profile__title {
  margin-top: 10px;
  margin-bottom: 15px;
  padding-left: 0;
  font-size: 32px;
}

.profile__table > tbody > tr {
  background-color: #F3FFEC;
}

.profile__table > tbody > tr > td {
  border-top: none;
}

.profile__table {
  .profile__table-label {
    padding: 5px 3px;
    color: #949469;
    font-size: 15px;
    vertical-align: middle;
  }

  .profile__table-value {
    padding: 0;
    font-size: 16px;
    vertical-align: middle;
  }
}

.profile__table--stripped > tbody > tr:nth-child(even) {
  background-color: #fafff7;
}

.profile__table--sidebar > tbody > tr > td {
  padding-top: 2px;
  padding-bottom: 2px;
  color: $greyDoctors;
}

.profile__table--sidebar .dashed-underline {
  color: #949469;
}

.profile__table--sidebar .profile__all-posts {
  display: block;
  margin-top: 10px;
}

.user-type-logo {
  display: inline-block;
}

.user-type-logo-small {
  width: 20px !important;
  height: 20px;
  vertical-align: baseline;
  margin-left: 5px;
}

.auth-label {
  margin-bottom: 15px;
  text-align: left;
}

.auth-label a {
  font-weight: bold;
}

.auth-label ul {
  padding-left: 15px;
  padding-top: 5px;
}

.dashed-underline + .tooltip {
  .tooltip-inner {
    background-color: #fafff7;
    color: #949469;
    border: 1px solid #949469;
  }
}

.dashed-underline {
  $arrow-style: 5px solid #949469;
  & + .tooltip.left > .tooltip-arrow {
    border-left: $arrow-style;
  }

  & + .tooltip.top > .tooltip-arrow {
    border-top: $arrow-style;
  }

  & + .tooltip.right > .tooltip-arrow {
    border-right: $arrow-style;
  }

  & + .tooltip.bottom > .tooltip-arrow {
    border-bottom: $arrow-style;
  }
}

.profile-more-info {
  margin-bottom: 10px;
  padding: 5px;
  text-align: center;
  background-color: #f9f9f9;
  font-size: 15px;

  .profile-more-info__img {
    margin-top: 10px;
  }

  .profile-more-info__text {
    margin-top: 10px;
    color: #949469;
    text-align: center;
  }

  .profile-more-info__link-wrap {
    margin-top: 10px;
    text-align: center;
    color: #336600;
    text-decoration: none;
  }
}

.profile {
  .subtitle {
    font-size: 18px;
    margin-bottom: 10px;
    font-weight: 400;
  }

  .connect {
    margin-top: 0;
    margin-right: 0;
  }
}

@include mobile {
  .profile-avatar {
    margin-top: 15px;
    margin-bottom: 15px;
    text-align: center;
  }

  .profile__like::after {
    top: -5;
    left: 75px;
  }
}

.profile__profession {
  margin-top: -10px;
  margin-bottom: 5px;
  font-style: italic;
  font-size: 16px;
  color: $greyDoctors;
}

.profile__hospitals-title {
  font-size: 16px;
  margin-bottom: 1px;
  margin-top: 20px;
  color: $greyDoctors;
}

.profile__hospital {
  margin-bottom: 5px;
  color: $greyDoctors;
}

.profile__hospital-name {
  text-decoration: underline;
}

.profile__prof-info {
  font-size: 16px;
  color: $greyDoctors;
}

.profile__visible {
  float: right;
  margin-top: 15px;
}

.profile__last-active {
  color: #737373;
  font-size: 13px;
  &:before {
    display: inline-block;
    vertical-align: middle;
    width: 10px;
    height: 10px;
    margin-right: 5px;
    content: '';
    background-image: url("/assets/images/icon_clock.svg");
    background-size: cover;
  }
}