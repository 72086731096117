@import "vars.scss";
@import "mixin.scss";

////////////////////////////////// Rewrite Bootstrap style, if tabs in b-table__wrap //////////////////////////////////


///////////////////////////////////////////////////// Tabs style /////////////////////////////////////////////////////
.b-table__wrap {
  padding: 3px 2px;
  border-color: #336600;
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  width: 100%;
  @include laptop {
    width: 102.15%;
  }
  @include mobile {
    overflow: scroll;
  }

  a {
    color: $textGreen;
    font-weight: bold;
    &:focus {
      color: $textGreen;
      text-decoration: none;
    }
    &:hover{
      color: $textGreen;
      text-decoration: underline;
    }
  }


  .nav-tabs {
    min-height: 34px;
    margin-bottom: 7px;
    padding-left: 1px;
    border: 1px solid $textGreen;
    border-radius: 4px;
    //@include tabs-gradient;
    //@include table-gradient;
    background-color: $greenColor3;

    li {
      margin-bottom: -3px;
      @include mobile {
        width: 100%;
      }

      a {
        margin-left: 2px;
        margin-top: 4px;
        padding: 5px 8px;
        color: #555555;
        border-bottom-color: $textGreen;
        font-family: Brandon, Verdana, Arial, sans-serif;
        @include tab-gradient;
        font-weight: 400;
        &:hover {
          color: #212121;
          border-color: #212121;
          border-bottom-color: $textGreen;
        }
      }
    }

    li.active {
      a {
        margin-top: 4px;
        padding: 6px 9px;
        padding-bottom: 4px;
        border-color: black;
        border-bottom-color: white;
        font-weight: bold;
        background: white;
        color: #212121;
        font-family: Brandon, Verdana, Arial, sans-serif;
        &:hover {
          border-color: black;
          border-bottom-color: white;
        }
      }
    }

  }
}
//////////////////////////////////////////////////////Table style//////////////////////////////////////////////////////

.b-table__wrap .tab-content {
  padding-left: 2px;
  padding-right: 3px;
}

.b-table__wrap {
  // table head
  thead {
    tr {
      background-color: $thTrColor;
      border-bottom: 0;
    }
    th {
      font-weight: normal;
      font-family: Brandon, Verdana, Arial, sans-serif;
      font-size: 16px;
      padding: 3px;
      color: #222222;
      @media (max-width: $mobile-max) {
        font-size: 13px;
      }
    }
  }
  // table body
  tbody {
    td {
      padding: 4px !important;
      font-size: 15px;
      border-top: none !important;
      border-bottom: none;
      line-height: 1.3em;
    }
  }
}

.b-table td a {
  font-family: Brandon, Verdana, Arial, sans-serif;
  color: $textGreen;
  font-weight: bold;
  &:focus {
    color: $textGreen;
    text-decoration: none;
  }
  &:hover{
    color: $textGreen;
    text-decoration: underline;
  }
}


.b-table__date  {
  font-family: Brandon, Verdana, Arial, sans-serif;
  color: #222222;
  font-size: 15px;
}

//Make  .b-table__date  time (like 20:59 PM) grey
.b-table__date span {
  color: #777777;
}

//Make table striped
.b-table__tbody tr:nth-child(even) {
  background-color: $tableBodyRowColor;
}

.b-table__avatar {
  width: 35px;
  height: 35px;
}

.b-table__reply a {
  font-size: 14px;
  color: $textGreen;
}

.b-table__btn {
  margin-top: 15px;
}

.test-block2 {
  position: absolute;
  top: 10px;
  height: 20px;
  display: flex;
  opacity: .3;
  width: 20px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}




































