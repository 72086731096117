@import "vars.scss";
@import "mixin.scss";

/////////////////////////////////////////// Possible connections ///////////////////////////////////////////


.connect {
  font-size: 14px;
  margin-top: 6px;
  @include laptop {
    margin-right: -15px;
  }
}

.connect__title {
  margin-left: 5px;
  margin-right: 6px;
}

////////////////// Stripped table styles //////////////////

.connect .row:nth-child(even) {
  background-color: #f3f3f3;
  @include mobile {
    background-color: white;
  }
}

.connect .row .connect__wrap:nth-child(2) {
  @include mobile {
    background-color: #f3f3f3;
  }
}

///////////////////////////////////////////////////////////

.connect__wrap {
  padding: 11px 8px;
}

.connect__avatar {
  float: left;
  height: 40px;
  width: 40px;
}

.connect__wrap-text {
  margin-left: 50px; //Place for user avatar
  line-height: 1em;
  padding-top: 2px;
}

.connect__author  {
  font-weight: bold;
  color: $textGreen;
  &:hover {
    color: $textGreen;
  }
}
.connect__job  {
  color: $greyColor3;
  line-height: 1.5em;
}
.connect__location  {
  font-style: italic;
  color: $greyColor3;
}
.connect__connect  {
  color: orange;
  line-height: 1.5em;
  font-style: italic;
  &:hover {
    color: orange;
  }
}

.connect__noactivity {
  margin-top: 19px;
  margin-left: 15px;
  color: black;
}

