@import "vars.scss";
@import "mixin.scss";

////////////////////////////////////////////////// Recent post inner //////////////////////////////////////////////////

.recent-posts {
  margin-top: 7px;
}

.recent-post__table {
  font-size: 16px;
  margin-bottom: 0;
}

.recent-post__table tr:nth-child(even){
  background-color: #f3f3f3;
}

.recent-post__td {
  border-top: none !important; //reset Bootstrap table style
}

.recent-post__avatar-wrap {
  width: 57px;
  //max-width: 32px;
  vertical-align: top;
  text-align: right;
  padding-left: 0 !important;
  padding-top: 6px !important;
  padding-right: 9px !important;
}

.recent-post__avatar {
  height: 40px;
  width: 40px;
  /*margin-top: -2px;
  margin-right: 1px;*/
}

.recent-post__text-wrap {
  max-width: 210px;
  padding-right: 0 !important;
}

.recent-post__author,
.recent-post__author > a {
  font-weight: bold;
  color: $textGreen;
  line-height: 12px;
}

.recent-post__date {
  float: right;
  padding-right: 5px;
  font-size: 12px;
  color: $greyColor1;
  font-weight: 400;
}

.recent-post__action {
  color: $greyColor2;
}

.recent-post__reply-text {
  width: 240px;
  overflow: hidden;
  color: $textGreen;
  word-break: break-word;
  &:hover {
    color: $textGreen;
  }
}

