@import "vars.scss";
@import "mixin.scss";

.confirmation {
  border-top: 1px solid #cfe0ce;
  padding-left: 6px;
}

.confirmation__title {
  margin-top: 12px;
  font-size: 20px;
}

.confirmation__text {
  font-size: 17px;
  margin-bottom: 7px;
}

.confirmation__link {
  font-size: 17px;
}